import React from 'react';
import { Link } from 'react-router-dom';

const CourseJoinModal = () => {
  return (
    <div className="process_modal esg_modal course_industry_mdl">
      <div className="modal fade show" id="esg_modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header text-center">
              <div className="process_hdng w-100">
                <h3>Your goal to join this event</h3>
                <p>Please add at-list 5 skills to continue</p>
              </div>
            </div>
            <div className="modal-body">
              <form>
                <div className="multiple_select_area">
                  <div className="option_btns mt-2">
                    <ul className="list_stye_none d-flex flex-wrap align-items-center gap-2 justify-content-center">
                      <li>
                        <Link
                          to="#"
                          className="d-flex align-items-center justify-content-center"
                        >
                          Get a job
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          className="d-flex align-items-center justify-content-center"
                        >
                          Get a freelance project
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          className="d-flex align-items-center justify-content-center"
                        >
                          Search experts
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          className="d-flex align-items-center justify-content-center"
                        >
                          Learn new things
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          className="d-flex align-items-center justify-content-center"
                        >
                          Get more clients
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          className="d-flex align-items-center justify-content-center"
                        >
                          Explore the market
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          className="d-flex align-items-center justify-content-center"
                        >
                          Other
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="process_btns_outer d-flex align-items-center justify-content-between mt-3">
                  <Link to="#" data-bs-dismiss="modal" className="btn gray">
                    Cancel
                  </Link>
                  <Link to="#" className="btn">
                    Save
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseJoinModal;
