/* eslint-disable */
import React from "react";

const GalleryTab = ({ galleryImages }) => {
  return (
    <div className="portfolio_outer">
      <div className="row">
        {galleryImages.map((image, index) => {
          return (
            <div className="col-lg-4 col-6" key={index}>
              <div className="portfolio_pic">
                <img src={image.path} alt="" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default GalleryTab;
