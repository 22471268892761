/* eslint-disable */
import "react-multi-carousel/lib/styles.css";

import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
//import context
import { GlobalProvider } from "context/GlobalContext";
/*import url and gateway methods */
import { getData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";

//import images
import { assetImages } from "constants";
import PostPhotosModal from "../Modals/PostPhotosModal";
import PostVideosModal from "../Modals/PostVideosModal";
import PostEventModal from "../Modals/PostEventModal";
import PostJobModal from "../Modals/PostJobModal";
import PostJobDescriptionModal from "../Modals/PostJobDescriptionModal";
import PostQuestionModal from "../Modals/PostQuestionModal";
import ForumPlaceHolderCard from "./PlaceholderCard/ForumPlaceHolderCard";
import SavePostModal from "components/ActivitiesComponents/Common/SavePostModal";
import PostSuccessModal from "components/ActivitiesComponents/Common/PostSuccessModal";
import ActivityListFilterPopup from "../Popup/ActivityListFilterPopup";

const ActivitiesLandingMiddlePanel = () => {
  const glowCount = [1, 2, 3];
  const { screenHeight, ecosystemList } = useContext(GlobalProvider);
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const { t } = useTranslation(); // translation marker
  const [tagList, setTagList] = useState([]);

  const [isLoading, setisLoading] = useState(false);
  const [forumList, setForumList] = useState([]);

  const [forumId, setforumId] = useState(null);

  const [filterEcosystem, setFilterEcosystem] = useState(null);
  const [filterDateRange, setFilterDateRange] = useState("");
  const [filterIsPrivate, setfilterIsPrivate] = useState(false);
  const [filterMod, setfilterMod] = useState(null);
  const [filterQuery, setfilterQuery] = useState("");
  const [filterEvents, setfilterEvents] = useState([]);
  const [filterGroups, setfilterGroups] = useState([]);
  const [filterTags, setfilterTags] = useState([]);

  const [params, setParams] = useState(null);
  const [searchedText, setsearchedText] = useState("");
  const [hideNextButton, sethideNextButton] = useState(false);

  const [reloadData, setReloadData] = useState(false);

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestUrl);

      // console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const getAllForums = async () => {
    try {
      setisLoading(true);
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_ALL_FORUM +
        `?token=${token}&rolestring=${userInfo.role.name}`;

      if (filterDateRange != "") {
        requestURL = requestURL + `&filterdates=${filterDateRange}`;
      }

      if (filterIsPrivate) {
        requestURL = requestURL + `&filterisprivate=${filterIsPrivate}`;
      }

      if (filterMod) {
        requestURL = requestURL + `&filtermod=${filterMod}`;
      }

      if (filterQuery != "") {
        requestURL = requestURL + `&filterquery=${filterQuery}`;
      }

      if (filterEvents.length > 0) {
        requestURL = requestURL + `&filterevents=${filterEvents}`;
      }

      if (filterGroups.length > 0) {
        requestURL = requestURL + `&filtergroups=${filterGroups}`;
      }

      if (filterTags.length > 0) {
        requestURL = requestURL + `&filterlabels=${filterTags}`;
      }

      if (filterEcosystem) {
        requestURL = requestURL + `&filterecosystemid=${filterEcosystem}`;
      }

      const response = await getData(requestURL);

      setisLoading(false);

      console.log(response);
      if (response.status) {
        setForumList(response.data);
        resetFilterData();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const changeForumStatus = async (id) => {
    let forumData = {
      status: "0",
    };

    let requestURL =
      url.API_BASE_URL + url.API_UPDATE_FORUM + `/${id}` + `?token=${token}`;

    const response = await putData(requestURL, forumData);

    if (response.status) {
      getAllForums();
    }
  };

  const resetForum = () => {
    setforumId(null);
    // getAllForums();
  };

  const resetFilterData = () => {
    setFilterEcosystem(null);
    setFilterDateRange("");
    setfilterIsPrivate(false);
    setfilterMod(null);
    setfilterQuery("");
    setfilterEvents([]);
    setfilterGroups([]);
    setfilterTags([]);
  };

  //reset list
  const resetList = () => {
    sethideNextButton(false);
    setsearchedText("");
    setReloadData(true);
  };

  //use effect for filter search
  useEffect(() => {
    if (
      filterDateRange != "" ||
      filterIsPrivate ||
      filterMod ||
      filterQuery != "" ||
      filterEvents.length > 0 ||
      filterGroups.length > 0 ||
      filterTags.length > 0 ||
      filterEcosystem
    ) {
      getAllForums();
    }
  }, [
    filterDateRange,
    filterIsPrivate,
    filterMod,
    filterQuery,
    filterEvents,
    filterGroups,
    filterTags,
    filterEcosystem,
  ]);

  useEffect(() => {
    if (reloadData) {
      getAllForums("0", "10", null);
      setReloadData(false);
    }
  }, [reloadData]);

  useEffect(() => {
    getAllForums();
    getAllTags();
  }, []);

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_FORUM")
  ) {
    return (
      <div className="pnl_mdlpnl">
        <div className="post_item_outer">
          {/* share box */}
          <div className="post_item">
            <div className="search_post_pnl white_shadow_bx">
              <div className="top_usr_srch">
                {/* userimage */}
                <div className="search_profile">
                  <img
                    src={
                      userInfo.photoimage
                        ? url.SERVER_URL + userInfo.photoimage?.path
                        : assetImages.defaultUser
                    }
                    alt=""
                  />
                </div>

                {/* create post modal box */}
                <div className="search_fill">
                  <Link
                    to="#"
                    className="form-control"
                    data-bs-toggle="modal"
                    data-bs-target="#create_postmdl"
                  >
                    {t("Start your post")}
                  </Link>
                </div>
              </div>
              {/* <div className="post_bttm_menu">
              <ul className="list_stye_none">
                <li>
                  <Link
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#create_post_img_mdl"
                  >
                    <i className="material-icons blue_icon">image</i>
                    <span>{t("Photo")} </span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#create_post_vdo_mdl"
                  >
                    <i className="material-icons grn_icon">movie</i>
                    <span>{t("Video")}</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    data-bs-target="#create_post_event_mdl"
                    data-bs-toggle="modal"
                  >
                    <i className="material-icons ylw_icon">event_available</i>
                    <span>{t("Event")}</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#create_post_job_mdl"
                  >
                    <i className="material-icons ornge_icon">work</i>
                    <span>{t("Jobs")}</span>
                  </Link>
                </li>
              </ul>
            </div> */}
            </div>
          </div>

          {/* <!-- ========== Start filter search Section ========== --> */}
          <div className="white_shadow_bx px-3 py-2 rounded-10 bg-white d-flex align-items-center justify-content-between mb-4 gap-3 filter_srch_item">
            <div className="filter_search_wrap d-flex align-items-center gap-3 w-100 p-3">
              <div className="dropdown">
                <Link
                  to="#"
                  className="dropdown-toggle text-gray"
                  data-bs-toggle="dropdown"
                >
                  Sort by
                </Link>
                <ul className="dropdown-menu px-2">
                  <li className="mb-2">
                    <Link
                      to="#"
                      className="dropdown-item d-flex align-items-center gap-2 text-gray"
                      // onClick={() => {
                      //   setParams({
                      //     sortby: "desc",
                      //   });
                      // }}
                    >
                      {t("Rank")}
                      <i className="material-icons-outlined fs-sm">
                        arrow_upward
                      </i>
                    </Link>
                  </li>
                  <li className="mb-2">
                    <Link
                      to="#"
                      className="dropdown-item d-flex align-items-center gap-2 text-gray"
                      // onClick={() => {
                      //   setParams({
                      //     sortby: "asc",
                      //   });
                      // }}
                    >
                      {t("Rank")}
                      <i className="material-icons-outlined fs-sm">
                        arrow_downward
                      </i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className="dropdown-item d-flex align-items-center gap-2 text-gray"
                      // onClick={() => {
                      //   setParams({
                      //     sortby: "name",
                      //   });
                      // }}
                    >
                      {t("Name")} (A-Z)
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="search_filter_form d-flex align-items-center justify-content-between w-100">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search by user name or email"
                  value={searchedText}
                  onChange={(e) => {
                    setsearchedText(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setfilterQuery(searchedText);
                    }
                  }}
                />
                <button
                  className="filter_srch_btn"
                  onClick={() => {
                    setfilterQuery(searchedText);
                  }}
                >
                  <i className="material-icons-outlined">search</i>
                </button>
              </div>
            </div>
            <div className="filter_refresh_btn d-flex align-items-center gap-3">
              <Link
                to="#"
                className="d-block refresh_iocn"
                onClick={() => {
                  resetFilterData();
                  resetList();
                }}
              >
                <i className="material-icons-outlined">refresh</i>
              </Link>
              <Link
                to="#"
                data-bs-toggle="offcanvas"
                data-bs-target="#forum_filter_offcanvas"
                role="button"
                aria-controls="forum_filter_offcanvas"
                className="d-flex align-items-center gap-2"
              >
                <i className="material-icons-outlined">tune</i>
                <span className="d-block">Filter</span>
              </Link>
            </div>
          </div>
          {/* <!-- ========== End filter search Section ========== --> */}

          {/* all posts */}

          {isLoading ? (
            <div className="post_item">
              {glowCount.map((item, index) => {
                return <ForumPlaceHolderCard key={index} />;
              })}
            </div>
          ) : (
            <div className="post_item">
              {/* <div className="postuser_detailsbx white_shadow_bx">
              <div className="user_post_top">
                <div className="post_left">
                  <div className="pofile_pic">
                    <img src="images/profile-pic5.png" alt="" />
                  </div>
                  <div className="pofile_info">
                    <h3>Don Bradman</h3>
                    <ul className="list_stye_none">
                      <li>
                        <i className="material-icons">public</i>
                        <span>Public</span>
                      </li>
                      <li>
                        <span>5min ago</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="user_right">
                  <ul className="list_stye_none">
                    <li className="dropdown">
                      <Link to="#" data-bs-toggle="dropdown">
                        <i className="material-icons-outlined">more_vert</i>
                      </Link>
                      <ul className="list_stye_none dropdown-menu">
                        <li>
                          <Link to="#">{t("Update Photo")}</Link>
                        </li>
                        <li>
                          <Link to="#">Lorem ipsum</Link>
                        </li>
                        <li>
                          <Link to="#">Lorem ipsum</Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="user_post_paragraph">
                <p>
                  Coined by mobile interface expert Steven Hoober, "The Thumb
                  Zone" refers to the area.
                </p>
              </div>
              <div className="poster_pic">
                <img src="images/activity-pic.jpg" alt="" />
              </div>
              <div className="user_post_icons">
                <ul className="list_stye_none">
                  <li>
                    <Link to="#">
                      <i className="material-icons ornge_icon">favorite</i>
                      <span>250</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/activities/details">
                      <i className="material-icons">chat</i>
                      <span>25</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="material-icons">stars</i>
                      <span>{t("Winner")}</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="postuser_detailsbx white_shadow_bx">
              <div className="user_post_top">
                <div className="post_left">
                  <div className="pofile_pic">
                    <img src="images/profile-pic5.png" alt="" />
                  </div>
                  <div className="pofile_info">
                    <h3>Don Bradman</h3>
                    <ul className="list_stye_none">
                      <li>
                        <i className="material-icons">public</i>
                        <span>Public</span>
                      </li>
                      <li>
                        <span>5min ago</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="user_right">
                  <ul className="list_stye_none">
                    <li className="dropdown">
                      <Link to="#" data-bs-toggle="dropdown">
                        <i className="material-icons-outlined">more_vert</i>
                      </Link>
                      <ul className="list_stye_none dropdown-menu">
                        <li>
                          <Link to="#">{t("Update Photo")}</Link>
                        </li>
                        <li>
                          <Link to="#">Lorem ipsum</Link>
                        </li>
                        <li>
                          <Link to="#">Lorem ipsum</Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="user_post_paragraph">
                <p>
                  Coined by mobile interface expert Steven Hoober, "The Thumb
                  Zone" refers to the area on a mobile device screen that is
                  easily accessible to the user's thumb when holding the device
                  with one hand. Since many users interact with their m ..
                </p>
              </div>
              <div className="postpic_outer">
                <div className="post_leftpnl">
                  <div className="post_pic">
                    <img src="images/activity1.jpg" alt="" />
                  </div>
                  <div className="post_pic">
                    <img src="images/activity2.jpg" alt="" />
                  </div>
                </div>
                <div className="post_rightpnl">
                  <div className="post_pic">
                    <img src="images/activity3.jpg" alt="" />
                  </div>
                  <div className="post_pic">
                    <img src="images/activity4.jpg" alt="" />
                  </div>
                  <div className="post_pic">
                    <img src="images/activity5.jpg" alt="" />
                    <Link to="#" className="more_img">
                      <span>+3</span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="user_post_icons">
                <ul className="list_stye_none">
                  <li>
                    <Link to="#">
                      <i className="material-icons ornge_icon">favorite</i>
                      <span>250</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/activities/details">
                      <i className="material-icons">chat</i>
                      <span>25</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="material-icons">stars</i>
                      <span>{t("Winner")}</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="postuser_detailsbx white_shadow_bx">
              <div className="user_post_top">
                <div className="post_left">
                  <div className="pofile_pic">
                    <img src="images/profile-pic5.png" alt="" />
                  </div>
                  <div className="pofile_info">
                    <h3>Don Bradman</h3>
                    <ul className="list_stye_none">
                      <li>
                        <i className="material-icons">public</i>
                        <span>Public</span>
                      </li>
                      <li>
                        <span>5min ago</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="user_right">
                  <ul className="list_stye_none">
                    <li className="dropdown">
                      <Link to="#" data-bs-toggle="dropdown">
                        <i className="material-icons-outlined">more_vert</i>
                      </Link>
                      <ul className="list_stye_none dropdown-menu">
                        <li>
                          <Link to="#">{t("Update Photo")}</Link>
                        </li>
                        <li>
                          <Link to="#">Lorem ipsum</Link>
                        </li>
                        <li>
                          <Link to="#">Lorem ipsum</Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="user_post_paragraph">
                <p>
                  Coined by mobile interface expert Steven Hoober, "The Thumb
                  Zone" refers to the area on a mobile device screen that is
                  easily accessible to the user's thumb when holding the device
                  with one hand. Since many users interact with their m ..
                </p>
              </div>
              <div className="postpic_outer">
                <div className="post_leftpnl">
                  <div className="post_pic">
                    <img src="images/activity6.jpg" alt="" />
                  </div>
                </div>
                <div className="post_rightpnl">
                  <div className="post_pic">
                    <img src="images/activity7.jpg" alt="" />
                  </div>
                  <div className="post_pic">
                    <img src="images/activity8.jpg" alt="" />
                  </div>
                </div>
              </div>
              <div className="user_post_icons">
                <ul className="list_stye_none">
                  <li>
                    <Link to="#">
                      <i className="material-icons ornge_icon">favorite</i>
                      <span>250</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/activities/details">
                      <i className="material-icons">chat</i>
                      <span>25</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="material-icons">stars</i>
                      <span>{t("Winner")}</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div> */}
              {forumList.map((forum, index) => {
                return (
                  <div
                    className="postuser_detailsbx white_shadow_bx"
                    key={index}
                  >
                    <div className="user_post_top">
                      <div className="post_left">
                        <div className="pofile_pic">
                          <img
                            src={
                              forum.moderatorimage == ""
                                ? assetImages.defaultUser
                                : url.SERVER_URL + forum.moderatorimage
                            }
                            alt=""
                          />
                        </div>
                        <div className="pofile_info">
                          <h3>{forum.moderatorname}</h3>
                          <ul className="list_stye_none">
                            <li>
                              <i className="material-icons">
                                {forum.isprivate ? "settings" : "public"}
                              </i>
                              <span className="ms-1">
                                {forum.isprivate ? "Private" : "Public"}
                              </span>
                            </li>
                            <li>
                              <span className="ms-2">{forum.timestring}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="user_right">
                        <ul className="list_stye_none">
                          <li className="dropdown">
                            <Link to="#" data-bs-toggle="dropdown">
                              <i className="material-icons-outlined">
                                more_vert
                              </i>
                            </Link>
                            <ul className="list_stye_none dropdown-menu">
                              <li>
                                <Link to={`/activities/details/${forum._id}`}>
                                  {t("Post Details")}
                                </Link>
                              </li>
                              {userInfo.role.name == "Admin" ||
                              forum.moderator._id.toString() ===
                                userInfo._id.toString() ? (
                                <li>
                                  <Link
                                    to="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#create_postmdl"
                                    onClick={() => setforumId(forum._id)}
                                  >
                                    {t("Update Post")}
                                  </Link>
                                </li>
                              ) : null}
                              {userInfo.role.name == "Admin" ||
                              forum.moderator._id.toString() ===
                                userInfo._id.toString() ? (
                                <li>
                                  <Link
                                    to="#"
                                    onClick={() => changeForumStatus(forum._id)}
                                  >
                                    {t("Delete Post")}
                                  </Link>
                                </li>
                              ) : null}
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="user_post_paragraph">
                      <p>
                        <Link to={`/activities/details/${forum._id}`}>
                          {forum.title}
                        </Link>
                      </p>
                      <p
                        dangerouslySetInnerHTML={{ __html: forum.description }}
                      ></p>
                    </div>

                    {forum.uploads.length === 0 ? null : forum.uploads
                        .length === 1 ? (
                      forum.uploads.map((upload, index) => {
                        return (
                          <div className="poster_pic" key={index}>
                            <img src={url.SERVER_URL + upload.path} alt="" />
                          </div>
                        );
                      })
                    ) : forum.uploads.length === 2 ? (
                      <div className="postpic_outer">
                        {forum.uploads.map((upload, index) => {
                          return (
                            <div
                              key={index}
                              className={
                                index == 0 ? "post_leftpnl" : "post_rightpnl"
                              }
                            >
                              <div className="post_pic">
                                <img
                                  src={url.SERVER_URL + upload.path}
                                  alt=""
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : forum.uploads.length === 3 ? (
                      <div className="postpic_outer">
                        <div className="post_leftpnl ">
                          {forum.uploads.slice(0, 1).map((upload, index) => {
                            return (
                              <div className="post_pic" key={index}>
                                <img
                                  src={url.SERVER_URL + upload.path}
                                  alt=""
                                />
                              </div>
                            );
                          })}
                        </div>
                        <div className="post_rightpnl">
                          {forum.uploads.slice(1, 3).map((upload, index) => {
                            return (
                              <div className="post_pic" key={index}>
                                <img
                                  src={url.SERVER_URL + upload.path}
                                  alt=""
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : forum.uploads.length === 4 ? (
                      <div className="postpic_outer">
                        <div className="post_leftpnl ">
                          {forum.uploads.slice(0, 2).map((upload, index) => {
                            return (
                              <div className="post_pic" key={index}>
                                <img
                                  src={url.SERVER_URL + upload.path}
                                  alt=""
                                />
                              </div>
                            );
                          })}
                        </div>
                        <div className="post_rightpnl">
                          {forum.uploads.slice(2, 4).map((upload, index) => {
                            return (
                              <div className="post_pic" key={index}>
                                <img
                                  src={url.SERVER_URL + upload.path}
                                  alt=""
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : forum.uploads.length >= 5 ? (
                      <div className="postpic_outer">
                        <div className="post_leftpnl">
                          {forum.uploads.slice(0, 2).map((upload, index) => {
                            return (
                              <div className="post_pic" key={index}>
                                <img
                                  src={url.SERVER_URL + upload.path}
                                  alt=""
                                />
                              </div>
                            );
                          })}
                        </div>
                        <div className="post_rightpnl">
                          {forum.uploads.slice(2, 5).map((upload, index) => {
                            return (
                              <div className="post_pic" key={index}>
                                <img
                                  src={url.SERVER_URL + upload.path}
                                  alt=""
                                />
                                {index == 2 && forum.uploads.length > 5 ? (
                                  <Link to="#" className="more_img">
                                    <span>+{forum.uploads.length - 5}</span>
                                  </Link>
                                ) : null}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : null}

                    <div className="tags_list  mt-4">
                      <div className="option_btns">
                        <ul className="list_stye_none d-flex flex-wrap align-items-center gap-2">
                          {forum.taglistarr.map((tag, index) => {
                            return (
                              <li className="tag" key={index}>
                                <Link
                                  to="#"
                                  className="tagblock d-flex align-items-center justify-content-center"
                                >
                                  <span>{tag.title}</span>
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>

                    {/* like,comment,winner icon */}
                    <div className="user_post_icons">
                      <ul className="list_stye_none">
                        <li>
                          <Link to="#">
                            <i className="material-icons ornge_icon">
                              favorite
                            </i>
                            <span>{forum.likes.length}</span>
                          </Link>
                        </li>
                        <li>
                          <Link to={`/activities/details/${forum._id}`}>
                            <i className="material-icons">chat</i>
                            <span>{forum.commentlist.length}</span>
                          </Link>
                        </li>
                        <li
                          className={forum.winnerperson == "" ? "" : "active"}
                        >
                          <Link to="#">
                            <i className="material-icons">stars</i>
                            <span>{t("Winner")}</span>
                            {/* {forum.winnerperson == "" ? null : (
                            <p>{forum.winnerperson}</p>
                          )} */}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          {/* ========== modals and popup area ========= */}

          <SavePostModal onModalClose={resetForum} forumId={forumId} />

          {/* ------ post success modal ----------- */}
          <PostSuccessModal afterModalClose={getAllForums} />

          <ActivityListFilterPopup
            ecosystemList={ecosystemList}
            tagList={tagList}
            setFilterEcosystem={setFilterEcosystem}
            setFilterDateRange={setFilterDateRange}
            setfilterIsPrivate={setfilterIsPrivate}
            setfilterMod={setfilterMod}
            setfilterQuery={setfilterQuery}
            setfilterEvents={setfilterEvents}
            setfilterGroups={setfilterGroups}
            setfilterTags={setfilterTags}
            resetList={getAllForums}
          />

          {/* ---- post photos modal ---------- */}
          <PostPhotosModal />

          {/* ------ post video modal ----- */}
          <PostVideosModal />

          {/* ------ post event modal --------- */}
          <PostEventModal />

          {/* -------- post job modal -------- */}
          <PostJobModal />

          {/* ----- job description modal --------- */}
          <PostJobDescriptionModal />

          {/* ------ post question modal --------- */}
          <PostQuestionModal />
        </div>
      </div>
    );
  } else {
    return (
      <div className="pnl_mdlpnl">
        <div className="empty_access text-center">
          <div className="empty_pic mb-4">
            {" "}
            <img src={assetImages.emptyVector} alt="" />
          </div>
          <div className="empty_text">
            <p className="fs-lg text-gray fw-semibold mb-4">
              Sorry....! You don't have privilege to see this content
            </p>
          </div>
        </div>
      </div>
    );
  }
};

export default ActivitiesLandingMiddlePanel;
