/* function to download file */
const fileDownloadHandler = async (file) => {
  try {
    const response = await fetch(file.path);
    const blob = await response.blob();
    const fileName = file.name;

    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, fileName);
    } else {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      link.click();
      window.URL.revokeObjectURL(url);
    }
  } catch (error) {
    console.error("Error occurred while downloading the file:", error);
  }
};

export { fileDownloadHandler };
