/* eslint-disable */
import React, { useState } from "react";
import { Link } from "react-router-dom";

const GroupListFilterPopup = ({
  tagList,
  categoryList,
  searchingList,
  interestList,
  resetList,
  setfilterGroupCategories,
  setfilterGroupInterests,
  setfilterGroupSearchings,
  setfilterGroupCity,
  setfilterGroupCountry,
  setFilterDateRange,
  setFilterGroupTags,
  setFilterGroupTitle,
  setFilterRating,
}) => {
  const [fromDate, setfromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const [title, settitle] = useState("");
  const [city, setcity] = useState("");
  const [country, setcountry] = useState("");
  const [selectedRating, setSelectedRating] = useState("");
  const [categoryData, setcategoryData] = useState([]);
  const [interestData, setinterestData] = useState([]);
  const [searchData, setsearchData] = useState([]);
  const [tagData, settagData] = useState([]);

  const handleRatingChange = (event) => {
    setSelectedRating(event.target.value);
  };

  //function for select category
  const categorySelectionHandler = (data) => {
    let prevData = categoryData;

    if (prevData.includes(data.value)) {
      let selectedItem = document.getElementById(
        "eventcategoryfilter-" + data.value
      );
      selectedItem.classList.remove("active");
      // exist in array, thus remove
      let key = prevData.indexOf(data.value);
      prevData.splice(key, 1);
    } else {
      let selectedDataItem = document.getElementById(
        "eventcategoryfilter-" + data.value
      );
      selectedDataItem.classList.add("active");
      // not exist in array, thus add
      prevData.push(data.value);
    }

    setcategoryData(prevData);
  };

  //function for reset
  const resetSelectCategory = () => {
    let resetBlock = document.getElementsByClassName("categoryblock");
    for (let i = 0; i < resetBlock.length; i++) {
      resetBlock[i].classList.remove("active");
    }
  };

  //function for select interest
  const interestSelectionHandler = (data) => {
    let prevData = interestData;

    if (prevData.includes(data.value)) {
      let selectedItem = document.getElementById("interestdata-" + data.value);
      selectedItem.classList.remove("active");
      // exist in array, thus remove
      let key = prevData.indexOf(data.value);
      prevData.splice(key, 1);
    } else {
      let selectedDataItem = document.getElementById(
        "interestdata-" + data.value
      );
      selectedDataItem.classList.add("active");
      // not exist in array, thus add
      prevData.push(data.value);
    }

    setinterestData(prevData);
  };

  //function for reset
  const resetSelectInterest = () => {
    let resetBlock = document.getElementsByClassName("interestblock");
    for (let i = 0; i < resetBlock.length; i++) {
      resetBlock[i].classList.remove("active");
    }
  };

  //function for select searching
  const searchingSelectionHandler = (data) => {
    let prevData = searchData;

    if (prevData.includes(data.value)) {
      let selectedItem = document.getElementById("searchdata-" + data.value);
      selectedItem.classList.remove("active");
      // exist in array, thus remove
      let key = prevData.indexOf(data.value);
      prevData.splice(key, 1);
    } else {
      let selectedDataItem = document.getElementById(
        "searchdata-" + data.value
      );
      selectedDataItem.classList.add("active");
      // not exist in array, thus add
      prevData.push(data.value);
    }

    setsearchData(prevData);
  };

  //function for reset
  const resetSelectSearching = () => {
    let resetBlock = document.getElementsByClassName("searchblock");
    for (let i = 0; i < resetBlock.length; i++) {
      resetBlock[i].classList.remove("active");
    }
  };

  //function for select tag
  const tagSelectionHandler = (data) => {
    let prevData = tagData;

    if (prevData.includes(data.value)) {
      let selectedItem = document.getElementById("tagdata-" + data.value);
      selectedItem.classList.remove("active");
      // exist in array, thus remove
      let key = prevData.indexOf(data.value);
      prevData.splice(key, 1);
    } else {
      let selectedDataItem = document.getElementById("tagdata-" + data.value);
      selectedDataItem.classList.add("active");
      // not exist in array, thus add
      prevData.push(data.value);
    }

    settagData(prevData);
  };

  //function for reset
  const resetSelectedTags = () => {
    let resetBlock = document.getElementsByClassName("tagblock");
    for (let i = 0; i < resetBlock.length; i++) {
      resetBlock[i].classList.remove("active");
    }
  };

  //function for remove active class for selected tags
  const removeActiveClass = () => {
    const liElements = document.querySelectorAll(".tags li a");
    liElements.forEach((li) => {
      li.classList.remove("active");
    });
  };

  //function for search
  const searchHandler = () => {
    //check start date and end date are not empty.If end date empty then only search by start date
    if (fromDate != "" && toDate != "" && fromDate < toDate) {
      setFilterDateRange(fromDate + "," + toDate);
    } else if (fromDate != "") {
      setFilterDateRange(fromDate);
    }

    setFilterGroupTitle(title);
    setfilterGroupCity(city);
    setfilterGroupCountry(country);
    setFilterGroupTags(tagData);
    setfilterGroupCategories(categoryData);
    setfilterGroupInterests(interestData);
    setfilterGroupSearchings(searchData);
    setFilterRating(selectedRating);

    // close the offcanvas of filter
    let offCanvasPopup = document.querySelector("#group_filter_offcanvas");
    let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
    offCanvas.hide();
    resetHandler();
  };

  //function for reset
  const resetHandler = () => {
    settoDate("");
    setfromDate("");
    settitle("");
    setcity("");
    setcountry("");
    setSelectedRating("");
    settagData([]);
    setcategoryData([]);
    setinterestData([]);
    setsearchData([]);
    settagData([]);
    removeActiveClass();
    resetSelectCategory();
    resetSelectInterest();
    resetSelectSearching();
    resetSelectedTags();
  };

  const reloadList = () => {
    // close the offcanvas of filter
    let offCanvasPopup = document.querySelector("#group_filter_offcanvas");
    let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
    offCanvas.hide();
    resetHandler();
    resetList();
  };
  return (
    <div className="filter_canvas">
      <div className="offcanvas offcanvas-end" id="group_filter_offcanvas">
        <div className="offcanvas-header">
          <h3>FILTER</h3>
          <Link to="#" data-bs-dismiss="offcanvas">
            <i className="material-icons-outlined">close</i>
          </Link>
        </div>
        <div className="offcanvas-body">
          <div className="filter_outerbx">
            {/* date */}
            <div className="filter_item">
              <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                <h4>Dates</h4>
              </div>
              <div className="filter_information">
                <div className="form-group d-flex gap-2">
                  <input
                    type="date"
                    className="form-control fs-sm shadow-none"
                    placeholder="From date"
                    value={fromDate}
                    onChange={(e) => setfromDate(e.target.value)}
                  />
                  <input
                    type="date"
                    className="form-control fs-sm shadow-none"
                    placeholder="To date"
                    value={toDate}
                    onChange={(e) => settoDate(e.target.value)}
                  />
                </div>
              </div>
            </div>

            {/* title start */}
            <div className="filter_item">
              <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                <h4>Title</h4>
              </div>
              <div className="filter_information">
                <div className="form-group d-flex gap-2">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Title"
                    value={title}
                    onChange={(e) => settitle(e.target.value)}
                  />
                </div>
              </div>
            </div>
            {/* title end */}

            {/* city start */}
            <div className="filter_item">
              <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                <h4>City</h4>
              </div>
              <div className="filter_information">
                <div className="form-group d-flex gap-2">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter City"
                    value={city}
                    onChange={(e) => setcity(e.target.value)}
                  />
                </div>
              </div>
            </div>
            {/* city end */}

            {/* country start */}
            <div className="filter_item">
              <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                <h4>Country</h4>
              </div>
              <div className="filter_information">
                <div className="form-group d-flex gap-2">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Country"
                    value={country}
                    onChange={(e) => setcountry(e.target.value)}
                  />
                </div>
              </div>
            </div>
            {/* country end */}

            {/* rating */}
            <div className="filter_item">
              <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                <h4>Rating</h4>
              </div>
              <div className="filter_information">
                <ul className="list_stye_none filter_rating">
                  {[5, 4, 3, 2, 1].map((rating) => (
                    <li key={rating}>
                      <label className="radio-check">
                        <input
                          type="radio"
                          name="rdo3"
                          value={rating}
                          checked={selectedRating === String(rating)}
                          onChange={handleRatingChange}
                        />
                        <span></span>
                        <p>
                          {rating}{" "}
                          <i className="material-icons-outlined">star</i> &
                          above
                        </p>
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            {/* Category */}
            <div className="filter_item">
              <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                <h4>Category</h4>
              </div>
              <div className="filter_information">
                <div className="tags_list">
                  <div className="option_btns mt-2">
                    <ul className="list_stye_none d-flex flex-wrap align-items-center gap-2">
                      {categoryList.map((item, index) => {
                        return (
                          <li className="tag" key={index}>
                            <Link
                              to="#"
                              onClick={() => categorySelectionHandler(item)}
                              id={"eventcategoryfilter-" + item.value}
                              className="categoryblock d-flex align-items-center justify-content-center"
                            >
                              <span>{item.label}</span>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* Interest */}
            <div className="filter_item">
              <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                <h4>Interests</h4>
              </div>
              <div className="filter_information">
                <div className="tags_list">
                  <div className="option_btns mt-2">
                    <ul className="list_stye_none d-flex flex-wrap align-items-center gap-2">
                      {interestList.map((item, index) => {
                        return (
                          <li className="tag" key={index}>
                            <Link
                              to="#"
                              onClick={() => interestSelectionHandler(item)}
                              id={"interestdata-" + item.value}
                              className="interestblock d-flex align-items-center justify-content-center"
                            >
                              <span>{item.label}</span>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* searchings */}
            <div className="filter_item">
              <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                <h4>Searchings</h4>
              </div>
              <div className="filter_information">
                <div className="tags_list">
                  <div className="option_btns mt-2">
                    <ul className="list_stye_none d-flex flex-wrap align-items-center gap-2">
                      {searchingList.map((item, index) => {
                        return (
                          <li className="tag" key={index}>
                            <Link
                              to="#"
                              onClick={() => searchingSelectionHandler(item)}
                              id={"searchdata-" + item.value}
                              className="searchblock d-flex align-items-center justify-content-center"
                            >
                              <span>{item.label}</span>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* label/tag */}
            <div className="filter_item">
              <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                <h4>Tags</h4>
              </div>
              <div className="filter_information">
                <div className="tags_list">
                  <div className="option_btns mt-2">
                    <ul className="list_stye_none d-flex flex-wrap align-items-center gap-2">
                      {tagList.map((item, index) => {
                        return (
                          <li className="tag" key={index}>
                            <Link
                              to="#"
                              onClick={() => tagSelectionHandler(item)}
                              id={"tagdata-" + item.value}
                              className="tagblock d-flex align-items-center justify-content-center"
                            >
                              <span>{item.label}</span>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="process_btns_outer d-flex align-items-center justify-content-between mt-3">
            <button className="btn gray" onClick={reloadList}>
              Reset
            </button>
            <button className="btn" onClick={searchHandler}>
              Show Result
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupListFilterPopup;
