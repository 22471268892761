import React from 'react';
import FeedLandingLeftPanel from '../FeedLandingLeftPanel/FeedLandingLeftPanel';
import FeedLandingMiddlePanel from '../FeedLandingMiddlePanel/FeedLandingMiddlePanel';
import FeedLandingRightPanel from '../FeedLandingRightPanel/FeedLandingRightPanel';

const FeedLandingBody = () => {
  return (
    <section className="feed_pnltotal">
      <div className="container-fluid">
        <div className="pnl_outer">
          <FeedLandingLeftPanel />
          <FeedLandingMiddlePanel />
          <FeedLandingRightPanel />
        </div>
      </div>
    </section>
  );
};

export default FeedLandingBody;
