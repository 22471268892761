/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { io } from "socket.io-client";

import { getData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import ConversationLeftPanel from "../ConversationLeftPanel/ConversationLeftPanel";
import ConversationRightPanel from "../ConversationRightPanel/ConversationRightPanel";

import { CurrentLocation } from "utils/CurrentLocation";
import { assetImages } from "constants";

const ConversationBody = () => {
  const socket = io(url.SERVER_URL);

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");
  const params = useParams();

  const [allContacts, setAllContacts] = useState([]);

  const [defaultScreen, setDefaultScreen] = useState("welcome"); // welcome, ai, contact

  const [selectedConversationId, setSelectedConversationId] = useState(null); //it can be user account id or group id
  const [conversationName, setConversationName] = useState("");
  const [selectedChatLogo, setSelectedChatLogo] = useState("");
  const [selectedChatEmail, setselectedChatEmail] = useState("");
  const [selectedChatRoomId, setSelectedChatRoomId] = useState(null);
  const [selectedChatPhone, setSelectedChatPhone] = useState("");
  const [selectedChatAddress, setSelectedChatAddress] = useState("");
  const [selectedChatCompany, setSelectedChatCompany] = useState("");
  const [groupModName, setgroupModName] = useState("");
  const [groupModImage, setgroupModImage] = useState("");
  const [groupMembers, setgroupMembers] = useState([]);
  const [groupFiles, setgroupFiles] = useState([]);
  const [groupModId, setgroupModId] = useState(null);
  const [groupMemberString, setgroupMemberString] = useState("");
  const [chatcomponentName, setchatcomponentName] = useState("");
  const [chatComponentTitle, setchatComponentTitle] = useState("");

  const updateDefaultScreen = (stateValue) => {
    setDefaultScreen(stateValue);
  };

  const updateSelectedChat = (stateValue) => {
    setSelectedConversationId(stateValue);
  };

  const updateConversationName = (stateValue) => {
    setConversationName(stateValue);
  };

  const updateSelectedChatLogo = (stateValue) => {
    setSelectedChatLogo(stateValue);
  };
  const updateSelectedChatEmail = (stateValue) => {
    setselectedChatEmail(stateValue);
  };

  const updatedSelectedChatRoom = (stateValue) => {
    setSelectedChatRoomId(stateValue);
  };

  const updatedSelectedChatDetails = (stateValue) => {
    setSelectedChatPhone(stateValue.conversationChatPhone);
    setSelectedChatAddress(stateValue.conversationChatAddress);
    setSelectedChatCompany(stateValue.conversationChatCompany);
  };

  const updatedGroupModeratorName = (stateValue) => {
    setgroupModName(stateValue);
  };

  const updatedGroupModeratorImage = (stateValue) => {
    setgroupModImage(stateValue);
  };

  const updatedGroupMembers = (stateValue) => {
    setgroupMembers(stateValue);
  };

  const updatedGroupFiles = (stateValue) => {
    setgroupFiles(stateValue);
  };

  const updateGroupModeratorId = (modId = null) => {
    setgroupModId(modId);
  };

  const updateGroupMemberString = (memberString = "") => {
    setgroupMemberString(memberString);
  };

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_CHAT")
  ) {
    return (
      <section className="chat_conversations">
        <div className="chat_pnlOuter">
          {/* ---  left panel ---- */}
          <ConversationLeftPanel
            selectedConversationId={selectedConversationId}
            setAllContacts={setAllContacts}
            allContacts={allContacts}
            io={io}
            socket={socket}
            updateDefaultScreen={updateDefaultScreen}
            updateSelectedChat={updateSelectedChat}
            updateConversationName={updateConversationName}
            updateSelectedChatLogo={updateSelectedChatLogo}
            updateSelectedChatEmail={updateSelectedChatEmail}
            updatedSelectedChatRoom={updatedSelectedChatRoom}
            updatedSelectedChatDetails={updatedSelectedChatDetails}
            setchatcomponentName={setchatcomponentName}
            setchatComponentTitle={setchatComponentTitle}
            updatedGroupModeratorName={updatedGroupModeratorName}
            updatedGroupModeratorImage={updatedGroupModeratorImage}
            updatedGroupMembers={updatedGroupMembers}
            updatedGroupFiles={updatedGroupFiles}
            updateGroupModeratorId={updateGroupModeratorId}
            updateGroupMemberString={updateGroupMemberString}
          />

          {/* --- right panel -------- */}
          <ConversationRightPanel
            allContacts={allContacts}
            socket={socket}
            defaultScreen={defaultScreen}
            selectedConversationId={selectedConversationId}
            conversationName={conversationName}
            selectedChatLogo={selectedChatLogo}
            selectedChatEmail={selectedChatEmail}
            selectedChatRoomId={selectedChatRoomId}
            selectedChatPhone={selectedChatPhone}
            selectedChatAddress={selectedChatAddress}
            selectedChatCompany={selectedChatCompany}
            groupModName={groupModName}
            groupModImage={groupModImage}
            groupMembers={groupMembers}
            groupFiles={groupFiles}
            groupModId={groupModId}
            groupMemberString={groupMemberString}
            chatcomponentName={chatcomponentName}
            setchatcomponentName={setchatcomponentName}
            chatComponentTitle={chatComponentTitle}
            setchatComponentTitle={setchatComponentTitle}
          />
        </div>
      </section>
    );
  } else {
    return (
      <section className="chat_conversations">
        <div className="empty_access text-center">
          <div className="empty_pic mb-4">
            {" "}
            <img src={assetImages.emptyVector} alt="" />
          </div>
          <div className="empty_text">
            <p className="fs-lg text-gray fw-semibold mb-4">
              Sorry....! You don't have privilege to see this content
            </p>
          </div>
        </div>
      </section>
    );
  }
};

export default ConversationBody;
