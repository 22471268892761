import React from 'react';
import { Link } from 'react-router-dom';

const AccountSettingsRightPanel = () => {
  return (
    <div className="pnl_mdlpnl">
      <div className="profile_information white_shadow_bx">
        <div className="account_user_outer">
          <div className="account_usr">
            <img src="images/sad.svg" alt="" />
          </div>
          <div className="account_usr_info">
            <h4>
              Hello <strong>Pierre Gagnaire</strong>
            </h4>
            <p>
              We would love for you to stay... but if you want to leave us we
              thank you for the time we have been together.
            </p>
          </div>
        </div>
        <div className="experience_bxOuter">
          <div className="experience_bxItem">
            <div className="profile_info_pnl">
              <div className="profile_info_row account_row">
                <label>Why are you leaving?</label>
                <div className="profile_inner_info">
                  <div className="left_profile_info">
                    <input
                      type="text"
                      value=""
                      className="fill_input"
                      placeholder="Enter Discription"
                    />
                  </div>
                </div>
              </div>
              <div className="profile_info_row account_row">
                <label>How can we convince you to stay?</label>
                <div className="profile_inner_info">
                  <div className="left_profile_info">
                    <input
                      type="password"
                      value=""
                      className="fill_input"
                      placeholder="Enter Discription"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="password_btnOuter">
              <div className="password_btn">
                <Link to="#" className="btn outline">
                  Close Account
                </Link>
                <Link to="#" className="btn fill_btn">
                  Changed My Mind
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountSettingsRightPanel;
