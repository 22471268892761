import React from "react";
import { languageList } from "helper/Common/CommonHelper";

const ChatMessageFlag = ({ language = "en" }) => {
  const [languageFlag] = languageList
    .filter((item) => item.value === language)
    .map((item) => item.flagimageurl);

  return <img src={languageFlag} alt="" />;
};

export default ChatMessageFlag;
