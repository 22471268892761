import React from 'react';


const ConversationAiHistory = () =>{



    return (

        <div className="offcanvas_sidebar history_product">
            <div className="offcanvas offcanvas-end" id="history">
                <div className="offcanvas-header justify-content-end">
                    <button type="button" className="close-btn text-reset filter_cross d-flex align-items-center justify-content-center" data-bs-dismiss="offcanvas" aria-label="Close"><span className="material-icons-outlined">close</span></button>
                </div>
                <div className="offcanvas-body">
                    <div className="histry_row d-flex align-items-center justify-content-between">
                        <h3>Best smartphone under 30,000 inr</h3>
                        <h6>38min</h6>
                    </div>
                    <div className="histry_row d-flex align-items-center justify-content-between">
                        <h3>How we name a gtp</h3>
                        <h6>48min</h6>
                    </div>
                    <div className="histry_row d-flex align-items-center justify-content-between">
                        <h3>Best way to explore new chat</h3>
                        <h6>1hr</h6>
                    </div>
                    

                </div>
            </div>
        </div>


    )


}

export default ConversationAiHistory;