import { assetImages } from 'constants';
import React from 'react';
import { Link } from 'react-router-dom';

// import use translation ------------------------------
import { useTranslation } from 'react-i18next';

const Brands = () => {
  const { t } = useTranslation(); // translation marker

  return (
    <section className="brand_company_sec">
      <div className="container">
        <div className="brand_logo_inner">
          <div className="left_info">
            <h5>{t('Brands we are worked with')}</h5>
          </div>
          <div className="brand_logo_outer">
            <div className="brand_logoItem">
              <Link to="#">
                <img src={assetImages.brandLogo1} alt="" />
              </Link>
            </div>
            <div className="brand_logoItem">
              <Link to="#">
                <img src={assetImages.brandLogo2} alt="" />
              </Link>
            </div>
            <div className="brand_logoItem">
              <Link to="#">
                <img src={assetImages.brandLogo3} alt="" />
              </Link>
            </div>
            <div className="brand_logoItem">
              <Link to="#">
                <img src={assetImages.brandLogo4} alt="" />
              </Link>
            </div>
            <div className="brand_logoItem">
              <Link to="#">
                <img src={assetImages.brandLogo5} alt="" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Brands;
