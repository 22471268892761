/* eslint-disable */
import AuthHeader from "components/common/AuthHeader";
import React, { useContext, useEffect } from "react";
//import context
import { GlobalProvider } from "context/GlobalContext";
import PaymentFailure from "components/PaymentComponents/Failure/Failure";

const Failure = () => {
  return (
    <>
      <header className="aftre_login_header">
        <AuthHeader />
      </header>

      {/* ------ body section --------- */}
      <PaymentFailure />
    </>
  );
};

export default Failure;