import React from 'react';
import MostImportantPanel from '../../MostImportantPanel/MostImportantPanel';

const DocsTab = () => {
  return (
    <div className="inner_pnl_outer">
      <div className="inner_leftpnl">
        <div className="people_details_bx white_shadow_bx">
          <div className="details_hdng">
            <h4>Docs</h4>
          </div>
          <div className="upload_area">
            <div className="upload_item">
              <div className="uploaded_innrBx">
                <div className="upload_icon">
                  <img src="/images/pdf.svg" alt="" />
                </div>
                <div className="upload_txt">
                  <h4>EventReg.pdf</h4>
                  <h5>1.5mb</h5>
                </div>
              </div>
            </div>
            <div className="upload_item">
              <div className="uploaded_innrBx">
                <div className="upload_icon">
                  <img src="/images/pdf.svg" alt="" />
                </div>
                <div className="upload_txt">
                  <h4>Managing.pdf</h4>
                  <h5>1.5mb</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* -------- most important panel ------------- */}
      <MostImportantPanel />
    </div>
  );
};

export default DocsTab;
