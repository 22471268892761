/* eslint-disable */
import CompanyDetailsBody from "components/CompanyComponents/CompanyDetailsComponents/CompanyDetailsBody/CompanyDetailsBody";
import AuthHeader from "components/common/AuthHeader";
//import context
import { GlobalProvider } from "context/GlobalContext";
import React, { useEffect, useContext } from "react";

const CompanyDetails = () => {
  return (
    <>
      <header className="aftre_login_header">
        <AuthHeader />
      </header>

      {/* ------- body section ------------ */}
      <CompanyDetailsBody />
    </>
  );
};

export default CompanyDetails;
