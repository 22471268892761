/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import { Link, useParams, useHistory } from "react-router-dom";

import SuccessStoriesDescription from "components/SuccessStoriesComponents/SuccessStoriesDetailsComponents/SuccessStoriesDescription/SuccessStoriesDescription";
import SuccessStoriesNewsSlider from "components/SuccessStoriesComponents/SuccessStoriesDetailsComponents/SuccessStoriesNewsSlider/SuccessStoriesNewsSlider";
import ContactNow from "components/common/ContactNow";
import Footer from "components/common/Footer";
import Header from "components/common/Header";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData } from "utils/Gateway";

const SuccessStoriesDetails = () => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const params = useParams();

  const [storyName, setStoryName] = useState("");
  const [storyDate, setStoryDate] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyImageUrl, setCompanyImageUrl] = useState("");
  const [successStorySummary, setSuccessStorySummary] = useState("");
  const [successStoryDetails, setSuccessStoryDetails] = useState("");
  const [successStorySitutation, setSuccessStorySitutation] = useState("");
  const [successStoryObjectives, setSuccessStoryObjectives] = useState("");
  const [successStoryProcess, setSuccessStoryProcess] = useState("");
  const [successStoryResults, setsuccessStoryResults] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [companyId, setCompanyId] = useState(null);

  const [ratingCount, setRatingCount] = useState("0.0");
  const [reviewsList, setReviewsList] = useState([]);
  const [ratingPercentArr, setRatingPercentArr] = useState([]);

  const [isReviewLoading, setIsReviewLoading] = useState(false);

  //function for get blog details
  const getSuccessStoryDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_SUCCESS_STORY_DETAILS +
        `/${params.id}` +
        `?token=${token}`;

      const response = await getData(requestUrl);

      console.log("response story details---->", response);

      if (response.status) {
        setStoryName(response.data?.name);
        setCompanyName(response.data.companyname);
        setCompanyImageUrl(response.data?.companylogo);
        setStoryDate(response.data.createddate);
        setSuccessStorySummary(response.data?.summary);
        setSuccessStoryDetails(response.data?.description);
        setSuccessStorySitutation(response.data?.previoussituation);
        setSuccessStoryObjectives(response.data?.previousobjectives);
        setSuccessStoryProcess(response.data?.processdata);
        setsuccessStoryResults(response.data?.resultsdata);
        setUploadedFiles(response.data?.uploads);
        setCompanyId(response.data?.companyid?._id);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all company reviews
  const getReviewsLists = async () => {
    setIsReviewLoading(true);

    let requestURL =
      url.API_BASE_URL +
      url.API_GET_ALL_REVIEWS +
      `?token=${token}&moduleslug=company&moduleid=${companyId}`;

    try {
      const response = await getData(requestURL);

      console.log("review list response", response);

      setIsReviewLoading(false);

      if (response.status) {
        if (response.data) {
          setRatingCount(response.data.rating);
          setReviewsList(response.data.reviews);
          setRatingPercentArr(response.data.ratingbar);
        } else {
          setRatingCount("");
          setReviewsList([]);
          setRatingPercentArr([]);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (params.id) {
      getSuccessStoryDetails();
    }
  }, [params.id]);

  useEffect(() => {
    if (companyId) {
      getReviewsLists();
    }
  }, [companyId]);

  useEffect(() => {
    document.title = "Success Stories"; // Update the title when Register component mounts
  }, []);
  return (
    <>
      {/* ---- Header section start----- */}
      <Header isSuccess={true} />
      {/* ---- Header section end----- */}

      {/* ------- description area --------- */}
      <SuccessStoriesDescription
        storyName={storyName}
        storyDate={storyDate}
        companyName={companyName}
        companyImageUrl={companyImageUrl}
        successStorySummary={successStorySummary}
        successStoryDetails={successStoryDetails}
        successStorySitutation={successStorySitutation}
        successStoryObjectives={successStoryObjectives}
        successStoryProcess={successStoryProcess}
        successStoryResults={successStoryResults}
        uploadedFiles={uploadedFiles}
      />

      {/* --------- news slider --------- */}
      <SuccessStoriesNewsSlider reviewsList={reviewsList} />

      {/* ------ contact now ------ */}
      <ContactNow />

      {/* ------- footer ------- */}
      <Footer />
    </>
  );
};

export default SuccessStoriesDetails;
