import React from 'react';
import AuthUserBox from 'components/common/AuthUserBox';
import ProfileMenuPanel from 'components/common/ProfileMenuPanel';

const PasswordResetLeftPanel = ({ moduleName }) => {
  return (
    <div className="pnl_leftpnl hidden_mobile">
      <AuthUserBox />
      <ProfileMenuPanel moduleName={moduleName} />
    </div>
  );
};

export default PasswordResetLeftPanel;
