import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const PasswordDetailsRightPanel = () => {
  return (
    <div className="pnl_mdlpnl">
      <div className="profile_information white_shadow_bx">
        <div className="info_hdng info_mainhdng">
          <h2>Password Settings</h2>
        </div>
        <div className="experience_bxOuter">
          <div className="experience_bxItem">
            <div className="profile_info_pnl">
              <div className="profile_info_row">
                <label>Password</label>
                <div className="profile_inner_info">
                  <div className="left_profile_info">
                    <input
                      type="password"
                      value="12345"
                      className="fill_input"
                      placeholder="Enter Password"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="password_btnOuter">
              <div className="password_btn">
                <Link to="/passwordreset" className="btn outline">
                  Reset
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordDetailsRightPanel;
