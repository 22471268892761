import React from 'react';
import PasswordDetailsLeftPanel from '../PasswordDetailsLeftPanel/PasswordDetailsLeftPanel';
import PasswordDetailsRightPanel from '../PasswordDetailsRightPanel/PasswordDetailsRightPanel';

const PasswordDetailsBody = () => {
  return (
    <section className="feed_pnltotal">
      <div className="container-fluid">
        <div className="pnl_outer profile_pnlOuter">
          <PasswordDetailsLeftPanel moduleName="password" />
          <PasswordDetailsRightPanel />
        </div>
      </div>
    </section>
  );
};

export default PasswordDetailsBody;
