import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
//import { assetImages } from "constants";

const SuggestEvent = ({params}) => {

  useEffect(()=>{
    console.log('params', params)
  }, [params])


  return (
    <div className="ai_productOuter">

        <Link to="#" className="ai_product_row d-flex gap-2">
            <div className="ai_procudt_pic event_chat_bx d-block">
                <h3>12</h3>
                <h5>January <span>2023</span></h5>
            </div>
            <div className="ai_procudt_text">
                <h3>DTDC Business Meeting</h3>
                <p className="d-flex align-items-center gap-1">  
                <span className="material-icons-outlined">place </span>Madrid, Spain</p>
                <div className="ai_links mt-2">
                    <ul className="d-flex align-items-center gap-2 flex-wrap">
                        <li>Meeting</li>
                        <li>Business</li>
                        <li>Tech</li>
                    </ul>
                </div>
            </div>
        </Link>

        <Link to="#" className="ai_product_row d-flex gap-2">
            <div className="ai_procudt_pic event_chat_bx d-block">
                <h3>12</h3>
                <h5>January <span>2023</span></h5>
            </div>
            <div className="ai_procudt_text">
                <h3>DTDC Business Meeting</h3>
                <p className="d-flex align-items-center gap-1">  
                <span className="material-icons-outlined">place </span>Madrid, Spain</p>
                <div className="ai_links mt-2">
                    <ul className="d-flex align-items-center gap-2 flex-wrap">
                        <li>Meeting</li>
                        <li>Business</li>
                        <li>Tech</li>
                    </ul>
                </div>
            </div>
        </Link>

    
        <div><Link to="#" className="btn outline" >View more events?</Link></div>

    </div>

    
  );
};

export default SuggestEvent;
