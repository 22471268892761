/* eslint-disable */
import AuthHeader from "components/common/AuthHeader";
import Plans from "components/Pricing/Plans/Plans";

const Pricing = () => {
  return (
    <>
      <header className="aftre_login_header">
        <AuthHeader />
      </header>

      {/* ------ body section --------- */}
      <Plans />
    </>
  );
};

export default Pricing;
