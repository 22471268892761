/* eslint-disable */
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const ConversationRightWelcomePanel = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  // useEffect(()=>{
  //   console.log('userInfo in right column', userInfo.name);

  // })

  return (
    <div className="chat_rightpnl">
      <div className="welcome_screnBx">
        <div className="welcome_innrbx w-100">
          <div className="welcome_txtpnl text-center">
            <h2>Welcome, {userInfo.name}</h2>
            <p>Ready? Chat with friends and send email in same place</p>
          </div>
          <div className="welcome_graphics_outer">
            <div className="row">
              <div className="col-lg-4 col-sm-6">
                <div className="grapics_item">
                  <div className="chat_graphice_pic">
                    <img src="images/chat-graphics1.png" alt="" />
                  </div>
                  <h4>Connect with people and collaborate</h4>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="grapics_item">
                  <div className="chat_graphice_pic">
                    <img src="images/chat-graphic2.png" alt="" />
                  </div>
                  <h4>Promote yourself </h4>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="grapics_item">
                  <div className="chat_graphice_pic">
                    <img src="images/chat-graphic3.png" alt="" />
                  </div>
                  <h4>Ask anything to Lobees AI</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConversationRightWelcomePanel;
