import React, { useEffect, useState } from "react";
//import { Link } from "react-router-dom";
// import use translation ------------------------------
//import { useTranslation } from "react-i18next";

/*import url and gateway methods */
import { getData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import { ecosystemSlug } from "Config/Config";

const Plans = () => {
  const token = localStorage.getItem("token");
  //const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [planList, setPlanList] = useState([]);
  const [selectedPlanId, setSelectedPlanId] = useState("");
  const [loading, setLoading] = useState(false);

  //function for get all lead records
  const getAllPlans = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_PLANS +
        `?ecosystemslug=${ecosystemSlug}`;

      console.log("url of group list---------->", requestUrl);

      const response = await getData(requestUrl);

      console.log("response in plan list------->", response);

      if (response.status) {
        setPlanList(response.data);
        //resetFilterData();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // subscribe to plan
  // send api request to create an invoice for this plan
  // on receive response true, redirect to cart with invoice id
  const subscribe = async (planId) => {
    setSelectedPlanId(planId);
    setLoading(true);
    console.log(planId);

    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_CREATE_INVOICE_BYPLAN +
        `/${planId}?token=${token}`;

      console.log(requestUrl);

      const response = await getData(requestUrl);

      console.log(response);
      setLoading(false);
      setSelectedPlanId("");

      if (response.status && response.data) {
        if (response.data._id) {
          // set invoice id in localstorage
          localStorage.setItem("invoiceid", response.data._id);

          localStorage.setItem("cartitemcount", 1);

          // redirect to cart page
          window.location.href = "/cart";
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getAllPlans();
  }, []);

  return (
    <section className="plan_sec">
      <div className="container">
        <div className="page_hdng dark_grnheading text-center">
          <h2 className="mb-2">
            We have something <br />
            in your budget
          </h2>
          <p>
            Get the best offerings form a team that dedicated for you and yours.
          </p>
        </div>
        <div className="pricing_outer">
          <div className="row">
            {planList.length > 0
              ? planList.map((plan, index) => {
                  return (
                    <div className="col-lg-4 col-md-6 mb-4" key={index}>
                      <div className="pricing_item p-4">
                        <div className="pricing_head pb-4 mb-4">
                          <h5 className="mb-4">{plan.title}</h5>
                          <h3> &euro; {plan.price} </h3>
                          <p> {plan.description} </p>
                        </div>
                        <div className="pricing_list mb-4">
                          <ul className="list_stye_none">
                            {plan.kpis.length > 0
                              ? plan.kpis.map((kpi, index) => {
                                  return (
                                    <li
                                      key={index}
                                      className="d-flex align-items-center gap-2"
                                    >
                                      <i className="material-icons-outlined d-flex align-items-center justify-content-center rounded-circle">
                                        done
                                      </i>
                                      <span>{kpi}</span>
                                    </li>
                                  );
                                })
                              : ""}
                          </ul>
                        </div>
                        <div className="pricing_btn">
                          <button
                            className="btn w-100"
                            onClick={() => subscribe(plan._id)}
                          >
                            {loading && selectedPlanId === plan._id ? (
                              <div
                                className="mx-2 spinner-border spinner-border-sm"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                            Subscribe
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })
              : ""}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Plans;
