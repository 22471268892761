import { assetImages } from "constants";
import React from "react";
import { Link } from "react-router-dom";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";

const SuccessModal = ({ afterModalClose }) => {
  const { t } = useTranslation(); // translation marker

  return (
    <div className="digital_success_modal">
      <div
        className="modal fade"
        id="success_modal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="kit_success">
                <div className="kit_rewards_icon">
                  <img src={assetImages.rewardLogo} alt="" />
                </div>
                <h2>{t("Thanks")}</h2>
                <p>
                  {t(
                    "Your feedback is submitted successfully"
                  )}
                </p>
                <Link
                  to="#"
                  data-bs-dismiss="modal"
                  className="btn"
                  onClick={afterModalClose}
                >
                  Close
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessModal;
