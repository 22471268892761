/* eslint-disable */
import AuthHeader from "components/common/AuthHeader";
import React, { useEffect, useContext } from "react";

//import context
import { GlobalProvider } from "context/GlobalContext";
import ActivityDetailsBody from "components/ActivitiesComponents/ActivitiesDetailsComponents/ActivityDetailsBody/ActivityDetailsBody";

const ActivitiesDetails = () => {
  return (
    <>
      <header className="aftre_login_header">
        <AuthHeader />
      </header>

      {/* ---------- body section ------------ */}
      <ActivityDetailsBody />
    </>
  );
};

export default ActivitiesDetails;
