import React from "react";
import { Link } from "react-router-dom";
// import use translation ------------------------------
import { useTranslation } from "react-i18next";
import { assetImages } from "constants";

const KitDigitalBanner = () => {
  const { t } = useTranslation(); // translation marker

  return (
    <section
      className="banner_area"
      style={{ backgroundImage: `url(${assetImages.connectBannerDotsImg})` }}
    >
      <div className="container">
        <div className="banner_outer">
          <div className="row">
            <div className="col-lg-12">
              <div className="banner_content">
                <h1>
                  {t("digitize Your")} <br />
                  {t("business With")} <br />
                  <span>{t("KIT DIGITAL")}</span>
                </h1>
                <p>
                  {t(
                    "The 'Digital Kit' offers up to €12,000 from EU's Next Generation funds to enhance SMEs and self-employed in Spain via digitizing agents like Lobees."
                  )}
                </p>

                {/* --- get started btn start --- */}
                <div className="bnr_btns">
                  <Link
                    to="#"
                    className="btn"
                    data-bs-toggle="modal"
                    data-bs-target="#register_mdl"
                  >
                    {t("Get Started")}
                  </Link>
                </div>
                {/* --- get started btn end --- */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default KitDigitalBanner;
