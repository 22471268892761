

const GetDateTime = (currentTime) => {

    const formattedTime = currentTime.toLocaleString(undefined, {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        //second: '2-digit',
        hour12: true, // Set this to true to display in 12-hour format with AM/PM
    });

    return formattedTime;
}

const GetDate = (currentTime) => {



}

export{
    GetDateTime,
    GetDate,

}