/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import SustainabilityAchievements from "components/SustainabilityComponents/SustainabilityAchievements/SustainabilityAchievements";
import SustainabilityAskQuestions from "components/SustainabilityComponents/SustainabilityAskQuestions/SustainabilityAskQuestions";
import SustainabilityBanner from "components/SustainabilityComponents/SustainabilityBanner/SustainabilityBanner";
import SustainabilityBenifits from "components/SustainabilityComponents/SustainabilityBenifits/SustainabilityBenifits";
import SustainabilityJoin from "components/SustainabilityComponents/SustainabilityJoin/SustainabilityJoin";
import SustainabilityWorkProcess from "components/SustainabilityComponents/SustainabilityWorkProcess/SustainabilityWorkProcess";
import ContactNow from "components/common/ContactNow";
import CustomerFeedback from "components/common/CustomerFeedback";
import Footer from "components/common/Footer";
import Header from "components/common/Header";

const Sustainability = () => {
  // Create a ref to the ContactNow component's DOM element
  const contactNowRef = useRef(null);

  const [sourceName, setSourceName] = useState("");

  // Function to auto-scroll to the ContactNow component
  const autoScollContactNow = () => {
    // Scroll to the ContactNow component's DOM element with smooth behavior
    contactNowRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    document.title = "Sustainability"; // Update the title when Register component mounts
  }, []);
  return (
    <>
      {/* ---- Header section ----- */}
      <Header />

      {/* ---------- banner section ----------- */}
      <SustainabilityBanner />

      {/* --------- join section ----------- */}
      <SustainabilityJoin />

      {/* ---------- suatainability benifits -------------- */}
      <SustainabilityBenifits />

      {/* --------- work process -------- */}
      <SustainabilityWorkProcess />

      {/* --------- frequently asked questions ----------- */}
      <SustainabilityAskQuestions
        source="Sustainability"
        autoScollContactNow={autoScollContactNow}
        setSourceName={setSourceName}
      />

      {/* ---------- customer feedback ------------ */}
      <CustomerFeedback />

      {/* ----------- Sustainability Achievements ------------- */}
      <SustainabilityAchievements />

      {/* --- contact now section --- */}
      <div ref={contactNowRef} />
      <ContactNow sourceName={sourceName} setSourceName={setSourceName} />

      {/* -------- footer -------- */}
      <Footer />
    </>
  );
};

export default Sustainability;
