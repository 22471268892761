import { useEffect } from "react";
import AuthHeader from "components/common/AuthHeader";
import CvBuilderMainPanel from "components/CvBuilder/CvBuilderMainPanel/CvBuilderMainPanel";

const CvBuilder = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <header className="aftre_login_header">
        <AuthHeader />
      </header>

      <CvBuilderMainPanel />
    </>
  );
};

export default CvBuilder;
