import React from 'react';
import { Link } from 'react-router-dom';

const VerifyEmailModal = () => {
  return (
    <div className="post_modal forgot_mdl">
      <div className="modal fade" id="verify_mdl">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">
                Verify Email <span>Please enter OTP we just sent you on</span>
              </h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="material-icons-outlined">close</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="email_verify">
                <span>mariadsouza@email.com</span>
                <Link to="#">
                  <i className="material-icons-outlined"> edit </i>
                </Link>
              </div>
              <div className="post_description">
                <label>Enter OTP</label>
                <input
                  type="number"
                  value=""
                  className="form-control"
                  placeholder="Enter OTPl"
                />
              </div>
              <div className="post_btn">
                <Link to="/passwordupdate" className="btn">
                  Next
                </Link>
              </div>
              <div className="resend_btn">
                <Link to="#">
                  <i className="material-icons-outlined">sync</i>
                  Resend OTP
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmailModal;
