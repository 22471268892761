/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";
import { assetImages } from "constants";

import * as url from "helper/UrlHelper";

const AuthUserBox = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  return (
    <div className="usr_left_top white_shadow_bx">
      <div className="user_left">
        <div className="pofile_pic">
          <img
            src={
              userInfo.photoimage
                ? url.SERVER_URL + userInfo.photoimage?.path
                : assetImages.defaultUser
            }
            alt=""
          />
        </div>
        <div className="pofile_info">
          <h3>
            {userInfo.name ? (
              <span>
                {userInfo.name} {userInfo.surname}
              </span>
            ) : (
              <span>{userInfo.email}</span>
            )}
          </h3>
          {userInfo.position && <h5>{userInfo.position}</h5>}
        </div>
      </div>
      {/* <div className="user_right">
        <ul className="list_stye_none">
          <li className="dropdown">
            <Link to="#" data-bs-toggle="dropdown">
              <i className="material-icons-outlined">more_vert</i>
            </Link>
            <ul className="list_stye_none dropdown-menu">
              <li>
                <Link to="#">Update image</Link>
              </li>
              <li>
                <Link to="#">Lorem ipsum</Link>
              </li>
              <li>
                <Link to="#">Lorem ipsum</Link>
              </li>
            </ul>
          </li>
        </ul>
      </div> */}
    </div>
  );
};

export default AuthUserBox;
