import React from 'react';

const VideoModal = () => {
  return (
    <div className="registration_modal  vdo_mdl">
      <div className="modal fade show" id="video_modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="material-icons-outlined">close</i>
              </button>
            </div>
            <div className="modal-body">
              <div className="video_bx">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/w7VFfwZYeG4"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoModal;
