/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";

// import resources ------------------------------
import { useTranslation } from "react-i18next";
import { assetImages } from "constants";

import * as url from "helper/UrlHelper";
import { getData, postData } from "utils/Gateway";

const Success = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const invoiceId = searchParams.get("ref");

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [planId, setPlanId] = useState(null);

  const [currency, setCurrency] = useState("€");
  const [invoiceData, setInvoiceData] = useState(null);

  const [cartItems, setCartItems] = useState([]);

  // create payment record for this invoice
  const savePaymentHandler = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_CREATE_PAYMENT_BY_INVOICE +
        `/${invoiceId}?token=${token}`;

      console.log("payment url", requestUrl);

      const response = await getData(requestUrl);

      console.log(response.data);
    } catch (error) {
      console.log("Error", error.message);
    }
  };

  // save subscription
  const saveSubscriptionHandler = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_CREATE_PLAN_SUBSCRIPTION +
        `/${planId}?token=${token}`;

      console.log("subscription url", requestUrl);

      const response = await getData(requestUrl);

      console.log(response.data);
    } catch (error) {
      console.log("Error", error.message);
    }
  };

  //function for company details
  const getInvoiceDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_INVOICE_DETAILS +
        `/${invoiceId}?token=${token}`;

      //console.log(requestUrl);

      const response = await getData(requestUrl);

      console.log(response.data);

      if (response.data) {
        setInvoiceData(response.data);

        setCartItems(response.data.invoiceitems);

        if (response.data && response.data.plan) {
          setPlanId(response.data.plan._id);
        } else {
          setPlanId(null);
        }

        // remove the invoice id from localstorage
        localStorage.removeItem("invoiceid");
      }
    } catch (error) {
      console.log("Error", error.message);
    }
  };

  // by default get the invoice details and set the payment
  useEffect(() => {
    getInvoiceDetails();

    savePaymentHandler();
  }, []);

  // if there is any plan in invoice, save the subscription for logged in user
  useEffect(() => {
    if (planId) {
      saveSubscriptionHandler();
    }
  }, [planId]);

  return (
    <section className="pt-70">
      <div className="container-fluid">
        <div className="py-4 px-3 cart_sec">
          <div className="row">
            <div className="col-lg-12">
              <div className="cart_leftpnl p-4 rounded-10 overflow-hidden">
                <div className="alert_msg_top bg-extra-light p-4 d-flex align-items-center justify-content-between gap-2 mb-4">
                  <div className="left_icon_msg d-flex align-items-center gap-2">
                    <div className="icon d-flex align-items-center justify-content-center">
                      <img src={assetImages.paymentSuccessLogo} alt="success" />
                    </div>
                    <div className="icon_info_text">
                      <h3 className="text-black fw-semibold mb-1 fs-xl">
                        Successful
                      </h3>
                      <p className="text-gray fs-sm">
                        We received your payment
                      </p>
                    </div>
                  </div>
                  <div className="right_success_btn">
                    <Link to="/" className="btn">
                      Order More Items
                    </Link>
                  </div>
                </div>

                <div className="oredr_info_outer">
                  {cartItems.length > 0
                    ? cartItems.map((cartItem, index) => {
                        return (
                          <div
                            className="order_info_row mb-4 pb-4 d-flex align-items-center justify-content-between"
                            key={index}
                          >
                            <div className="left_order_info">
                              <h5 className="mb-2 fs-sm text-gray">
                                Invoice ID : {invoiceData.invoiceid}
                              </h5>
                              <h3 className="mb-2 fs-md text-black fw-semibold">
                                {invoiceData.plan ? "PLAN : " : ""}
                                {cartItem.description}
                              </h3>
                              <ul className="list_stye_none d-flex align-items-center gap-4">
                                {invoiceData.plan ? (
                                  <li className="d-flex align-items-center gap-2">
                                    <label className="mb-0 text-gray fs-sm d-inline-block">
                                      Duration:
                                    </label>
                                    <span className="text-black fs-sm d-inline-block fw-medium">
                                      {invoiceData.plan.duration} days
                                    </span>
                                  </li>
                                ) : (
                                  ""
                                )}

                                <li className="d-flex align-items-center gap-2">
                                  <label className="mb-0 text-gray fs-sm d-inline-block">
                                    Discount:
                                  </label>
                                  <span className="text-black fs-sm d-inline-block fw-medium">
                                    {cartItem.discountpercent}%
                                  </span>
                                </li>

                                <li className="d-flex align-items-center gap-2">
                                  <label className="mb-0 text-gray fs-sm d-inline-block">
                                    Tax:
                                  </label>
                                  <span className="text-black fs-sm d-inline-block fw-medium">
                                    {cartItem.taxpercent}%
                                  </span>
                                </li>
                              </ul>
                            </div>
                            <div className="right_order_info">
                              <h4 className="fs-md fw-medium text-black d-flex align-items-center gap-1">
                                {cartItem.totalprice}€
                                <span className="material-icons text-green fs-lg">
                                  verified
                                </span>
                              </h4>
                            </div>
                          </div>
                        );
                      })
                    : ""}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Success;
