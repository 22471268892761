import MyProfileBody from 'components/MyProfileComponents/MyProfileBody/MyProfileBody';
import AuthHeader from 'components/common/AuthHeader';
import React from 'react';

const MyProfile = () => {
  return (
    <>
      {/* -------- header ------------ */}
      <AuthHeader />

      {/* ---------- body --------- */}
      <MyProfileBody />
    </>
  );
};

export default MyProfile;
