import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { assetImages } from "constants";

const SuggestContact = ({params}) => {

  useEffect(()=>{
    console.log('params', params)
  }, [params])


  return (
    <div className="ai_productOuter">

        <Link to="#" className="ai_product_row d-flex gap-2">
            <div className="ai_procudt_pic">
                <img src={assetImages.defaultUser} alt=""/>
            </div>
            <div className="ai_procudt_text">
                <h3>Pierre Gagnaire</h3>
                <p className="d-flex align-items-center gap-1">  
                    <span className="material-icons-outlined">work_outline </span>  
                    Chef, JW Marriott
                </p>
                <div className="chat_rating d-flex align-items-center gap-1 mt-1">
                    <i className="material-icons-outlined ornge_icon">star </i>
                    <h5>4.5 <span>(32)</span></h5>
                </div>
            </div>
        </Link>

        <Link to="#" className="ai_product_row d-flex gap-2">
            <div className="ai_procudt_pic">
                <img src="images/people1.png" alt=""/>
            </div>
            <div className="ai_procudt_text">
                <h3>Carlos Mateo</h3>
                <p className="d-flex align-items-center gap-1">  
                    <span className="material-icons-outlined">work_outline </span>  
                    Manager, DEL
                </p>
                <div className="chat_rating d-flex align-items-center gap-1 mt-1">
                    <i className="material-icons-outlined ornge_icon">star </i>
                    <h5>4.5 <span>(32)</span></h5>
                </div>
            </div>
        </Link>


        <Link to="#" className="ai_product_row d-flex gap-2">
            <div className="ai_procudt_pic">
                <img src="images/people2.png" alt=""/>
            </div>
            <div className="ai_procudt_text">
                <h3>Maria Pedro H</h3>
                <p className="d-flex align-items-center gap-1">  
                    <span className="material-icons-outlined">work_outline </span>  
                    Digital Marketing Professional
                </p>
                <div className="chat_rating d-flex align-items-center gap-1 mt-1">
                    <i className="material-icons-outlined ornge_icon">star </i>
                    <h5>4.5 <span>(32)</span></h5>
                </div>
            </div>
        </Link>

    
        <div><Link to="#" className="btn outline" >View more people?</Link></div>

    </div>

    
  );
};

export default SuggestContact;
