/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";
import * as url from "helper/UrlHelper";

//import images
import { assetImages } from "constants";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";

const EventListCard = ({ eventData = {} }) => {
  const { t } = useTranslation(); // translation marker

  const memberLimit = 5; //for displaying the limit number of members

  return (
    <div className="groups_item">
      <div className="top_company_row">
        <div className="event_hdng">
          <h3>{eventData.title}</h3>
          <h5>
            <i className="material-icons-outlined"> event_note </i>
            <span>{eventData.eventstartdate}</span>
          </h5>
        </div>
        <div className="event_tags">
          <ul className="list_stye_none">
            <li>
              <i className="material-icons-outlined ornge_icon">star</i>
              <span className="rating d-flex gap-1">
                {eventData.ratingtotal} <em>({eventData.reviewtotal})</em>
              </span>
            </li>
            <li className="event_loc">
              <i className="material-icons-outlined">place</i>
              <span>{eventData.eventlocation}</span>
            </li>
          </ul>
        </div>
      </div>

      <div className="grp_pic_txtInnr">
        <div className="left_group">
          <div className="event_para">
            <p>{eventData.summary}</p>
          </div>
          <div className="event_user grps_user">
            <ul className="list_stye_none">
              {eventData.eventmemberlist
                .slice(0, memberLimit)
                .map((member, index) => {
                  return (
                    <li key={index}>
                      <Link to="#">
                        <img
                          height={40}
                          width={40}
                          className="rounded-circle"
                          src={
                            member.eventmemberimage == ""
                              ? assetImages.defaultUser
                              : url.SERVER_URL + member.eventmemberimage
                          }
                          alt=""
                        />
                      </Link>
                    </li>
                  );
                })}
              {eventData.eventmembercount ? (
                <li>
                  {eventData.eventmembercount > memberLimit ? (
                    <Link to="#">
                      +{eventData.eventmembercount - memberLimit} more
                    </Link>
                  ) : null}
                </li>
              ) : null}
            </ul>
          </div>
        </div>
        <div className="groups_imgbx_pnl">
          <div className="grp_picOuter">
            {eventData.eventlogo === "" ? (
              <span
                className="placeholder"
                style={{ width: "130px", height: "130px" }}
              ></span>
            ) : (
              <img
                className="group_pic rounded"
                src={url.SERVER_URL + eventData.eventlogo}
                alt=""
              />
            )}
          </div>
        </div>
      </div>

      <div className="details_btn">
        <Link to={`/events/details/${eventData._id}`} className="btn outline">
          {t("View Details")}
        </Link>
      </div>
    </div>
  );
};

export default EventListCard;
