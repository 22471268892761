import React from 'react';
import AuthHeader from 'components/common/AuthHeader';
import ImprovementAreaBody from 'components/ImprovementAreaComponents/ImprovementAreaBody/ImprovementAreaBody';

const ImprovementArea = () => {
  return (
    <>
      {/* -------- header ------------ */}
      <AuthHeader />

      {/* ---------- body --------- */}
      <ImprovementAreaBody />
    </>
  );
};

export default ImprovementArea;
