import React from "react";

const TagsAddedSuccessModal = ({ afterTagModalClose }) => {
  return (
    <div className="post_modal success_greetings_mdl">
      <div className="modal fade" id="success_mdl" data-bs-backdrop="static">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="gretings_icon">
                <span className="material-icons-outlined"> verified </span>
              </div>
              <div className="gretings_hdng">
                <h3>Tags Added Successfully</h3>
                <h6>Your tag has been successfully updated</h6>
              </div>
              <div className="post_btn post_bnts_outer">
                <button
                  className="btn"
                  data-bs-dismiss="modal"
                  onClick={afterTagModalClose}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TagsAddedSuccessModal;
