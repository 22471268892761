import React from 'react';
import { Link } from 'react-router-dom';
// import use translation ------------------------------
import { useTranslation } from 'react-i18next';

const HomeModules = () => {
  const { t } = useTranslation(); // translation marker

  return (
    <section className="help_system_area pd_130">
      <div className="help_dots help_dots1">
        <img src="images/help-dots1.png" alt="" />
      </div>
      <div className="help_dots help_dots2">
        <img src="images/help-dots2.png" alt="" />
      </div>
      <div className="container">
        <div className="page_hdng dark_grnheading text-center">
          <h5>{t('How do we help organizations to')}</h5>
          <h2>
            {t('here show all modules &')} <br />{' '}
            {t('it’s contribution in making')} <br /> {t('things easier')}
          </h2>
        </div>
        <div className="help_outer">
          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <div className="help_item">
                <div className="circle_icon">
                  <span className="material-icons-outlined">
                    space_dashboard
                  </span>
                </div>
                <div className="help_text">
                  <h3>{t('CRM or PRM')}</h3>
                  <p>
                    {t(
                      'Company on the list not involved in technology has its origins as far back as 1888 as a textile company.'
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="help_item">
                <div className="circle_icon">
                  <span className="material-icons-outlined">work_outline</span>
                </div>
                <div className="help_text">
                  <h3>{t('Challenges')}</h3>
                  <p>
                    {t(
                      'It is now a holding company with interests a wide variety of sectors including utilities, railroads and insurance companies'
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="help_item">
                <div className="circle_icon">
                  <span className="material-icons-outlined">inventory</span>
                </div>
                <div className="help_text">
                  <h3>{t('Project Management')}</h3>
                  <p>
                    {' '}
                    {t(
                      "Schools in the National Universities category offer a full range of undergraduate majors, plus master's and Ph.D."
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="help_item">
                <div className="circle_icon">
                  <span className="material-icons-outlined">hub</span>
                </div>
                <div className="help_text">
                  <h3>{t('Social Network')}</h3>
                  <p>
                    {t(
                      'Now a holding company with interests a wide variety of sectors including utilities, railroads and insurance.'
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="help_item">
                <div className="circle_icon">
                  <span className="material-icons-outlined">school</span>
                </div>
                <div className="help_text">
                  <h3>{t('Course Management')}</h3>
                  <p>
                    {' '}
                    {t(
                      "Schools in the National Universities category offer a full range of undergraduate majors, plus master's and Ph.D."
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="help_item">
                <div className="circle_icon">
                  <span className="material-icons-outlined">storefront</span>
                </div>
                <div className="help_text">
                  <h3>{t('Marketplace')}</h3>
                  <p>
                    {t(
                      'Company on the list not involved in technology has its origins as far back as 1888 as a textile company.'
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="load_morebtn text-center">
            <Link to="#" className="btn black_btn">
              {t('Load More')}
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeModules;
