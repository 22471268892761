import AuthMenuPanel from "components/common/AuthMenuPanel";
import AuthUserBox from "components/common/AuthUserBox";
import React from "react";

const CourseLandingLeftPanel = () => {
  return (
    <div className="pnl_leftpnl hidden_mobile">
      <AuthUserBox />
      <AuthMenuPanel moduleName="course" />
    </div>
  );
};

export default CourseLandingLeftPanel;
