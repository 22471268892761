/* eslint-disable */
import React from "react";
import AuthRouteMiddleware from "./routes/AuthMiddleWare";
import GlobalContext from "context/GlobalContext";

const App = () => {
  return (
    <GlobalContext>
      <AuthRouteMiddleware />
    </GlobalContext>
  );
};

export default App;
