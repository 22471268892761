/* eslint-disable */
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { putData } from "utils/Gateway";

//import context
import { GlobalProvider } from "context/GlobalContext";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";

const ResetPassowordModal = () => {
  const { userEmailforOtp, setuserEmailforOtp } = useContext(GlobalProvider);
  const { t } = useTranslation(); // translation marker
  const [userPassword, setUserPassword] = useState("");
  const [passwordWarning, setPasswordWarning] = useState(false);
  const [userConfirmPassword, setUserConfirmPassword] = useState("");
  const [confirmPasswordWarning, setConfirmPasswordWarning] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordNotMatched, setPasswordNotMatched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // function for toggle passowrd to text
  const togglePassowrd = () => {
    setShowPassword(!showPassword);
  };

  // function for change text type
  const toggleConfirmPassowrd = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  //validation function
  const validateForm = () => {
    let isValid = true;

    if (userPassword === "") {
      setPasswordWarning(true);
      isValid = false;
    }

    if (userConfirmPassword === "") {
      setConfirmPasswordWarning(true);
      isValid = false;
    }

    return isValid;
  };

  //chnage passowrd function
  const changePasswordHandler = async () => {
    if (validateForm()) {
      if (userPassword === userConfirmPassword) {
        setPasswordNotMatched(false);
        try {
          setIsLoading(true);
          let userData = {
            email: userEmailforOtp,
            password: userPassword,
          };

          let requestURL = url.API_BASE_URL + url.API_UPDATE_PASSSWORD;

          const response = await putData(requestURL, userData);

          setIsLoading(false);
          if (response.status) {
            resetModal();
            //hide reset passowrd modal
            let loginModal = document.querySelector("#reset_password");
            let modal = bootstrap.Modal.getInstance(loginModal);
            modal.hide();

            //show success_modal modal
            let myModal = new bootstrap.Modal(
              document.getElementById("congrats_success_mdl")
            );
            myModal.show();
          }
        } catch (error) {
          console.log(error.message);
        }
      } else {
        setPasswordNotMatched(true);
      }
    }
  };

  //function for reset modal
  const resetModal = () => {
    setuserEmailforOtp("");
    setUserPassword("");
    setUserConfirmPassword("");
  };

  return (
    <div className="registration_modal">
      <div
        className="modal fade show"
        id="reset_password"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog small_modal">
          <div className="modal-content">
            <div className="modal-header">
              <div className="signin_hdng text-left">
                <h3>{t("Forgot Password")}</h3>
                <h5>{t("Enter your new password")}</h5>
              </div>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={resetModal}
              >
                <i className="material-icons-outlined">close</i>
              </button>
            </div>
            <div className="modal-body">
              <div className="login_body">
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="landing_login_fillOuter">
                    {/* ------ passowrd section start ----- */}
                    <div className="input_fill_landing">
                      <label>{t("Password")}</label>
                      <div className="eye_btnfill">
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control"
                          placeholder={t("New Password")}
                          value={userPassword}
                          onChange={(e) => {
                            setPasswordWarning(false);
                            setUserPassword(e.target.value);
                          }}
                        />
                        <Link
                          to="#"
                          className="copy_link"
                          onClick={togglePassowrd}
                        >
                          <span className="material-icons-outlined">
                            {" "}
                            visibility
                          </span>
                        </Link>
                      </div>
                    </div>
                    {/* ------ passowrd section end ----- */}
                    {passwordWarning && (
                      <p className="error">
                        <span className="material-icons-outlined">info</span>
                        {t("Please enter password")}
                      </p>
                    )}

                    {/* ------ confirm passowrd section start ----- */}
                    <div className="input_fill_landing">
                      <label>{t("Confirm Password")}</label>
                      <div className="eye_btnfill">
                        <input
                          type={showConfirmPassword ? "text" : "password"}
                          value={userConfirmPassword}
                          className="form-control"
                          placeholder={t("Re-enter Password")}
                          onChange={(e) => {
                            setConfirmPasswordWarning(false);
                            setUserConfirmPassword(e.target.value);
                          }}
                        />
                        <Link
                          to="#"
                          className="copy_link"
                          onClick={toggleConfirmPassowrd}
                        >
                          <span className="material-icons-outlined">
                            {" "}
                            visibility
                          </span>
                        </Link>
                      </div>
                    </div>
                    {/* ------ confirm passowrd section end ----- */}

                    {confirmPasswordWarning && (
                      <p className="error">
                        <span className="material-icons-outlined">info</span>
                        {t("Please enter confirm password")}
                      </p>
                    )}

                    {passwordNotMatched && (
                      <p className="error">
                        <span className="material-icons-outlined">info</span>
                        {t("Password and confirm password not matched")}
                      </p>
                    )}
                  </div>
                  {/* ------ update button section start ----- */}
                  <div className="apply_btn d-flex justify-content-end">
                    <button
                      className="btn"
                      disabled={isLoading ? true : false}
                      style={{
                        cursor: isLoading ? "not-allowed" : "pointer",
                      }}
                      onClick={changePasswordHandler}
                    >
                      {t("Update Password")}
                      {isLoading && (
                        <div
                          className="mx-2 spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      )}
                    </button>
                  </div>
                  {/* ------ update button section end ----- */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassowordModal;
