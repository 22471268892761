/* eslint-disable */
import React, { useContext, useState } from "react";
import { Link, useParams } from "react-router-dom";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { postData } from "utils/Gateway";

//import context
import { GlobalProvider } from "context/GlobalContext";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";

//import images
import { assetImages } from "constants";

const RecomandationModal = ({ recieverId, userName, userImage, token }) => {
  const params = useParams();
  const { t } = useTranslation(); // translation marker

  const [invoiceTitle, setinvoiceTitle] = useState("");
  const [leadBudget, setleadBudget] = useState("");
  const [leadName, setleadName] = useState("");
  const [leadEmail, setleadEmail] = useState("");
  const [leadPhone, setleadPhone] = useState("");
  const [invoiceDetails, setInvoiceDetails] = useState("");

  const [errorMessage, seterrorMessage] = useState("");
  const [isSending, setisSending] = useState(false);

  //** Hooks for validation */
  const [validation, setValidation] = useState({
    titleWarning: false,
    budgetWarning: false,
    nameWarning: false,
    emailWarning: false,
  });

  //** Validate function */
  const validationHandler = () => {
    let isValid = true;

    if (invoiceTitle === "") {
      setValidation((prevState) => ({ ...prevState, titleWarning: true }));
      isValid = false;
    }

    if (leadBudget === "") {
      setValidation((prevState) => ({ ...prevState, budgetWarning: true }));
      isValid = false;
    }

    if (leadName === "") {
      setValidation((prevState) => ({ ...prevState, nameWarning: true }));
      isValid = false;
    }

    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (leadEmail === "" || !leadEmail.match(mailformat)) {
      setValidation((prevState) => ({ ...prevState, emailWarning: true }));
      isValid = false;
    }

    return isValid;
  };

  const createInvoiceHandler = async () => {
    if (validationHandler()) {
      setisSending(true);
      try {
        let invoiceData = {
          recieverid: recieverId,
          title: invoiceTitle,
          leadbudget: leadBudget,
          leadname: leadName,
          leademail: leadEmail,
          leadphone: leadPhone,
          invoicedetails: invoiceDetails,
        };

        let requestURL = `${url.API_BASE_URL}${url.API_CREATE_RECOMENDATION}?token=${token}`;

        const response = await postData(requestURL, invoiceData);

        setisSending(false);
        console.log(response);

        if (response.status) {
          resetHandler();
          const bootstrapModal = document.querySelector("#recomanded_mdl");
          const modal = bootstrap.Modal.getInstance(bootstrapModal);
          modal.hide();

          const successModal = new bootstrap.Modal(
            document.getElementById("recomnd_success_mdl")
          );
          successModal.show();
        } else {
          seterrorMessage(response.message);
        }
      } catch (error) {
        seterrorMessage(error.message);
      }
    } else {
      seterrorMessage("Please fill all required fields");
    }
  };

  const resetHandler = () => {
    setinvoiceTitle("");
    setleadBudget("");
    setleadName("");
    setleadEmail("");
    setleadPhone("");
    setInvoiceDetails("");

    seterrorMessage("");
    setValidation({
      nameWarning: false,
      emailWarning: false,
    });
  };

  return (
    <div className="process_modal builder_modal">
      <div
        className="modal fade show"
        id="recomanded_mdl"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog medium_modal" role="document">
          <div className="modal-content">
            {/* ---- modal header section start ---- */}
            <div className="modal-header">
              {/* ---- modal title start ---- */}
              <div className="signin_hdng text-left">
                <h5>{t("Recomanded an opportunity to")}</h5>

                <h3>{userName}</h3>
              </div>
              {/* ---- modal title end ---- */}

              {/* ---- modal close button start ---- */}
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={resetHandler}
              >
                <i className="material-icons-outlined">close</i>
              </button>
              {/* ---- modal close button end ---- */}
            </div>
            {/* ---- modal header section end ---- */}

            {/* ---- modal body section start ---- */}
            <div className="modal-body">
              <div className="login_body">
                {/* ----- register form section start ----- */}

                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="form_innr">
                    {" "}
                    <div className="form-group">
                      <label>
                        <span className="text-danger">*</span> Title
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="* Enter title"
                        value={invoiceTitle}
                        onChange={(e) => {
                          setinvoiceTitle(e.target.value);
                          setValidation((prevState) => ({
                            ...prevState,
                            titleWarning: false,
                          }));
                          seterrorMessage("");
                        }}
                      />

                      {validation.titleWarning && (
                        <p className="error mt-1">
                          <span className="material-icons-outlined me-1">
                            info
                          </span>
                          {t("Please enter title")}
                        </p>
                      )}
                    </div>
                    <div className="form-group d-flex align-items-center gap-3">
                      <div className="w-100">
                        <div className="date_check_row d-flex align-items-center justify-content-between">
                          <label>
                            <span className="text-danger">*</span> Budget
                          </label>
                        </div>
                        <div className="experince_date_row">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Enter budget"
                            value={leadBudget}
                            onChange={(e) => {
                              setleadBudget(e.target.value);
                              setValidation((prevState) => ({
                                ...prevState,
                                budgetWarning: false,
                              }));
                              seterrorMessage("");
                            }}
                          />
                          {validation.budgetWarning && (
                            <p className="error mt-1">
                              <span className="material-icons-outlined me-1">
                                info
                              </span>
                              {t("Please enter budget")}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="w-100">
                        <div className="date_check_row d-flex align-items-center justify-content-between">
                          <label>
                            <span className="text-danger">*</span> Customer Name
                          </label>
                        </div>
                        <div className="experince_date_row">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="* Enter customer name"
                            value={leadName}
                            onChange={(e) => {
                              setleadName(e.target.value);
                              setValidation((prevState) => ({
                                ...prevState,
                                nameWarning: false,
                              }));
                              seterrorMessage("");
                            }}
                          />

                          {validation.nameWarning && (
                            <p className="error mt-1">
                              <span className="material-icons-outlined me-1">
                                info
                              </span>
                              {t("Please enter customer name")}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="form-group d-flex align-items-center gap-3">
                      <div className="w-100">
                        <div className="date_check_row d-flex align-items-center justify-content-between">
                          <label>
                            <span className="text-danger">*</span> Customer
                            Email
                          </label>
                        </div>
                        <div className="experince_date_row">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="* Enter customer email"
                            value={leadEmail}
                            onChange={(e) => {
                              setleadEmail(e.target.value);
                              setValidation((prevState) => ({
                                ...prevState,
                                emailWarning: false,
                              }));
                              seterrorMessage("");
                            }}
                          />

                          {validation.emailWarning && (
                            <p className="error mt-1">
                              <span className="material-icons-outlined me-1">
                                info
                              </span>
                              {t("Please enter customer email")}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="w-100">
                        <div className="date_check_row d-flex align-items-center justify-content-between">
                          <label>Customer Phone</label>
                        </div>
                        <div className="experince_date_row d-flex gap-2">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Enter Customer phone"
                            value={leadPhone}
                            onChange={(e) => {
                              setleadPhone(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Details</label>
                      <textarea
                        name=""
                        id=""
                        rows="5"
                        className="form-control"
                        placeholder="Enter details"
                        value={invoiceDetails}
                        onChange={(e) => {
                          setInvoiceDetails(e.target.value);
                        }}
                      ></textarea>
                    </div>
                  </div>

                  <div className="process_btns_outer d-flex align-items-center justify-content-between mt-3">
                    <button
                      onClick={resetHandler}
                      data-bs-dismiss="modal"
                      className="btn gray"
                    >
                      Cancel
                    </button>
                    <button
                      className="btn"
                      onClick={createInvoiceHandler}
                      disabled={isSending ? true : false}
                      style={{
                        cursor: isSending ? "not-allowed" : "pointer",
                      }}
                    >
                      Save
                      {isSending && (
                        <div
                          className="mx-2 spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      )}
                    </button>
                  </div>
                </form>
                {/* ----- register form section end ----- */}
              </div>
            </div>
            {/* ---- modal body section end ---- */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecomandationModal;
