/* eslint-disable */
import JobDetailsBody from "components/JobsComponents/JobDetailsComponents/JobDetailsBody/JobDetailsBody";
import AuthHeader from "components/common/AuthHeader";

const JobDetails = () => {
  return (
    <>
      <header className="aftre_login_header">
        <AuthHeader />
      </header>

      {/* ------- body section ------------ */}
      <JobDetailsBody />
    </>
  );
};

export default JobDetails;
