/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/common/Header";
import ConnectBanner from "../../components/ConnectComponents/ConnectBanner/ConnectBanner";
import ConnectTalents from "../../components/ConnectComponents/ConnectTalents/ConnectTalents";
import ConnectJobs from "../../components/ConnectComponents/ConnectJobs/ConnectJobs";
import Footer from "../../components/common/Footer";
import ConnectHowWeDo from "../../components/ConnectComponents/ConnectHowWeDo/ConnectHowWeDo";
import Brands from "../../components/common/Brands";
import Conversation from "../../components/common/Conversation";
import CustomerFeedback from "../../components/common/CustomerFeedback";
import ContactNow from "../../components/common/ContactNow";

const Connect = () => {
  // Create a ref to the ContactNow component's DOM element
  const contactNowRef = useRef(null);

  const [sourceName, setSourceName] = useState("");

  // Function to auto-scroll to the ContactNow component
  const autoScollContactNow = () => {
    // Scroll to the ContactNow component's DOM element with smooth behavior
    contactNowRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    document.title = "Connect"; // Update the title when Register component mounts
  }, []);
  return (
    <>
      {/* ---- Header section ----- */}
      <Header />

      {/* ---- banner section ---- */}
      <ConnectBanner />

      {/* ---- brands section ---- */}

      <Brands />

      {/* ---- talents section ---- */}
      <ConnectTalents
        autoScollContactNow={autoScollContactNow}
        setSourceName={setSourceName}
      />

      {/* ---- job section ---- */}
      <ConnectJobs
        autoScollContactNow={autoScollContactNow}
        setSourceName={setSourceName}
      />

      {/* ---- how we do section ---- */}
      <ConnectHowWeDo />

      {/* ---- let's talk section ---- */}
      <Conversation
        source="Connect"
        autoScollContactNow={autoScollContactNow}
        setSourceName={setSourceName}
      />

      {/* ---- customer feedback section ---- */}
      <CustomerFeedback />

      {/* ---- contact now section ---- */}
      <div ref={contactNowRef} />
      <ContactNow sourceName={sourceName} setSourceName={setSourceName} />

      {/* ---- footer section ---- */}
      <Footer />
    </>
  );
};

export default Connect;
