/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

/*import url and gateway methods */
import { getData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import ActivityDetailsLeftPanel from "../ActivityDetailsLeftPanel/ActivityDetailsLeftPanel";
import ActivityDetailsMiddlePanel from "../ActivityDetailsMiddlePanel/ActivityDetailsMiddlePanel";
import ActivityDetailsRightPanel from "../ActivityDetailsRightPanel/ActivityDetailsRightPanel";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";
import AddCommentModal from "../Modals/AddCommentModal";
import TagsModal from "components/common/Modals/TagsModal";
import TagsAddedSuccessModal from "components/common/Modals/TagsAddedSuccessModal";

const ActivityDetailsBody = () => {
  const params = useParams();

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const { t } = useTranslation(); // translation marker

  const [title, settitle] = useState("");
  const [description, setdescription] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const [isPrivate, setIsPrivate] = useState(false);

  const [moderatorId, setmoderatorId] = useState(null);
  const [moderatorName, setmoderatorName] = useState("");
  const [moderatorImage, setmoderatorImage] = useState("");
  const [forumTimeAgo, setforumTimeAgo] = useState("");
  const [tags, settags] = useState([]);
  const [likeCount, setLikeCount] = useState(0);
  const [commentCount, setCommentCount] = useState(0);
  const [commentList, setCommentList] = useState([]);

  const [topCommentId, settopCommentId] = useState(null);
  const [winnerId, setwinnerId] = useState(null);

  const [selectedCommentId, setselectedCommentId] = useState(null);

  const [similarList, setsimilarList] = useState([]);

  const getPostDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_FORUM_DETAILS +
        `/${params.id}?token=${token}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        settitle(response.data.title);
        setdescription(response.data.description);
        settags(response.data.taglistarr);

        setUploadedFiles(response.data?.uploads);

        setIsPrivate(response.data.isprivate);

        setmoderatorId(response.data.moderator._id);
        setmoderatorImage(response.data.moderatorimage);
        setmoderatorName(response.data.moderatorname);
        setforumTimeAgo(response.data.timestring);
        setLikeCount(response.data.likes ? response.data.likes.length : 0);
        setCommentCount(response.data.commentlist.length);
        setCommentList(response.data.commentlist);
        settopCommentId(response.data?.topcomment);
        setwinnerId(response.data?.winner);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const getAllForums = async () => {
    try {
      let requestURL =
        url.API_BASE_URL + url.API_GET_ALL_FORUM + `?token=${token}`;

      const response = await getData(requestURL);

      // console.log(response);
      if (response.status) {
        const filteredResults = response.data.filter(
          (item) => item._id.toString() !== params.id.toString()
        );

        setsimilarList(filteredResults);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //like/dislike forum
  const toggleLikeForum = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_TOGGLE_LIKE_FORUM_POST +
        `/${params.id}?token=${token}`;

      const response = await putData(requestURL);

      console.log(response);

      if (response.status) {
        getPostDetails();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //edit comment
  const editCommentHandler = (id) => {
    setselectedCommentId(id);
  };

  //delete comment
  const deleteCommentHandler = async (id) => {
    try {
      let forumData = {
        status: "0",
      };

      let requestURL =
        url.API_BASE_URL + url.API_UPDATE_COMMENT + `/${id}?token=${token}`;

      const response = await putData(requestURL, forumData);

      // console.log(response);

      if (response.status) {
        getPostDetails();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //like a comment
  const toggleLikeComment = async (commentId) => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_TOGGLE_LIKE_FORUM_COMMENT +
        `/${commentId}?token=${token}`;

      const response = await putData(requestURL);

      console.log(response);

      if (response.status) {
        //show otpconfirm modal
        let myModal = new bootstrap.Modal(
          document.getElementById("like_done_mdl")
        );
        myModal.show();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //make winner
  const winnerHandler = async (commentId, commnetOwnerId) => {
    try {
      let forumData = {
        topcomment: commentId,
        winner: commnetOwnerId,
      };

      let requestURL =
        url.API_BASE_URL +
        url.API_UPDATE_FORUM +
        `/${params.id}?token=${token}`;

      const response = await putData(requestURL, forumData);

      // console.log(response);

      if (response.status) {
        // let myModal = new bootstrap.Modal(
        //   document.getElementById("success_comment_mdl")
        // );
        // myModal.show();
        getPostDetails();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (params.id) {
      getPostDetails();
      getAllForums();
    }
  }, [params.id]);

  return (
    <section className="feed_pnltotal">
      <div className="container-fluid">
        <div className="back_btn">
          <Link to="/activities">
            <i className="material-icons-outlined">arrow_back</i>
            <span>{t("Back")}</span>
          </Link>
        </div>

        <div className="pnl_outer ">
          {/* --------- left panel ------------- */}
          <ActivityDetailsLeftPanel />

          {/* --------- middle panel ------------- */}
          <ActivityDetailsMiddlePanel
            title={title}
            description={description}
            tags={tags}
            uploadedFiles={uploadedFiles}
            moderatorId={moderatorId}
            moderatorName={moderatorName}
            moderatorImage={moderatorImage}
            forumTimeAgo={forumTimeAgo}
            likeCount={likeCount}
            commentCount={commentCount}
            commentList={commentList}
            toggleLikeForum={toggleLikeForum}
            onEditComment={editCommentHandler}
            onDeleteComment={deleteCommentHandler}
            winnerHandler={winnerHandler}
            topCommentId={topCommentId}
            winnerId={winnerId}
            toggleLikeComment={toggleLikeComment}
            isPrivate={isPrivate}
          />

          {/* --------- right panel ------------- */}
          <ActivityDetailsRightPanel similarList={similarList} />

          <AddCommentModal
            forumId={params.id}
            selectedCommentId={selectedCommentId}
            onModalClose={() => {
              getPostDetails();
              setselectedCommentId(null);
            }}
          />

          <TagsModal selectedId={params.id} moduleName="activity" />

          <TagsAddedSuccessModal afterTagModalClose={getPostDetails} />
        </div>
      </div>
    </section>
  );
};

export default ActivityDetailsBody;
