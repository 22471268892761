/* eslint-disable */
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

/*import url and gateway methods */
import { getData, postData, putData, uploadMultipleFile } from "utils/Gateway";
import * as url from "helper/UrlHelper";
import QuestionSuccessModal from "../Modals/QuestionSuccessModal";

const QuestionTab = ({ selectedLessonId = null }) => {
  const params = useParams();
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const [faqList, setFaqList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [noteTitle, setNoteTitle] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  //function for get notes
  const getAllFaqs = async () => {
    try {
      setIsLoading(true);

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_COURSE_LESSON_FAQS +
        `?token=${token}&filtercourse=${params.id}&filterlesson=${selectedLessonId}`;

      const response = await getData(requestUrl);

      console.log(response);

      setIsLoading(false);

      if (response.status) {
        const responsedData = response.data
          .filter((item) => item.details !== "")
          .sort((a, b) => a.rank - b.rank);

        setFaqList(responsedData);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for save faq
  const saveFaqHandler = async () => {
    try {
      setIsSaving(true);

      let noteData = {
        notetype: "2",
        title: noteTitle,
        details: "",
        rank: 0,
        isprivate: false,
        course: params.id,
        lessonid: selectedLessonId,
      };

      let requestUrl =
        url.API_BASE_URL + url.API_ADD_NEW_NOTE + `?token=${token}`;

      const response = await postData(requestUrl, noteData);

      setIsSaving(false);

      console.log(response);

      if (response.status) {
        //show otpconfirm modal
        let myModal = new bootstrap.Modal(
          document.getElementById("question_success_modal")
        );
        myModal.show();

        resetHandler();
        getAllFaqs();
      }
    } catch (error) {}
  };

  const resetHandler = () => {
    setNoteTitle("");
  };

  useEffect(() => {
    if (selectedLessonId && params.id) {
      getAllFaqs();
    }
  }, [params.id, selectedLessonId]);

  return (
    <div className="people_details_bx white_shadow_bx">
      <div className="accordian_area">
        <div className="accordion accordion-flush" id="faq-accordian2">
          {faqList.map((faq, index) => {
            return (
              <div className="accordion-item" key={index}>
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#flush-collapse${index}`}
                    aria-expanded="false"
                  >
                    <span>{faq.title}</span>
                  </button>
                </h2>
                <div
                  id={`flush-collapse${index}`}
                  className="accordion-collapse collapse"
                  data-bs-parent="#faq-accordian2"
                >
                  <div className="accordion-body">
                    <div className="accor_para">
                      <p>{faq.details}</p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="question_submit_area">
        <div className="input_fill jobinput_fill description_fill">
          <label>Your Questions</label>
          <textarea
            className="form-control"
            placeholder="Enter message"
            value={noteTitle}
            onChange={(e) => setNoteTitle(e.target.value)}
          ></textarea>
        </div>
        <div className="question_submit">
          <button
            type="button"
            // data-bs-toggle="modal"
            // data-bs-target="#question_success_modal"
            className="btn"
            onClick={saveFaqHandler}
            disabled={isSaving}
            style={{ cursor: isSaving ? "not-allowed" : "pointer" }}
          >
            Submit Question
            {isSaving && (
              <div
                className="mx-2 spinner-border spinner-border-sm"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            )}
          </button>
        </div>
      </div>
      <QuestionSuccessModal />
    </div>
  );
};

export default QuestionTab;
