import React from 'react';
import AccountSettingsLeftPanel from '../AccountSettingsLeftPanel/AccountSettingsLeftPanel';
import AccountSettingsRightPanel from '../AccountSettingsRightPanel/AccountSettingsRightPanel';

const AccountSettingsBody = () => {
  return (
    <section className="feed_pnltotal">
      <div className="container-fluid">
        <div className="pnl_outer profile_pnlOuter">
          <AccountSettingsLeftPanel moduleName="accountSettings" />
          <AccountSettingsRightPanel />
        </div>
      </div>
    </section>
  );
};

export default AccountSettingsBody;
