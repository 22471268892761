/* eslint-disable */
import { assetImages } from "constants";
import React from "react";
import { Link, useParams } from "react-router-dom";
import * as url from "helper/UrlHelper";

const JobHeaderSection = ({
  isAgency,
  companyLogoPath,
  companyName,
  jobTypeValue,
  title,
  summary,
  minSalary,
  maxSalary,
  isApplied,
  applyCount,
}) => {
  const params = useParams();

  // console.log('applyCount', isApplied);

  return params && params.id ? (
    <div className="builder_item white_shadow_bx mb-3">
      <div className="top_company_row">
        <div className="company_logotxt">
          <span>
            <img
              className="rounded-circle"
              src={
                companyLogoPath === ""
                  ? assetImages.defaultUser
                  : url.SERVER_URL + companyLogoPath
              }
              alt=""
              height={35}
            />
          </span>
          <span className="ms-2">{companyName}</span>
        </div>
        <div className="rating_tags d-flex align-items-center">
          <div className="jb_rating">
            <i className="material-icons-outlined">schedule</i>
            <span>{jobTypeValue}</span>
          </div>
          {/* <div className="tags_icon">
            <Link to="#">
              <span className="material-icons-outlined">bookmark_border</span>
            </Link>
          </div> */}
        </div>
      </div>
      <div className="event_hdng d-flex align-items-center justify-content-between">
        <h3 className="mb-0">{title}</h3>
        <h4 className="mb-0">
          {" "}
          €{minSalary} - €{maxSalary}
        </h4>
      </div>

      <div className="event_hdng d-flex align-items-center justify-content-between">
        <p>{summary}</p>
      </div>

      {isApplied ? (
        <>
          {isAgency ? null : (
            <p className="text-danger">
              ** You have already submitted CVs for this job.
            </p>
          )}
          <p className=""> Application Submitted Count : {applyCount}</p>
        </>
      ) : (
        ""
      )}
    </div>
  ) : (
    ""
  );
};

export default JobHeaderSection;
