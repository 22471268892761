import React from "react";

const PostSuccessModal = ({ afterModalClose }) => {
  return (
    <div className="post_modal success_greetings_mdl">
      <div className="modal fade" id="gretings_mdl" data-bs-backdrop="static">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="gretings_icon">
                <span className="material-icons-outlined"> task_alt </span>
              </div>
              <div className="gretings_hdng">
                <h3>Post saved Successfully</h3>
              </div>
              <div className="post_btn post_bnts_outer">
                {/* <button
                  className="btn gray_btn"
                  data-bs-toggle="modal"
                  data-bs-dismiss="modal"
                  data-bs-target="#create_postmdl"
                >
                  Add Another
                </button> */}
                <button
                  className="btn"
                  data-bs-dismiss="modal"
                  onClick={afterModalClose}
                >
                  Close Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostSuccessModal;
