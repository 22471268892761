import React from "react";
import AuthMenuPanel from "components/common/AuthMenuPanel";
import AuthUserBox from "components/common/AuthUserBox";

const AppUserLeftPanel = () => {
  return (
    <div className="pnl_leftpnl hidden_mobile">
      <AuthUserBox />
      <AuthMenuPanel moduleName="appuser" />
    </div>
  );
};

export default AppUserLeftPanel;
