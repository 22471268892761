import AuthMenuPanel from "components/common/AuthMenuPanel";
import AuthUserBox from "components/common/AuthUserBox";
import React from "react";

const JobLandingLeftPanel = () => {
  return (
    <div className="pnl_leftpnl hidden_mobile">
      <AuthUserBox />
      <AuthMenuPanel moduleName="jobs" />
    </div>
  );
};

export default JobLandingLeftPanel;
