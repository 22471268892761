import React from "react";
import { Link } from "react-router-dom";

const LessonLeftPanel = ({
  courseDetails = {},
  selectedLessonId,
  setSelectedLessonId,
}) => {
  const lessonList = courseDetails.courselessons || [];

  return (
    <div className="lessons_leftpnl">
      <div className="lesson_rating_outer">
        {/* <Link to="#" className="lesson_rating_row">
          <div className="lesson_statusOuter">
            <div className="lesson_time">
              <ul className="list_stye_none">
                <li>20min</li>
                <li>Ankit Jain</li>
              </ul>
            </div>
            <div className="lesson_status success_status">
              <i className="material-icons-outlined">check_circle</i>
              <span>Completed</span>
            </div>
          </div>
          <div className="lesson_hdng">
            <h3>Foundations of User Experience (UX) Design</h3>
          </div>
          <div className="lesson_time rating_status">
            <ul className="list_stye_none">
              <li>
                <i className="material-icons-outlined ornge_icon">star</i>
                <span>4.8</span>
              </li>
              <li> 3,940 reviews</li>
            </ul>
          </div>
        </Link>
        <Link to="#" className="lesson_rating_row active_lessons">
          <div className="lesson_statusOuter">
            <div className="lesson_time">
              <ul className="list_stye_none">
                <li>20min</li>
                <li>Ankit Jain</li>
              </ul>
            </div>
            <div className="lesson_status pending_status">
              <i className="material-icons-outlined">info</i>
              <span>Pending</span>
            </div>
          </div>
          <div className="lesson_hdng">
            <h3>Start the UX Design Process: Empathize, Define, and Ideate</h3>
          </div>
          <div className="lesson_time rating_status">
            <ul className="list_stye_none">
              <li>
                <i className="material-icons-outlined ornge_icon">star</i>
                <span>4.8</span>
              </li>
              <li>reviews</li>
            </ul>
          </div>
        </Link> */}
        {lessonList.length > 0
          ? lessonList.map((lessonData, index) => {
              return (
                <Link
                  to="#"
                  className={`lesson_rating_row ${
                    selectedLessonId &&
                    selectedLessonId.toString() === lessonData._id.toString()
                      ? "active_lessons"
                      : ""
                  }`}
                  key={index}
                  onClick={() => {
                    document.body.classList.add("course_open");
                    setSelectedLessonId(lessonData._id);
                  }}
                >
                  <div className="lesson_statusOuter">
                    <div className="lesson_time">
                      <ul className="list_stye_none">
                        <li>{lessonData?.duration} min</li>
                        <li>{lessonData?.membernames}</li>
                      </ul>
                    </div>
                    {courseDetails?.usercourserole !== "" ? null : (
                      <div
                        className={`lesson_status ${
                          lessonData?.statusstring === "0"
                            ? "pending_status"
                            : "success_status"
                        } `}
                      >
                        <i className="material-icons-outlined">
                          {lessonData?.statusstring === "0"
                            ? "info"
                            : "check_circle"}
                        </i>{" "}
                        <span>{lessonData?.completestatus}</span>
                      </div>
                    )}
                  </div>
                  <div className="lesson_hdng">
                    <h3>{lessonData?.title}</h3>
                  </div>
                  <div className="lesson_time rating_status">
                    <ul className="list_stye_none">
                      {/* <li>
                        <i className="material-icons-outlined ornge_icon">
                          star
                        </i>
                        <span>4.8</span>
                      </li> */}
                      <li>{lessonData?.chaptername}</li>
                    </ul>
                  </div>
                </Link>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default LessonLeftPanel;
