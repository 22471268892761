/* eslint-disable */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { getData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import ConversationRightGroupChatPanel from "./ConversationRightGroupChatPanel";
import ConversationGroupDetailsPopup from "components/ConversationComponents/Popups/ConversationGroupDetailsPopup";
import { assetImages } from "constants";
import AddToGroupModal from "components/ConversationComponents/Modals/AddToGroupModal";
import ChatHistoryComponent from "components/ConversationComponents/Modals/ChatHistoryModal";

const ConversationRightGroupPanel = ({
  conversationName = "",
  selectedChatLogo = "",
  selectedChatRoomId,
  selectedChatAddress,
  chatcomponentName,
  setchatcomponentName,
  chatComponentTitle,
  setchatComponentTitle,
  groupModName,
  groupModImage,
  groupMembers = [],
  groupFiles = [],
  groupModId = null,
  groupMemberString = "",
  socket,
  allContacts = [],
}) => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [isLoading, setisLoading] = useState(false);
  const [groupLogoImage, setgroupLogoImage] = useState("");
  const [groupMemberList, setgroupMemberList] = useState([]);
  const [groupFileList, setgroupFileList] = useState([]);
  const [groupMemberNames, setgroupMemberNames] = useState("");

  //search message requirements
  const [displaySearchPanel, setdisplaySearchPanel] = useState(false);
  const [searchMessage, setsearchMessage] = useState("");
  const [refreshMessages, setrefreshMessages] = useState(false);

  const [getSearchMessage, setgetSearchMessage] = useState(false);
  const [groupCreatedTime, setgroupCreatedTime] = useState("");

  const [showHistory, setShowHistory] = useState(false);

  //function for reset all chats
  const refreshChatHandler = () => {
    setrefreshMessages(true);
    setdisplaySearchPanel(false);
    setsearchMessage("");
  };

  //function for search messages
  const searchMessageHandler = () => {
    setgetSearchMessage(true);
    setdisplaySearchPanel(false);
  };

  const searchMessageByEnter = (e) => {
    if (e.key === "Enter") {
      searchMessageHandler();
    }
  };

  const getGroupDetails = async () => {
    try {
      setisLoading(true);
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_GROUP_DETAILS +
        `/${selectedChatRoomId}?token=${token}`;

      const response = await getData(requestURL);

      setisLoading(false);

      if (response.status) {
        setgroupLogoImage(response.data.grouplogoimage);
        setgroupMemberList(response.data.groupmemberlist);
        setgroupFileList(response.data.uploads);
        setgroupMemberNames(response.data.groupmemberstring);
        setgroupCreatedTime(response.data.createdtimestring);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (selectedChatRoomId) {
      getGroupDetails();
      refreshChatHandler();
    }
  }, [selectedChatRoomId]);

  return (
    <>
      <div className="chat_topPnl">
        <div className="chatusr_info">
          <div className="list_back">
            <Link
              to="#"
              onClick={() => {
                document.body.classList.toggle("chatmsg_open");
              }}
            >
              <i className="material-icons-round">arrow_back</i>
            </Link>
          </div>
          {isLoading ? (
            <div className="grp_userHead placeholder-glow">
              <div className="user_placeHoledr">
                <div className="default_usr">
                  <img src={assetImages.defaultUser} alt="" />
                </div>
                <div className="default_usr_name">
                  <span className="placeholder placeholder-sm col-5 rounded-3"></span>
                  <span className="placeholder placeholder-sm col-2 rounded-3"></span>
                </div>
              </div>
            </div>
          ) : (
            <Link
              to="#"
              data-bs-toggle="offcanvas"
              data-bs-target="#profile_pnl"
              className="chat_usrOuter profile_linkBtn"
            >
              <div className="chatting_user">
                {selectedChatLogo === "" ? (
                  <>
                    <figure className="group_usr">
                      <img
                        src={
                          groupModImage === ""
                            ? assetImages.defaultUser
                            : url.SERVER_URL + groupModImage
                        }
                        alt=""
                      />
                    </figure>
                    {groupMemberList.slice(0, 2).map((member, index2) => {
                      return (
                        <figure className="group_usr" key={index2}>
                          <img
                            src={
                              member.groupmemberimage === ""
                                ? assetImages.defaultUser
                                : url.SERVER_URL + member.groupmemberimage
                            }
                            alt=""
                          />
                        </figure>
                      );
                    })}
                    {groupMemberList.length > 2 ? (
                      <span className="group_usr plus_usr">
                        {groupMembers.length - 2} <em>+</em>
                      </span>
                    ) : null}
                  </>
                ) : (
                  <figure className="">
                    <img
                      className="rounded-circle"
                      height={40}
                      width={40}
                      src={url.SERVER_URL + selectedChatLogo}
                      alt=""
                    />{" "}
                  </figure>
                )}
              </div>

              <div className="chat_usrtext">
                <h3>{conversationName}</h3>
                {/* <h5>{groupMembers.length + 1} Participants</h5> */}
                <h5>{groupMemberNames}</h5>
              </div>
            </Link>
          )}
          <div className="info_icons me-2">
            <ul>
              <li>
                <Link to="#" onClick={refreshChatHandler}>
                  <i className="material-icons-round">refresh</i>
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="srch_Btn_icon"
                  onClick={() => {
                    setdisplaySearchPanel(!displaySearchPanel);
                    setsearchMessage("");
                  }}
                >
                  <i className="material-icons-round">search</i>
                </Link>
              </li>
              {groupModId &&
              groupModId.toString() === userInfo._id.toString() ? (
                <li>
                  <Link
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#add_to_group_people_mdl"
                  >
                    <i className="material-icons-round">person_add_alt</i>
                  </Link>
                </li>
              ) : null}
              <li>
                <Link
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#chathistorymodal"
                  onClick={() => setShowHistory(true)}
                >
                  <i className="material-icons-round">history</i>
                </Link>
              </li>

              {/* <li>
                <Link
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#addtag_mdl"
                >
                  <i className="material-icons-round">loupe</i>
                </Link>
              </li> */}
            </ul>
          </div>
        </div>

        <div
          className="serch_pnl"
          style={{ display: displaySearchPanel ? "block" : "none" }}
        >
          <div className="srch_innrFill">
            <input
              type="text"
              className="form-control"
              placeholder="Search for conversation"
              value={searchMessage}
              onChange={(e) => {
                setsearchMessage(e.target.value);
              }}
              onKeyDown={searchMessageByEnter}
            />
            <button
              className="srch_submitBtn"
              type="button"
              onClick={searchMessageHandler}
            >
              <i className="material-icons-round"> send</i>
            </button>
          </div>
        </div>
      </div>

      <ConversationRightGroupChatPanel
        groupCreatedTime={groupCreatedTime}
        conversationName={conversationName}
        selectedChatLogo={groupLogoImage}
        selectedChatRoomId={selectedChatRoomId}
        selectedChatAddress={selectedChatAddress}
        chatcomponentName={chatcomponentName}
        setchatcomponentName={setchatcomponentName}
        chatComponentTitle={chatComponentTitle}
        setchatComponentTitle={setchatComponentTitle}
        groupModId={groupModId}
        groupModName={groupModName}
        groupModImage={groupModImage}
        groupMembers={groupMemberList}
        groupFiles={groupFileList}
        socket={socket}
        searchMessage={searchMessage}
        setsearchMessage={setsearchMessage}
        getSearchMessage={getSearchMessage}
        setgetSearchMessage={setgetSearchMessage}
        refreshMessages={refreshMessages}
        setrefreshMessages={setrefreshMessages}
      />

      <ConversationGroupDetailsPopup
        selectedChatRoomId={selectedChatRoomId}
        conversationName={conversationName}
        selectedChatLogo={groupLogoImage}
        groupModName={groupModName}
        groupModImage={groupModImage}
        groupMembers={groupMemberList}
        groupFiles={groupFileList}
        groupModId={groupModId}
      />

      <AddToGroupModal
        myContacts={allContacts}
        groupId={selectedChatRoomId}
        afterModalClose={getGroupDetails}
      />

      

        <ChatHistoryComponent
          showHistory={showHistory}
          isGroupChat={true}
          setShowHistory={setShowHistory}
          selectedChatRoomId={selectedChatRoomId}
          searchMessage={searchMessage}

          />          
      

    </>
  );
};

export default ConversationRightGroupPanel;
