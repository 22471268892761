import React from "react";

const EventListPlaceHolder = () => {
  return (
    <div className="groups_item">
      <div className="top_company_row">
        <div className="event_hdng" style={{ width: "50%" }}>
          <h3>
            <span className="placeholder" style={{ width: "70%" }}></span>
          </h3>
          <h5>
            <span className="placeholder" style={{ width: "50%" }}></span>
          </h5>
        </div>
        <div className="event_tags">
          <span className="placeholder" style={{ width: "70%" }}></span>
          <span className="placeholder" style={{ width: "70%" }}></span>
        </div>
      </div>

      <div className="event_para">
        <p>
          <span className="placeholder" style={{ width: "70%" }}></span>
        </p>
      </div>
      <div className="event_user">
        <ul className="list_stye_none">
          <li>
            <span
              className="placeholder rounded-circle"
              style={{ width: "40px", height: "40px" }}
            ></span>
          </li>
          <li>
            <span
              className="placeholder rounded-circle"
              style={{ width: "40px", height: "40px" }}
            ></span>
          </li>
          <li>
            <span
              className="placeholder rounded-circle"
              style={{ width: "40px", height: "40px" }}
            ></span>
          </li>
          <li>
            <span
              className="placeholder rounded-circle"
              style={{ width: "40px", height: "40px" }}
            ></span>
          </li>
          <li>
            <span
              className="placeholder rounded-circle"
              style={{ width: "40px", height: "40px" }}
            ></span>
          </li>
        </ul>
      </div>
      <div className="details_btn">
        <span
          className="placeholder"
          style={{
            width: "110px",
            height: "40px",
            borderRadius: "30px",
          }}
        ></span>
      </div>
    </div>
  );
};

export default EventListPlaceHolder;
