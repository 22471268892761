/* eslint-disable */
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

/*import url and gateway methods */
import { getData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import ContentTab from "../Tabs/ContentTab";
import TaskTab from "../Tabs/TaskTab";
import QuestionTab from "../Tabs/QuestionTab";

const LessonRighPanel = ({
  selectedLessonId = null,
  nextLessonHandler,
  userCourseRole = "",
}) => {
  const token = localStorage.getItem("token");
  const params = useParams();

  const [lessonDetails, setLessonDetails] = useState({});

  //get lesson details
  const getLessonDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_COURSE_LESSON_DETAILS +
        `/${selectedLessonId}?token=${token}&filtercourse=${params.id}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        setLessonDetails(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (selectedLessonId) {
      getLessonDetails();
    }
  }, [selectedLessonId]);

  return (
    <div className="lessons_rightpnl">
      <div className="course_detailspnl_innr">
        <div className="progress_courseOuter">
          <div className="back_progressOuter">
            <div className="page_back">
              <Link
                to="#"
                className="back_course"
                onClick={() => {
                  document.body.classList.remove("course_open");
                }}
              >
                <span className="material-icons-outlined"> arrow_back</span>
                Back
              </Link>
            </div>
            <div className="progress_text">
              <h5>
                <span>32%</span> Completed
              </h5>
            </div>
          </div>
          <div className="invite_progressouter">
            <span
              className="invite_progress_bar blue_bar"
              style={{ width: "32%" }}
            ></span>
          </div>
        </div>
        <div className="white_shadow_bx">
          <div className="lessons_tablink tab_links people_tablinks">
            <ul className="nav nav-tabs list_stye_none">
              <li className="nav-item">
                <Link
                  className="nav-link active show"
                  data-bs-toggle="tab"
                  to="#content"
                >
                  Content
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" data-bs-toggle="tab" to="#task">
                  Task
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" data-bs-toggle="tab" to="#questions">
                  Question
                </Link>
              </li>
            </ul>
          </div>
          <div className="tab-content">
            <div className="tab-pane fade active show" id="content">
              <ContentTab
                lessonDetails={lessonDetails}
                nextLessonHandler={nextLessonHandler}
                userCourseRole={userCourseRole}
              />
            </div>
            <div className="tab-pane fade" id="task">
              <TaskTab
                userCourseRole={userCourseRole}
                taskList={lessonDetails?.tasklist}
                selectedLessonId={selectedLessonId}
              />
            </div>
            <div className="tab-pane fade" id="questions">
              <QuestionTab selectedLessonId={selectedLessonId} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LessonRighPanel;
