import React from 'react';
import MyProfileLeftPanel from '../MyProfileLeftPanel/MyProfileLeftPanel';
import MyProfileRightPanel from '../MyProfileRightPanel/MyProfileRightPanel';

const MyProfileBody = () => {
  return (
    <section className="feed_pnltotal">
      <div className="container-fluid">
        <div className="pnl_outer profile_pnlOuter">
          <MyProfileLeftPanel moduleName="staffInfo" />
          <MyProfileRightPanel />
        </div>
      </div>
    </section>
  );
};

export default MyProfileBody;
