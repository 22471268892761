/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/common/Header";
import DevelopBanner from "../../components/DevelopComponents/DevelopBanner/DevelopBanner";
import Footer from "../../components/common/Footer";
import DeveloperConnectLobees from "../../components/DevelopComponents/DeveloperConnectLobees/DeveloperConnectLobees";
import DeveloperLobeesTeam from "../../components/DevelopComponents/DeveloperLobeesTeam/DeveloperLobeesTeam";
import DeveloperHowWeDo from "../../components/DevelopComponents/DeveloperHowWeDo/DeveloperHowWeDo";
import Brands from "../../components/common/Brands";
import Conversation from "../../components/common/Conversation";
import CustomerFeedback from "../../components/common/CustomerFeedback";
import ContactNow from "../../components/common/ContactNow";

const Develop = () => {
  // Create a ref to the ContactNow component's DOM element
  const contactNowRef = useRef(null);

  const [sourceName, setSourceName] = useState("");

  // Function to auto-scroll to the ContactNow component
  const autoScollContactNow = () => {
    // Scroll to the ContactNow component's DOM element with smooth behavior
    contactNowRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    document.title = "Developer"; // Update the title when Register component mounts
  }, []);
  return (
    <>
      {/* ---- Header section ----- */}
      <Header />

      {/* ---- banner section start ---- */}
      <DevelopBanner />

      {/* ---- brands section ---- */}
      <Brands />

      {/* ---- connect lobees section ---- */}
      <DeveloperConnectLobees
        autoScollContactNow={autoScollContactNow}
        setSourceName={setSourceName}
      />

      {/* ---- lobees team section ---- */}
      <DeveloperLobeesTeam
        autoScollContactNow={autoScollContactNow}
        setSourceName={setSourceName}
      />

      {/* ---- how we do it section ---- */}
      <DeveloperHowWeDo />

      {/* ---- let's talk section ---- */}
      <Conversation
        source="Developers"
        autoScollContactNow={autoScollContactNow}
        setSourceName={setSourceName}
      />

      {/* ---- customer feedback section ---- */}
      <CustomerFeedback />

      {/* --- contact now section --- */}
      <div ref={contactNowRef} />
      <ContactNow sourceName={sourceName} setSourceName={setSourceName} />

      {/* ---- footer section ----*/}
      <Footer />
    </>
  );
};

export default Develop;
