import React from "react";
import ActivitiesLandingLeftPanel from "../ActivitiesLandingLeftPanel/ActivitiesLandingLeftPanel";
import ActivitiesLandingMiddlePanel from "../ActivitiesLandingMiddlePanel/ActivitiesLandingMiddlePanel";
import ActivitiesLandingRightPanel from "../ActivitiesLandingRightPanel/ActivitiesLandingRightPanel";
import AllFilterModal from "components/common/Modals/AllFilterModal";

const ActivitiesLandingBody = () => {
  return (
    <section className="feed_pnltotal">
      <div className="container-fluid">
        <div className="pnl_outer">
          <ActivitiesLandingLeftPanel />
          <ActivitiesLandingMiddlePanel />
          <ActivitiesLandingRightPanel />
        </div>
      </div>

      {/* -------- all filter modal -------- */}
      <AllFilterModal />
    </section>
  );
};

export default ActivitiesLandingBody;
