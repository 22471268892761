/* eslint-disable */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData, postData } from "utils/Gateway";

//------ get utils functions -----------
import { GetDateTime } from "utils/DateTimeFromTimestamp";

// ----- Import Popups and modals----------
import ConversationAiHistory from "./ConversationAiHistory";

// ----- Import Suggest Modal Items----------
import SuggestContact from "./../SuggestModuleItemCards/SuggestContact";

import SuggestCompany from "./../SuggestModuleItemCards/SuggestCompany";

import SuggestCourse from "./../SuggestModuleItemCards/SuggestCourse";

import SuggestEvent from "./../SuggestModuleItemCards/SuggestEvent";

import SuggestGroup from "./../SuggestModuleItemCards/SuggestGroup";

import SuggestJob from "./../SuggestModuleItemCards/SuggestJob";

import SuggestProduct from "./../SuggestModuleItemCards/SuggestProduct";

import SuggestServices from "./../SuggestModuleItemCards/SuggestServices";

const ConversationRightAiPanel = () => {
  // translation
  const { t } = useTranslation();

  // all chat messages in array to display
  const [chatMessages, setChatMessages] = useState([]);

  const [gptPrompts, setGptPrompts] = useState([]);

  // current chat query asked by logged in user
  const [queryMessage, setQueryMessage] = useState("");

  const [waitingResponse, setWaitingResponse] = useState(false);

  const [aiCommunicationError, setAiCommunicationError] = useState(false);

  const [gptThreadId, setGptThreadId] = useState(null);

  const [gptAssistantId, setGptAssistantId] = useState(null);

  const [chatObjective, setChatObjective] = useState(null); //

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  // get CurrentTime and Location
  const currentTime = new Date();
  const formattedTime = GetDateTime(currentTime);

  // location info
  let locationInfo = null;
  if (localStorage.getItem("locationInfo")) {
    locationInfo = JSON.parse(localStorage.getItem("locationInfo"));
  }
  const token = localStorage.getItem("token");

  // reference chat object to hold information about chat data
  let chatMessageObj = {
    userid: null,
    username: "",
    userimage: "",
    tracker: "0",
    time: GetDateTime(new Date()),
    suggest: true,
    tags: [],
    message: "",
    moduleselected: "",
  };

  // send query to chatGpt and get response
  const chatMessageHandler = async (e) => {
    if (e) e.preventDefault(); // check if e is provided to prevent errors when called from FORM

    setWaitingResponse(true);

    // push the message of user as question in ChatMessage to display in view
    // copy all default properties
    let queryMessageObj = { ...chatMessageObj };
    queryMessageObj.userid = localStorage.getItem("userID");
    queryMessageObj.message = queryMessage;

    if (queryMessage.length > 1) {
      setChatMessages((prevResponses) => [...prevResponses, queryMessageObj]);
    }

    // reset values of properties
    resetAll();

    // gpt post params
    const userName = userInfo ? userInfo.name : "Guest";

    const gptParams = {
      threadid: gptThreadId,
      assistantid: gptAssistantId,
      username: userName,
      message: queryMessage,
      promotslug: chatObjective,
    };

    try {
      let requestURL = url.API_BASE_URL + url.API_CHAT_GPT_ASSISTANT;

      console.log("request url -->", requestURL);
      console.log("request params -->", gptParams);

      //const response = {}
      const response = await postData(requestURL, gptParams);

      console.log("response -->", response);

      if (response.status) {
        setWaitingResponse(false);

        // set the thread id and assistant id to continue discussion with same context
        setGptThreadId(response.threadid);
        setGptAssistantId(response.assistantid);

        // add response in ChatMessage array to display in view
        let responseMessageObj = { ...chatMessageObj };

        let lastResponseMsg = "";
        if (response.data && response.data.length > 0) {
          lastResponseMsg = response.data[0];
        }
        responseMessageObj.message = lastResponseMsg;
        responseMessageObj.username = "AI Assistant";
        responseMessageObj.moduleselected = response.moduleselected;

        setChatMessages((prevResponses) => [
          ...prevResponses,
          responseMessageObj,
        ]);

        //once the discussion start, set the chatObjective = null
        // so that AI can continue discussion with the same assistant
        setChatObjective(null);
      }
    } catch (e) {
      console.log("error message -->", e.message);
    }
  };

  const ref = useRef(null);

  const scrollToBottom = () => {
    const lastChildElement = ref.current?.lastElementChild;
    lastChildElement?.scrollIntoView({ behavior: "smooth" });
  };

  // reset all records
  const resetAll = () => {
    setQueryMessage("");
  };

  const closeMobileAIPanelHandler = () => {
    document.body.classList.toggle("chatmsg_open");
  };

  const restartDiscussion = () => {
    setChatMessages([]);
    setChatObjective(null);
    setGptAssistantId(null);
    setGptThreadId(null);
  };

  // get all prompts of ecosystem
  const getEcosystemPrompts = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_CHAT_GPT_PROMPTS + `?token=${token}`;

      const response = await getData(requestUrl);

      console.log("gptPrompts", response);

      if (response.status) {
        setGptPrompts(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // get all ecosystem prompts available
  useEffect(() => {
    getEcosystemPrompts();
  }, []);

  // when select a prompt, initiate chat converstion based on that prompt
  useEffect(() => {
    if (chatObjective != null) {
      chatMessageHandler();
    }
  }, [chatObjective]);

  useEffect(() => {
    // Scroll to the bottom when new messages adds
    scrollToBottom();
  }, [chatMessages]);

  return (
    <>
      {/* Top header of right panel */}

      <div className="chat_topPnl">
        <div className="chatusr_info">
          <div className="list_back">
            <Link to="#" onClick={closeMobileAIPanelHandler}>
              <i className="material-icons-round">arrow_back</i>
            </Link>
          </div>
          <Link to="#" className="chat_usrOuter profile_linkBtn">
            <div className="chatting_user">
              <img src="images/ai-logo.svg" alt="" />
            </div>
            <div className="chat_usrtext">
              <h3>LobeesAI</h3>
            </div>
          </Link>
          <div className="assistant_btns">
            <ul className="d-flex align-items-center gap-3">
              {/* <li>
                        <Link to="#" className="shopping_bag">
                            <span className="material-icons-outlined d-block">shopping_bag</span>
                        </Link>
                    </li> */}
              <li>
                <Link
                  to="#"
                  className="clock_sttaus"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#history"
                >
                  <span className="material-icons-outlined d-block">
                    update
                  </span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="serch_pnl">
          <div className="srch_innrFill">
            <input
              type="text"
              className="form-control"
              placeholder="Search for conversation"
            />
            <button className="srch_submitBtn">
              <i className="material-icons-round"> send</i>
            </button>
          </div>
        </div>
      </div>

      {/* polulate the chat data */}
      <div className="chating_mdlPnl ai_chat_innr">
        <div className="chatting_innr">
          <div
            className="msg_outer"
            ref={ref}
            //id="chatmessagecontent"
            style={{ height: "40rem" }}
          >
            <div className="msg_row group_usrmsg_row" key="abc">
              <div className="chat_infoLft">
                <div className="msg_usr">
                  <img src="images/ai-logo.svg" alt="" />
                </div>
              </div>
              <div className="chat_msgright">
                <div className="grp_usrname_time">
                  <h5>
                    <span className="grp_usrTime">
                      Lobees - {formattedTime}
                    </span>
                  </h5>
                </div>
                <div className="msg_text">
                  <h2>
                    {t("Hello")} {userInfo.name}
                  </h2>
                  <p>
                    {t(
                      "To continue, please choose a service below or just say hello"
                    )}{" "}
                    😊{" "}
                  </p>
                  {locationInfo ? (
                    <p className=" text-primary">
                      <span className="me-1">
                        <i className="material-icons-round"> location_on </i>
                      </span>
                      {locationInfo.city && (
                        <span className="ms-1">{locationInfo.city}</span>
                      )}
                      {locationInfo.country && (
                        <span className="ms-1">, {locationInfo.country}</span>
                      )}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            {chatMessages.map((chatMessage, index) => {
              const messageText = chatMessage.message.split("\n");

              if (chatMessage.userid) {
                // user message
                return (
                  <div className="msg_row own_msgRow grp_ownmsg" key={index}>
                    <div className="chat_msgright">
                      <div className="chat_info_timeOuter">
                        <h5>
                          {chatMessage.time}
                          <span className="msg_status">
                            <i className="material-icons-round">
                              {" "}
                              location_on{" "}
                            </i>
                          </span>
                          {locationInfo ? locationInfo.city : ""} -
                          {locationInfo ? locationInfo.country : ""}
                        </h5>
                      </div>
                      <div className="own_msgArea">
                        <p>{chatMessage.message}</p>
                      </div>
                    </div>
                  </div>
                );
              } else {
                // ai message
                return (
                  <>
                    <div className="msg_row group_usrmsg_row" key={index}>
                      <div className="chat_infoLft">
                        <div className="msg_usr">
                          <img src="images/ai-logo.svg" alt="" />
                        </div>
                      </div>
                      <div className="chat_msgright">
                        <div className="grp_usrname_time">
                          <h5>
                            <span className="grp_usrTime">
                              Lobees - {chatMessage.time}
                            </span>
                          </h5>
                        </div>
                        <div className="msg_text">
                          <ul
                            className={` ${
                              aiCommunicationError ? "text-danger" : ""
                            }`}
                          >
                            {messageText.map((paragraph, index) => (
                              <li key={index}>{paragraph}</li>
                            ))}
                          </ul>

                          {/* <p>Tag: {chatMessage.moduleselected}</p> */}
                        </div>
                      </div>
                    </div>

                    {/* suggest modules based on conversation tags */}
                    {chatMessage.suggest === true &&
                    chatMessage.moduleselected ? (
                      <div className=" ai_suggetions_area mb-3">
                        <ul className="d-flex align-items-center gap-1 justify-content-end flex-wrap">
                          {chatMessage.moduleselected.includes("PRODUCT") ? (
                            <li>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                Marketplace - Buy Product
                              </Link>
                            </li>
                          ) : (
                            ""
                          )}

                          {chatMessage.moduleselected.includes("SERVICE") ? (
                            <li>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                Marketplace - Contract service
                              </Link>
                            </li>
                          ) : (
                            ""
                          )}

                          {chatMessage.moduleselected.includes("COMPANY") ? (
                            <li>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                Company offers, you might be interested
                              </Link>
                            </li>
                          ) : (
                            ""
                          )}

                          {chatMessage.moduleselected.includes("USER") ? (
                            <li>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                Search and connect Experts
                              </Link>
                            </li>
                          ) : (
                            ""
                          )}

                          {chatMessage.moduleselected.includes("EVENT") ? (
                            <li>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                Events nearby
                              </Link>
                            </li>
                          ) : (
                            ""
                          )}

                          {chatMessage.moduleselected.includes("GROUP") ? (
                            <li>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                Groups that you might like to join
                              </Link>
                            </li>
                          ) : (
                            ""
                          )}

                          {chatMessage.moduleselected.includes("COURSE") ? (
                            <li>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                Courses you can learn
                              </Link>
                            </li>
                          ) : (
                            ""
                          )}

                          {chatMessage.moduleselected.includes("JOB") ? (
                            <li>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                Jobs that matches your skills
                              </Link>
                            </li>
                          ) : (
                            ""
                          )}
                        </ul>
                      </div>
                    ) : (
                      ""
                    )}

                    {/* show module items cards as suggested */}
                    {chatMessage.moduleselected &&
                    chatMessage.moduleselected.includes("USER") ? (
                      <SuggestContact />
                    ) : (
                      ""
                    )}

                    {chatMessage.moduleselected &&
                    chatMessage.moduleselected.includes("COMPANY") ? (
                      <SuggestCompany />
                    ) : (
                      ""
                    )}

                    {chatMessage.moduleselected &&
                    chatMessage.moduleselected.includes("COURSE") ? (
                      <SuggestCourse />
                    ) : (
                      ""
                    )}

                    {chatMessage.moduleselected &&
                    chatMessage.moduleselected.includes("EVENT") ? (
                      <SuggestEvent />
                    ) : (
                      ""
                    )}

                    {chatMessage.moduleselected &&
                    chatMessage.moduleselected.includes("GROUP") ? (
                      <SuggestGroup />
                    ) : (
                      ""
                    )}

                    {chatMessage.moduleselected &&
                    chatMessage.moduleselected.includes("JOB") ? (
                      <SuggestJob />
                    ) : (
                      ""
                    )}

                    {chatMessage.moduleselected &&
                    chatMessage.moduleselected.includes("PRODUCT") ? (
                      <SuggestProduct />
                    ) : (
                      ""
                    )}

                    {chatMessage.moduleselected &&
                    chatMessage.moduleselected.includes("SERVICE") ? (
                      <SuggestServices />
                    ) : (
                      ""
                    )}
                  </>
                );
              }
            })}

            {/* when AI is thinking  */}
            {waitingResponse ? (
              <div className="msg_row reply_msgRow">
                <div className="chat_infoLft">
                  <div className="msg_usr">
                    <img src="images/ai-logo.svg" alt="" />
                  </div>
                  <div className="chat_info_timeOuter">
                    <div className="chat_dots">
                      <img src="images/dots-loading.gif" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>

        <div
          className={`reply_upload_wrapper ${
            chatMessages.length > 0 ? "d-none" : ""
          }`}
        >
          <div className="reply_popup_area ai_suggetions_area">
            <ul className="d-flex align-items-center gap-1 justify-content-end flex-wrap">
              {/* <li><Link 
                  to="#" 
                  className="d-flex align-items-center bg-primary text-white"
                  onClick={()=>{
                    setChatObjective('hello')
                  }}
                  >
                    {t('Say Hello')}..👋
                  </Link>
            </li> */}
              {gptPrompts.length > 0
                ? gptPrompts.map((gptPrompt, index) => {
                    return (
                      <li key={index}>
                        <Link
                          to="#"
                          className="d-flex align-items-center bg-primary text-white"
                          onClick={() => {
                            console.log("gptPrompt.slug", gptPrompt.slug);
                            setChatObjective(gptPrompt.slug);
                          }}
                        >
                          {t(gptPrompt.title)}
                        </Link>
                      </li>
                    );
                  })
                : ""}
            </ul>
          </div>
        </div>
      </div>

      {/* chat message box */}
      <div className="chat_sendPnl">
        <div className="emoji_iconArea">
          <ul className="list_stye_none">
            <li>
              <Link
                to="#"
                onClick={() => {
                  restartDiscussion();
                }}
              >
                <span className="material-icons-round">refresh</span>
              </Link>
            </li>
          </ul>
        </div>
        <div className="send_textbx">
          <form onSubmit={chatMessageHandler}>
            <input
              type="text"
              autoComplete="off"
              className="form-control"
              placeholder={t("Enter your message")}
              value={queryMessage}
              onChange={(e) => setQueryMessage(e.target.value)}
              id="chartboxinput"
              autoFocus={true}
            />
            <button type="submit" className="chat_send_btn">
              <span className="material-icons-round">send</span>
            </button>
          </form>
        </div>
      </div>

      <ConversationAiHistory />
    </>
  );
};

export default ConversationRightAiPanel;
