import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import * as url from "helper/UrlHelper";

const CourseListCard = ({ courseData = {} }) => {
  const { t } = useTranslation(); // translation marker

  return (
    <div className="people_row course_row groups_item">
      <div className="course_left">
        {courseData.courselogo === "" ? (
          <span
            className="placeholder"
            style={{ width: "128px", height: "128px" }}
          ></span>
        ) : (
          <img src={url.SERVER_URL + courseData.courselogo} alt="" />
        )}
      </div>
      <div className="course_right">
        <div className="top_company_row">
          <div className="company_logotxt">
            {/* <span>
              <img src="images/google.svg" alt="" />
            </span> */}
            <span>{courseData.coursecategories}</span>
          </div>
          <div className="rating_tags d-flex align-items-center">
            <div className="jb_rating">
              <i className="material-icons-outlined ornge_icon">star</i>
              <span>
                {courseData.totalratings} <em>({courseData.totalreviews})</em>
              </span>
            </div>
            {/* <div className="tags_icon">
              <Link to="#">
                <span className="material-icons-outlined">bookmark_border</span>
              </Link>
            </div> */}
          </div>
        </div>
        <div className="event_hdng">
          <h3>{courseData.title}</h3>
        </div>
        {courseData.courseskills ? (
          <div className="event_tags">
            <ul className="list_stye_none">
              {courseData?.courseskills?.map((skill, index) => {
                return (
                  <li key={index}>
                    <Link to="#">{skill}</Link>
                  </li>
                );
              })}
            </ul>
          </div>
        ) : null}
        <div className="details_btn">
          <Link
            to={`/courses/details/${courseData._id}`}
            className="btn outline"
          >
            {t("View Details")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CourseListCard;
