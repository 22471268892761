import React from 'react';
import ImprovementAreaLeftPanel from '../ImprovementAreaLeftPanel/ImprovementAreaLeftPanel';
import ImprovementAreaRightPanel from '../ImprovementAreaRightPanel/ImprovementAreaRightPanel';

const ImprovementAreaBody = () => {
  return (
    <section className="feed_pnltotal">
      <div className="container-fluid">
        <div className="pnl_outer profile_pnlOuter">
          <ImprovementAreaLeftPanel moduleName="improvementArea" />
          <ImprovementAreaRightPanel />
        </div>
      </div>
    </section>
  );
};

export default ImprovementAreaBody;
