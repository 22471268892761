/* eslint-disable */
import React, { useContext, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData, postData } from "utils/Gateway";
import SaveSurveyModal from "components/common/Modals/SaveSurveyModal";

import { ecosystemSlug } from "Config/Config";
import SaveSurveySuccessModal from "components/common/Modals/SaveSurveySuccessModal";
import SurveyLeftPanel from "../SurveyPanels/SurveyLeftPanel/SurveyLeftPanel";
import SurveyRightPanel from "../SurveyPanels/SurveyRightPanel/SurveyRightPanel";

const SurveyBody = () => {
  const params = useParams(); //survey invite id

  //left panel and right panel requirements
  const [surveyInviteDetailsLoading, setSurveyInviteDetailsLoading] =
    useState(false);
  const [surveyInviteeImage, setSurveyInviteeImage] = useState("");
  const [surveyInviteeName, setSurveyInviteeName] = useState("");
  const [surveyInviteeMemberFrom, setSurveyInviteeMemberFrom] = useState("");
  const [totalNoOfSurveyInvites, setTotalNoOfSurveyInvites] = useState("");
  const [totalNoOfGivenSurveys, setTotalNoOfGivenSurveys] = useState("");
  const [surveyInviterImage, setSurveyInviterImage] = useState("");
  const [surveyInviterName, setSurveyInviterName] = useState("");
  const [responseListLoading, setResponseListLoading] = useState(false);
  const [surveyResponseList, setSurveyResponseList] = useState([]);
  const [showEmptyMessage, setShowEmptyMessage] = useState(false);

  const [surveyId, setsurveyId] = useState(null);
  const [surveyInviteeEmail, setSurveyInviteeEmail] = useState("");
  const [surveyTitle, setSurveyTitle] = useState("");
  const [surveyQuestion, setSurveyQuestion] = useState(null);
  const [totalNoOfQuestions, settotalNoOfQuestions] = useState(0);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showQuestionModal, setshowQuestionModal] = useState(false);

  const [isSending, setIsSending] = useState(false);

  //get invite details
  const getSurveyInviteDetails = async () => {
    setSurveyInviteDetailsLoading(true);
    try {
      let requestURL =
        url.API_BASE_URL + url.API_GET_SURVEY_INVITE_DETAILS + `/${params.id}`;

      // console.log(requestURL);

      const response = await getData(requestURL);

      // console.log(response);

      setSurveyInviteDetailsLoading(false);

      if (response.status) {
        setsurveyId(response.data.surveyid);
        setSurveyTitle(response.data.name);
        setSurveyInviteeImage(response.data.surveyinviteeimage);
        setSurveyInviteeName(response.data.surveyinviteename);
        setSurveyInviteeMemberFrom(response.data.surveyinviteememberfrom);
        setTotalNoOfSurveyInvites(response.data.totalnoofsurveyinvites);
        setTotalNoOfGivenSurveys(response.data.totalgivensurveyresponses);
        setSurveyInviterImage(response.data.surveyinviterimage);
        setSurveyInviterName(response.data.surveyinvitername);
        setSurveyInviteeEmail(response.data.invitee.email);
        getAllSurveyResponses(response.data.invitee.email);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //get all responses by invitee
  const getAllSurveyResponses = async (inviteeEmail) => {
    try {
      setResponseListLoading(true);
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_SURVEY_RESPONSES +
        `?filtersurveyuseremail=${inviteeEmail}`;

      console.log(requestURL);

      const response = await getData(requestURL);

      // console.log(response);

      setResponseListLoading(false);

      if (response.status) {
        if (response.data.length === 0) {
          setShowEmptyMessage(true);
        } else {
          setShowEmptyMessage(false);
        }
        setSurveyResponseList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //get survey questions
  const getSurveyQuestions = async () => {
    try {
      let requestURL =
        url.API_BASE_URL + url.API_GET_SURVEY_INVITE_DETAILS + `/${params.id}`;

      console.log(requestURL, currentQuestionIndex);

      const response = await getData(requestURL);

      if (response.status) {
        if (response.data.questionlist.length > 0) {
          settotalNoOfQuestions(response.data.questionlist.length);
          const getUnansweredQuestions = response.data.questionlist;

          setSurveyQuestion(getUnansweredQuestions[currentQuestionIndex]);

          //show otpconfirm modal
          let myModal = new bootstrap.Modal(
            document.getElementById("save_survey")
          );
          myModal.show();
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //save answer of survey
  const saveAnswerOfSurvey = async (questionid, answer) => {
    try {
      let sureveyResponseData = {
        ecosystemidslug: ecosystemSlug,
        surveyid: surveyId,
        surveyinviteid: params.id,
        questionid,
        response: answer,
      };

      let requestURL = url.API_BASE_URL + url.API_SAVE_RESPONSE_SURVEY;

      const response = await postData(requestURL, sureveyResponseData);

      setIsSending(false);

      //hide modal
      let surveyModal = document.querySelector("#save_survey");
      let modal = bootstrap.Modal.getInstance(surveyModal);
      modal.hide();

      console.log(response);

      if (response.status) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        setshowQuestionModal(true);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //fetch previous answer and show modal
  const previousModalhandler = () => {
    setshowQuestionModal(true);
  };

  //function for after success modal close
  const closeModalHandler = (isSubmit) => {
    setCurrentQuestionIndex(0);
    if (isSubmit) {
      getAllSurveyResponses(surveyInviteeEmail);
    }
  };

  /**
   * use effect for display next question in survey modal.
   * If survey has finsihed then success modal will show.
   */
  useEffect(() => {
    if (showQuestionModal) {
      if (currentQuestionIndex + 1 <= totalNoOfQuestions) {
        getSurveyQuestions();
      } else {
        //show success modal
        let myModal = new bootstrap.Modal(
          document.getElementById("survey_success_mdl")
        );
        myModal.show();
      }

      setshowQuestionModal(false);
    }
  }, [showQuestionModal]);

  /**
   * Get survey invite details
   */
  useEffect(() => {
    if (params.id) {
      getSurveyInviteDetails();
    }
  }, [params.id]);

  return (
    <div className="my_survey_outer d-flex flex-wrap">
      {/* <!-- ========== Start survey panel component ========== --> */}
      <SurveyLeftPanel
        surveyInviteDetailsLoading={surveyInviteDetailsLoading}
        surveyInviteeImage={surveyInviteeImage}
        surveyInviteeName={surveyInviteeName}
        surveyInviteeMemberFrom={surveyInviteeMemberFrom}
        totalNoOfSurveyInvites={totalNoOfSurveyInvites}
        totalNoOfGivenSurveys={totalNoOfGivenSurveys}
        responseListLoading={responseListLoading}
        showEmptyMessage={showEmptyMessage}
        surveyResponseList={surveyResponseList}
      />
      {/* <!-- ========== End survey panel component ========== --> */}

      {/* <!-- ========== Start right panel component ========== --> */}
      <SurveyRightPanel
        surveyInviteDetailsLoading={surveyInviteDetailsLoading}
        surveyInviteeName={surveyInviteeName}
        surveyInviterImage={surveyInviterImage}
        surveyInviterName={surveyInviterName}
        surveyTitle={surveyTitle}
        getSurveyQuestions={getSurveyQuestions}
      />

      {/* <!-- ========== End right panel component ========== --> */}

      {/* <!-- ========== Start survey modal component ========== --> */}
      <SaveSurveyModal
        closeModalHandler={closeModalHandler}
        isSending={isSending}
        setIsSending={setIsSending}
        previousModalhandler={previousModalhandler}
        surveyTitle={surveyTitle}
        surveyQuestion={surveyQuestion}
        currentQuestionIndex={currentQuestionIndex}
        setCurrentQuestionIndex={setCurrentQuestionIndex}
        totalNoOfQuestions={totalNoOfQuestions}
        saveAnswerOfSurvey={saveAnswerOfSurvey}
      />
      {/* <!-- ========== End survey modal component ========== --> */}

      {/* <!-- ========== Start save success modal component ========== --> */}
      <SaveSurveySuccessModal closeModalHandler={closeModalHandler} />
      {/* <!-- ========== End save success modal component ========== --> */}
    </div>
  );
};

export default SurveyBody;
