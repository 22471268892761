/* eslint-disable */
import AuthHeader from "components/common/AuthHeader";
import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";

//import context
import { GlobalProvider } from "context/GlobalContext";
import GroupLandingBody from "components/GroupsComponents/GroupLandingComponents/GroupLandingBody/GroupLandingBody";

const GroupLanding = () => {
  return (
    <>
      <AuthHeader moduleName={"group"} />

      <GroupLandingBody />
    </>
  );
};

export default GroupLanding;
