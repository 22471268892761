/* eslint-disable */
import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { postData } from "utils/Gateway";

//import context
import { GlobalProvider } from "context/GlobalContext";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";

const ForgotPasswordModal = () => {
  const { setuserEmailforOtp, setcreateAccountMessage } =
    useContext(GlobalProvider);

  const { t } = useTranslation(); // translation marker

  const [userEmail, setUserEmail] = useState("");
  const [emailWarning, setEmailWarning] = useState(false);
  const [notValidEMail, setNotValidEMail] = useState(false);
  const [otpSending, setotpSending] = useState(false);

  //otp function
  const otpHandler = async () => {
    if (userEmail == "") {
      setEmailWarning(true);
    } else {
      setEmailWarning(false);

      const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (userEmail.match(mailformat)) {
        setNotValidEMail(false);
        try {
          setotpSending(true);
          let userData = {
            email: userEmail,
          };
          let requestURL = url.API_BASE_URL + url.API_GET_OTP;

          const response = await postData(requestURL, userData);

          console.log(response);

          setotpSending(false);

          if (response.status) {
            setcreateAccountMessage("");
            setuserEmailforOtp(userEmail);

            //hide login modal
            let loginModal = document.querySelector("#forgot_modal");
            let modal = bootstrap.Modal.getInstance(loginModal);
            modal.hide();

            //show otpconfirm modal
            let myModal = new bootstrap.Modal(
              document.getElementById("otp_validate_mdl")
            );
            myModal.show();
          } else {
            setuserEmailforOtp("");
            setcreateAccountMessage(
              "Email does not exist.Please create a new account."
            );

            //hide login modal
            let loginModal = document.querySelector("#forgot_modal");
            let modal = bootstrap.Modal.getInstance(loginModal);
            modal.hide();

            //show register modal
            let myModal = new bootstrap.Modal(
              document.getElementById("register_mdl")
            );
            myModal.show();
          }
          setUserEmail("");
        } catch (error) {
          console.log(error.message);
        }
      } else {
        setNotValidEMail(true);
      }
    }
  };

  //reset function
  const resetModal = () => {
    setUserEmail("");
    setuserEmailforOtp("");
    setcreateAccountMessage("");
  };

  return (
    <div className="registration_modal">
      <div
        className="modal fade show"
        id="forgot_modal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog small_modal">
          <div className="modal-content">
            <div className="modal-header">
              <div className="signin_hdng text-left">
                <h3>{t("Forgot Password")}</h3>
                <h5>{t("Please enter email id we will send you a OTP")}</h5>
              </div>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                data-bs-toggle="modal"
                data-bs-target="#login_modal"
                aria-label="Close"
                onClick={resetModal}
              >
                <i className="material-icons-outlined">close</i>
              </button>
            </div>
            <div className="modal-body">
              <div className="login_body">
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="landing_login_fillOuter">
                    <div className="input_fill_landing">
                      <label>{t("Email")}</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder={t("Enter email")}
                        value={userEmail}
                        onChange={(e) => {
                          setEmailWarning(false);
                          setUserEmail(e.target.value);
                        }}
                      />
                      {/* <p className="error">
                        <span className="material-icons-outlined"> info</span>
                        This is a error message
                      </p> */}
                      {/* ------ email warning start ------ */}
                      {emailWarning && (
                        <p className="error mt-1">
                          <span className="material-icons-outlined me-1">
                            info
                          </span>
                          {t("Please enter email")}
                        </p>
                      )}
                      {/* ----- valid email warning ------ */}
                      {notValidEMail && (
                        <p className="error mt-1">
                          <span className="material-icons-outlined me-1">
                            info
                          </span>
                          {t("Please enter valid email")}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="apply_btn d-flex justify-content-end">
                    <button
                      className="btn"
                      onClick={otpHandler}
                      disabled={otpSending ? true : false}
                      style={{
                        cursor: otpSending ? "not-allowed" : "pointer",
                      }}
                    >
                      {t("Send OTP")}
                      {otpSending && (
                        <div
                          className="mx-2 spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordModal;
