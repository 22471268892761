/* eslint-disable */
import "react-multi-carousel/lib/styles.css";

import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
//import context
import { GlobalProvider } from "context/GlobalContext";
/*import url and gateway methods */
import { getData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";

//import images
import { assetImages } from "constants";

const AppUserRightPanel = ({ similarProfileList }) => {
  const { screenHeight } = useContext(GlobalProvider);
  const token = localStorage.getItem("token");
  const { t } = useTranslation(); // translation marker

  return (
    <div className="pnl_rightpnl">
      <div className="white_shadow_bx">
        <div className="contact_header">
          <div className="post_hdng">
            <h4>{t("Similar Profiles")}</h4>
          </div>
          <div className="contacts_srch_right"></div>
        </div>
        <div className="similar_postpnl">
          {similarProfileList.map((user, index) => {
            return (
              <div className="similar_post_row" key={index}>
                <Link
                  to={`/peoples/details/${user._id}`}
                  className="left_people"
                >
                  <div className="people_profile">
                    <img
                      src={
                        user.userimage == ""
                          ? assetImages.defaultUser
                          : url.SERVER_URL + user.userimage
                      }
                      alt=""
                    />
                  </div>
                  <div className="people_profile_info">
                    <h3>{user.username}</h3>
                    <h5>
                      {user.position
                        ? user.position === ""
                          ? "-"
                          : user.position
                        : "-"}
                    </h5>
                    <div className="event_tags">
                      <ul className="list_stye_none mb-0">
                        <li>
                          <i className="material-icons-outlined ornge_icon">
                            star
                          </i>
                          <span className="rating">
                            {user.totalratings} <em>({user.totalreviews})</em>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
          {/* <div className="similar_post_row">
            <div className="left_people">
              <div className="people_profile">
                <img src="images/people1.png" alt="" />
              </div>
              <div className="people_profile_info">
                <h3>Harry Nonalarm</h3>
                <h5>Sales Manager at Lobees Ecosystem</h5>
                <div className="event_tags">
                  <ul className="list_stye_none mb-0">
                    <li>
                      <i className="material-icons-outlined ornge_icon">star</i>
                      <span className="rating">
                        4.5 <em>(32)</em>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="similar_post_row">
            <div className="left_people">
              <div className="people_profile">
                <img src="images/people1.png" alt="" />
              </div>
              <div className="people_profile_info">
                <h3>Harry Nonalarm</h3>
                <h5>Sales Manager at Lobees Ecosystem</h5>
                <div className="event_tags">
                  <ul className="list_stye_none mb-0">
                    <li>
                      <i className="material-icons-outlined ornge_icon">star</i>
                      <span className="rating">
                        4.5 <em>(32)</em>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="similar_post_row">
            <div className="left_people">
              <div className="people_profile">
                <img src="images/people1.png" alt="" />
              </div>
              <div className="people_profile_info">
                <h3>Harry Nonalarm</h3>
                <h5>Sales Manager at Lobees Ecosystem</h5>
                <div className="event_tags">
                  <ul className="list_stye_none mb-0">
                    <li>
                      <i className="material-icons-outlined ornge_icon">star</i>
                      <span className="rating">
                        4.5 <em>(32)</em>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="similar_post_row">
            <div className="left_people">
              <div className="people_profile">
                <img src="images/people1.png" alt="" />
              </div>
              <div className="people_profile_info">
                <h3>Harry Nonalarm</h3>
                <h5>Sales Manager at Lobees Ecosystem</h5>
                <div className="event_tags">
                  <ul className="list_stye_none mb-0">
                    <li>
                      <i className="material-icons-outlined ornge_icon">star</i>
                      <span className="rating">
                        4.5 <em>(32)</em>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default AppUserRightPanel;
