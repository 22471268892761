/* eslint-disable */
import React, { useState } from "react";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { postData } from "utils/Gateway";

//import images
import { assetImages } from "constants";
import SuccessModal from "./Modals/SuccessModal";

import { ecosystemSlug } from "Config/Config";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";

const ContactNow = ({ sourceName, setSourceName }) => {
  const [name, setname] = useState("");
  const [surName, setsurName] = useState("");
  const [email, setemail] = useState("");
  const [companyName, setcompanyName] = useState("");
  const [message, setmessage] = useState("");
  const [requiredWarning, setRequiredWarning] = useState(false);
  const [isSending, setisSending] = useState(false);

  //function submit contact data
  const submitMessageHandler = async () => {
    if (name == "" || email == "" || message == "") {
      setRequiredWarning(true);
    } else {
      setRequiredWarning(false);
      try {
        setisSending(true);

        let contactData = {
          name,
          surname: surName,
          email,
          company: companyName,
          customercomment: message,
          source: sourceName || "",
        };

        let requestUrl =
          url.API_BASE_URL +
          url.API_SAVE_GUEST_LEAD +
          `?ecosystemslug=${ecosystemSlug}`;

        const response = await postData(requestUrl, contactData);

        // console.log(response);

        if (response.status) {
          setisSending(false);
          const bootstrapModal = new bootstrap.Modal(
            document.getElementById("success_modal")
          );
          bootstrapModal.show();
          resetHandler();
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  //function for after modal close execution
  const afterModalClose = () => {};

  //reset function
  const resetHandler = () => {
    setRequiredWarning(false);
    setname("");
    setsurName("");
    setemail("");
    setcompanyName("");
    setmessage("");
    if (setSourceName) {
      setSourceName("");
    }
  };
  const { t } = useTranslation(); // translation marker
  return (
    <section className="contact_sec pd_130">
      <div className="container">
        <div className="contact_dots">
          <img src={assetImages.contactDots} alt="contactDots" />
        </div>
        <div className="page_hdng white_hdng">
          <h5>{t("Contact Now")}</h5>
          <h2>{t("Let’s connect & make a change together")}</h2>
        </div>
        {/* ----- contact form section start ----- */}
        <div className="contact_form_area">
          <form onSubmit={(e) => e.preventDefault()}>
            {/* ----- name and surname section start ----- */}
            <div className="input_filOuter">
              {/* ----- name section start ----- */}
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  value={name}
                  onChange={(e) => {
                    setRequiredWarning(false);
                    setname(e.target.value);
                  }}
                  placeholder={t("First Name (*required)")}
                  required
                />
              </div>
              {/* ----- name section end ----- */}

              {/* ----- surname section start ----- */}
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("Last Name")}
                  value={surName}
                  onChange={(e) => setsurName(e.target.value)}
                  required
                />
              </div>
              {/* ----- surname section end ----- */}
            </div>
            {/* ----- name and surname section end ----- */}

            {/* ----- email section start ----- */}
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                onChange={(e) => {
                  setRequiredWarning(false);
                  setemail(e.target.value);
                }}
                placeholder={t("Enter Email (*required)")}
                value={email}
                required
              />
            </div>
            {/* ----- email section end ----- */}

            {/* ----- company section start ----- */}
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                placeholder={t("Your Company")}
                value={companyName}
                onChange={(e) => setcompanyName(e.target.value)}
              />
            </div>
            {/* ----- company section end ----- */}

            {/* ----- message section start ----- */}
            <div className="form-group description_fill">
              <textarea
                className="form-control"
                value={message}
                onChange={(e) => {
                  setmessage(e.target.value);
                  setRequiredWarning(false);
                }}
                placeholder={t("Enter Message (*required)")}
              ></textarea>
            </div>
            {/* ----- message section end ----- */}

            {/* ---- error message section start ----- */}
            {requiredWarning && (
              <p className="error fw-bold">
                * {t("Please fill up the required fields")}
              </p>
            )}
            {/* ---- error message section end ----- */}

            {/* ----- button section start ----- */}
            <div className="submit_btn">
              <button
                type="button"
                className="btn darkgrn_btn"
                onClick={submitMessageHandler}
                style={{ cursor: isSending ? "not-allowed" : "pointer" }}
                disabled={isSending ? true : false}
              >
                {t("Submit Request")}
                {isSending && (
                  <div
                    className="mx-2 spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
              </button>
            </div>
            {/* ----- button section end ----- */}
          </form>
        </div>
        {/* ----- contact form section end ----- */}
      </div>
      {/* ---- success modal ---- */}
      <SuccessModal afterModalClose={afterModalClose} />
    </section>
  );
};

export default ContactNow;
