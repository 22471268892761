import React from "react";
// import use translation ------------------------------
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const GroupDetailsRightPanel = ({ similarList }) => {
  const { t } = useTranslation(); // translation marker
  return (
    <div className="pnl_rightpnl">
      <div className="white_shadow_bx">
        <div className="contact_header">
          <div className="post_hdng">
            <h4>{t("Similar Groups")}</h4>
          </div>
          <div className="contacts_srch_right"></div>
        </div>
        <div className="similar_postpnl">
          {similarList.map((groupData, index) => {
            return (
              <div className="similar_post_row" key={index}>
                <div className="groups_info">
                  <div className="top_grp_info">
                    <div className="experts_holdertext">
                      <h3>
                        <Link to={`/groups/details/${groupData._id}`}>
                          {groupData.title}
                        </Link>
                      </h3>
                      <h5>
                        {groupData.isprivate ? t("Private") : t("Public")} Group
                      </h5>
                    </div>
                  </div>
                  <div className="address">
                    <i className="material-icons-outlined">place</i>
                    <span>
                      {groupData.city}, {groupData.country}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default GroupDetailsRightPanel;
