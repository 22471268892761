/* eslint-disable */
import React, { useEffect, useState, useRef, useContext } from "react";
import { Link } from "react-router-dom";

import { assetImages } from "constants";

import { formatTimeAgo } from "utils/FormatTimeAgo";
import { fileDownloadHandler } from "utils/DownloadFile";
import ChatRightPanelPlaceholder from "components/ConversationComponents/ConversationLeftPanel/Placeholder/ChatRightPanelPlaceholder";
import { languageList } from "helper/Common/CommonHelper";
import ChatMessageContent from "../ConversationRightPanel/ChatMessageContent/ChatMessageContent";
import ChatMessageFlag from "../ConversationRightPanel/ChatMessageFlag/ChatMessageFlag";

/*import url and gateway methods */
import { getData} from "utils/Gateway";
import * as url from "helper/UrlHelper";

const ChatHistoryComponent = ({ showHistory,
                                isGroupChat = false, // for contact its false for group its true
                                setShowHistory,
                                selectedChatRoomId, 
                                searchMessage=null 
                            
                            }) => {

    const token = localStorage.getItem("token");
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    const [chatHistory, setChatHistory] = useState([]);
    const messagesEndRef = useRef(null);

    const [historyChatLimit, setHistoryChatLimit ] = useState(500);
    const [historyChatOffset, setHistoryChatOffset ] = useState(0);
    const [isHistoryLoading, setIsHistoryLoading] = useState(false);

    // load history chat messages to check previous messages
    const getHistoryChatMessages = async () => {

        setIsHistoryLoading(true);

        try {
        let requestUrl =
            url.API_BASE_URL +
            url.API_GET_ALL_CHAT_MESSAGES +
            `?token=${token}&limit=${historyChatLimit}&offset=${historyChatOffset}`;
        
        if(isGroupChat){
            requestUrl += `&chatgroupid=${selectedChatRoomId}`;
        }else{
            requestUrl += `&chatpersonid=${selectedChatRoomId}`;
        }


        if (searchMessage !== "") {
            requestUrl += `&searchmessage=${searchMessage}`;
        }

        console.log('history req url',requestUrl);

        const response = await getData(requestUrl);

        console.log(response);

        setIsHistoryLoading(false);

        if (response.status) {
            setChatHistory(response.data);
        } else {
            setChatHistory([]);
        }
        } catch (error) {
        console.log(error.message);
        }
    };

    const handleClose = () => {   

        setShowHistory(false);
        setChatHistory([]);
    };



  useEffect(() => {
    if (showHistory) {

      getHistoryChatMessages();

    }
  }, [showHistory, historyChatLimit]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chatHistory]);

  return (
    <div className="historyModal">
      <div className="modal fade show" id="chathistorymodal">
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header mdl_hdng d-flex justify-content-end">
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              >
                <span className="material-icons-round">close</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="">
                <div className="upload_innrBody">
                  <div className="upload_outer">

                    {
                    isHistoryLoading ? (
                        <ChatRightPanelPlaceholder />
                    ) :
                    chatHistory.length === 0 ? (
                      <div className="chating_mdlPnl">
                        <div className="chatting_innr chating_start">
                          <div className="nothing_pic">
                            <figure>
                              <img src={assetImages.emptyGroupMessage} alt="" />
                            </figure>
                            <div className="nothing_caption">
                              <p>Oops! messages not found</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="chating_mdlPnl grp_chat_Innr">
                        <div className="chatting_innr">
                          <div className="msg_outer msg_shareicon_info">
                            {chatHistory.map((chatData, index) => (
                              <div
                                className={`msg_row ${
                                  chatData.sender.toString() === userInfo._id.toString()
                                    ? "own_msgRow grp_ownmsg"
                                    : "group_usrmsg_row"
                                }`}
                                key={index}
                              >
                                {chatData.sender.toString() === userInfo._id.toString() ? null : (
                                  <div className="chat_infoLft">
                                    <div className="msg_usr">
                                      <img
                                        src={
                                          chatData.senderimage === ""
                                            ? assetImages.defaultUser
                                            : url.SERVER_URL + chatData.senderimage
                                        }
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                )}
                                <div className="chat_msgright">
                                  <div
                                    className={
                                      chatData.sender.toString() === userInfo._id.toString()
                                        ? "chat_info_timeOuter"
                                        : "grp_usrname_time"
                                    }
                                  >
                                    {chatData.sender.toString() === userInfo._id.toString() ? (
                                      <h5>
                                        {formatTimeAgo(chatData.createdAt)}
                                      </h5>
                                    ) : (
                                      <h5>
                                        <span className="grp_usr_prsn">
                                          {chatData.sendername}
                                        </span>
                                        <span className="ms-2 grp_usrTime">
                                          {formatTimeAgo(chatData.createdAt)}
                                        </span>
                                      </h5>
                                    )}
                                  </div>
                                  {chatData.sender.toString() === userInfo._id.toString() ? (
                                    <div className="owntext_row">
                                      <div className="own_msgArea dropdown">
                                        {chatData.parent ? (
                                          <div className="replied_msg_row">
                                            <ChatMessageContent
                                              message={chatData.parentchatmessage}
                                            />
                                            <span>By {chatData.parentchatsender}</span>
                                          </div>
                                        ) : null}
                                        <Link
                                          to="#"
                                          data-bs-toggle="dropdown"
                                          className="chat_expand_arws"
                                          aria-expanded="false"
                                        >
                                          <span className="material-icons-outlined">
                                            expand_more
                                          </span>
                                        </Link>
                                        <ul className="dropdown-menu">
                                          <li>
                                            <Link
                                              to="#"
                                              onClick={() => {
                                                chatInputRef.current.focus();
                                                setreplyMessage(chatData.description);
                                                setreplyUserName("You");
                                                setparentMessageId(chatData._id);
                                              }}
                                              className="d-flex align-items-center gap-2"
                                            >
                                              <i className="material-symbols-outlined fs-md">
                                                reply
                                              </i>
                                              <span>Reply</span>
                                            </Link>
                                          </li>
                                          {chatData.sender.toString() === userInfo._id.toString() ? (
                                            <li>
                                              <Link
                                                to="#"
                                                onClick={() => {
                                                  deleteChatHandler(chatData._id);
                                                }}
                                                className="d-flex align-items-center gap-2"
                                              >
                                                <i className="material-symbols-outlined fs-md">
                                                  delete
                                                </i>
                                                <span>Delete</span>
                                              </Link>
                                            </li>
                                          ) : null}
                                        </ul>
                                        <div className="replied_msg_text">
                                          <ChatMessageContent
                                            message={chatData.description}
                                          />
                                          {chatData.flaggedlanguage && chatData.flaggedlanguage !== "" ? (
                                            <div
                                              className="replied_msg_row recever_translatebx d-flex gap-1 p-2 rounded-10 overflow-hidden mt-2"
                                              style={{
                                                borderLeft: "2px solid #3191FF",
                                              }}
                                            >
                                              <span
                                                className="d-block rounded-circle overflow-hidden"
                                                style={{
                                                  height: "20px",
                                                  width: "20px",
                                                  minWidth: "20px",
                                                }}
                                              >
                                                <ChatMessageFlag
                                                  language={chatData.flaggedlanguage}
                                                />
                                              </span>
                                              <ChatMessageContent
                                                message={chatData.flaggeddescription}
                                              />
                                            </div>
                                          ) : null}
                                        </div>
                                        {chatData.uploadedchatfiles && chatData.uploadedchatfiles.length > 0 ? (
                                          <div className="upload_innrBody msg_uploadbody">
                                            <div className="upload_outer">
                                              <div className="row">
                                                {chatData.uploadedchatfiles.slice(0, 6).map((file, index2) => {
                                                  const fileExtension = file.path.split(".").pop();
                                                  const fileObj = {
                                                    ...file,
                                                    path: url.SERVER_URL + file.path,
                                                  };
                                                  return (
                                                    <div
                                                      className="col"
                                                      key={index2}
                                                      style={{ cursor: "pointer" }}
                                                      onClick={() => {
                                                        fileDownloadHandler(fileObj);
                                                      }}
                                                    >
                                                      <div className="chat_uploadItem position-relative">
                                                        <div className="chat_upload_pic">
                                                          {fileExtension === "jpg" ||
                                                          fileExtension === "jpeg" ||
                                                          fileExtension === "png" ? (
                                                            <img
                                                              src={url.SERVER_URL + file.path}
                                                              alt=""
                                                            />
                                                          ) : fileExtension === "pdf" ? (
                                                            <img src={assetImages.PdfImg} alt="" />
                                                          ) : fileExtension === "xlsx" ||
                                                            fileExtension === "xlsm" ||
                                                            fileExtension === "xlsb" ||
                                                            fileExtension === "xltx" ||
                                                            fileExtension === "xls" ? (
                                                            <img src={assetImages.XlsImg} alt="" />
                                                          ) : (
                                                            <img src={assetImages.DocImg} alt="" />
                                                          )}
                                                        </div>
                                                        <div className="chat_upload_caption">
                                                          <p>
                                                            <span>{file.name}</span>
                                                          </p>
                                                        </div>
                                                        {index2 === 5 && chatData.uploadedchatfiles.length - 6 !== 0 ? (
                                                          <div className="overlay_text position-absolute">
                                                            <Link
                                                              to="#"
                                                              className="d-flex h-100 w-100 align-items-center justify-content-center"
                                                            >
                                                              {chatData.uploadedchatfiles.length - 6}+
                                                            </Link>
                                                          </div>
                                                        ) : null}
                                                      </div>
                                                    </div>
                                                  );
                                                })}
                                              </div>
                                            </div>
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="msg_text dropdown">
                                      {chatData.parent ? (
                                        <div className="replied_msg_row">
                                          <ChatMessageContent
                                            message={chatData.parentchatmessage}
                                          />
                                          <span>By {chatData.parentchatsender}</span>
                                        </div>
                                      ) : null}
                                      <Link
                                        to="#"
                                        data-bs-toggle="dropdown"
                                        className="chat_expand_arws"
                                        aria-expanded="false"
                                      >
                                        <span className="material-icons-outlined">
                                          expand_more
                                        </span>
                                      </Link>
                                      <ul className="dropdown-menu">
                                        <li>
                                          <Link
                                            to="#"
                                            onClick={() => {
                                              chatInputRef.current.focus();
                                              if (chatData.flaggedlanguage && chatData.flaggedlanguage !== "") {
                                                setreplyMessage(chatData.flaggeddescription);
                                              } else {
                                                setreplyMessage(chatData.description);
                                              }
                                              setreplyUserName(conversationName);
                                              setparentMessageId(chatData._id);
                                            }}
                                            className="d-flex align-items-center gap-2"
                                          >
                                            <i className="material-symbols-outlined fs-md">
                                              reply
                                            </i>
                                            <span>Reply</span>
                                          </Link>
                                        </li>
                                      </ul>
                                      <div className="replied_msg_text">
                                        <ChatMessageContent
                                          message={chatData.description}
                                        />
                                        {chatData.flaggedlanguage && chatData.flaggedlanguage !== "" ? (
                                          <div
                                            className="recever_translatebx d-flex gap-1 p-2 rounded-10 bg-white overflow-hidden mt-2"
                                            style={{ borderLeft: "2px solid #3191FF" }}
                                          >
                                            <span
                                              className="d-block rounded-circle overflow-hidden"
                                              style={{
                                                height: "20px",
                                                width: "20px",
                                                minWidth: "20px",
                                              }}
                                            >
                                              <ChatMessageFlag
                                                language={chatData.flaggedlanguage}
                                              />
                                            </span>
                                            <ChatMessageContent
                                              message={chatData.flaggeddescription}
                                            />
                                          </div>
                                        ) : null}
                                        {chatData.uploadedchatfiles && chatData.uploadedchatfiles.length > 0 ? (
                                          <div className="upload_innrBody msg_uploadbody">
                                            <div className="upload_outer">
                                              <div className="row">
                                                {chatData.uploadedchatfiles.slice(0, 6).map((file, index2) => {
                                                  const fileExtension = file.path.split(".").pop();
                                                  const fileObj = {
                                                    ...file,
                                                    path: url.SERVER_URL + file.path,
                                                  };
                                                  return (
                                                    <div
                                                      className="col"
                                                      key={index2}
                                                      style={{ cursor: "pointer" }}
                                                      onClick={() => {
                                                        fileDownloadHandler(fileObj);
                                                      }}
                                                    >
                                                      <div className="chat_uploadItem position-relative">
                                                        <div className="chat_upload_pic">
                                                          {fileExtension === "jpg" ||
                                                          fileExtension === "jpeg" ||
                                                          fileExtension === "png" ? (
                                                            <img
                                                              src={url.SERVER_URL + file.path}
                                                              alt=""
                                                            />
                                                          ) : fileExtension === "pdf" ? (
                                                            <img src={assetImages.PdfImg} alt="" />
                                                          ) : fileExtension === "xlsx" ||
                                                            fileExtension === "xlsm" ||
                                                            fileExtension === "xlsb" ||
                                                            fileExtension === "xltx" ||
                                                            fileExtension === "xls" ? (
                                                            <img src={assetImages.XlsImg} alt="" />
                                                          ) : (
                                                            <img src={assetImages.DocImg} alt="" />
                                                          )}
                                                        </div>
                                                        <div className="chat_upload_caption">
                                                          <p>
                                                            <span>{file.name}</span>
                                                          </p>
                                                        </div>
                                                        {index2 === 5 && chatData.uploadedchatfiles.length - 6 !== 0 ? (
                                                          <div className="overlay_text position-absolute">
                                                            <Link
                                                              to="#"
                                                              className="d-flex h-100 w-100 align-items-center justify-content-center"
                                                            >
                                                              {chatData.uploadedchatfiles.length - 6}+
                                                            </Link>
                                                          </div>
                                                        ) : null}
                                                      </div>
                                                    </div>
                                                  );
                                                })}
                                              </div>
                                            </div>
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            ))}
                            <div ref={messagesEndRef} name="scrollRefDiv" />
                          </div>
                        </div>
                      </div>
                    )}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatHistoryComponent;