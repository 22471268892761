import React from "react";

const RecomnadSuccessModal = () => {
  return (
    <div className="post_modal success_greetings_mdl">
      <div
        className="modal fade"
        id="recomnd_success_mdl"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="gretings_icon">
                <span className="material-icons-outlined"> task_alt </span>
              </div>
              <div className="gretings_hdng">
                <h3>Recomandation saved Successfully</h3>
              </div>
              <div className="post_btn post_bnts_outer">
                <button className="btn" data-bs-dismiss="modal">
                  Close Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecomnadSuccessModal;
