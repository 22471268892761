import React from "react";
import { Link } from "react-router-dom";

const GroupListPlaceHolder = () => {
  return (
    <div className="groups_item">
      <div className="groupsbx_outer">
        <div className="groups_textbx_pnl">
          <div className="event_hdng bookmark_hdng">
            <h3>
              <span className="placeholder" style={{ width: "250px" }}></span>
            </h3>
            <div className="bookmarks_grps">
              <Link to="#">
                <span className="placeholder" style={{ width: "30px" }}></span>
              </Link>
            </div>
          </div>
          <div className="event_tags mb-3">
            <span className="placeholder" style={{ width: "50px" }}></span>
            <span className="placeholder" style={{ width: "50px" }}></span>
          </div>
          <div className="grp_pic_txtInnr">
            <div className="left_group" style={{ width: "60%" }}>
              <div className="event_para">
                <p>
                  <span
                    className="placeholder"
                    style={{ width: "100%", display: "block" }}
                  ></span>
                </p>
              </div>
              <div className="frst_user_text">
                <p>
                  <span
                    className="placeholder"
                    style={{
                      width: "40%",
                      display: "block",
                      color: "#AAB3BD",
                    }}
                  ></span>
                </p>
              </div>
              <div className="event_user">
                <ul className="list_stye_none">
                  <li>
                    <span
                      className="placeholder rounded-circle"
                      style={{ width: "40px", height: "40px" }}
                    ></span>
                  </li>
                  <li>
                    <span
                      className="placeholder rounded-circle"
                      style={{ width: "40px", height: "40px" }}
                    ></span>
                  </li>
                  <li>
                    <span
                      className="placeholder rounded-circle"
                      style={{ width: "40px", height: "40px" }}
                    ></span>
                  </li>
                  <li>
                    <span
                      className="placeholder rounded-circle"
                      style={{ width: "40px", height: "40px" }}
                    ></span>
                  </li>
                  <li>
                    <span
                      className="placeholder rounded-circle"
                      style={{ width: "40px", height: "40px" }}
                    ></span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="groups_imgbx_pnl">
              <div className="grp_picOuter">
                <span
                  className="placeholder"
                  style={{ width: "130px", height: "130px" }}
                ></span>
              </div>
            </div>
          </div>
          <div className="details_btn">
            <span
              className="placeholder"
              style={{
                width: "110px",
                height: "40px",
                borderRadius: "30px",
              }}
            ></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupListPlaceHolder;
