import React from 'react';
import { Link } from 'react-router-dom';

const ExperienceRightPanel = () => {
  return (
    <div className="pnl_mdlpnl">
      <div className="profile_information white_shadow_bx">
        <div className="info_hdng info_mainhdng">
          <h2>Experience</h2>
          <Link to="#" className="add_anotherBtn">
            <span>Add Another</span>
            <i className="material-icons-outlined">add_circle_outline</i>
          </Link>
        </div>
        <div className="experience_bxOuter">
          <div className="experience_bxItem">
            <div className="info_hdng">
              <h4>Experience 1</h4>
              <Link to="#" className="delete_bxbtn">
                <i className="material-icons-outlined">delete</i>
              </Link>
            </div>
            <div className="profile_info_pnl">
              <div className="profile_info_row">
                <label>Company</label>
                <div className="profile_inner_info">
                  <div className="left_profile_info">
                    <input
                      type="text"
                      value=""
                      className="fill_input"
                      placeholder="Enter company name"
                    />
                  </div>
                </div>
              </div>
              <div className="profile_info_row">
                <label>Position</label>
                <div className="profile_inner_info">
                  <div className="left_profile_info">
                    <div className="select_bx">
                      <select className="nosearch_select ">
                        <option>UX Designer</option>
                        <option>UI Developer</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="profile_info_row">
                <label>Person in Charge</label>
                <div className="profile_inner_info">
                  <div className="left_profile_info">
                    <input
                      type="text"
                      value=""
                      className="fill_input"
                      placeholder="Person in Charge"
                    />
                  </div>
                </div>
              </div>
              <div className="profile_info_row">
                <label>CTC (Annual)</label>
                <div className="profile_inner_info">
                  <div className="left_profile_info">
                    <input
                      type="number"
                      value=""
                      className="fill_input"
                      placeholder="Enter CTC"
                    />
                  </div>
                </div>
              </div>
              <div className="profile_info_row">
                <label>About</label>
                <div className="profile_inner_info">
                  <div className="left_profile_info">
                    <input
                      type="text"
                      value=""
                      className="fill_input"
                      placeholder="About company"
                    />
                  </div>
                </div>
              </div>
              <div className="profile_info_row">
                <label>Joining Date</label>
                <div className="profile_inner_info">
                  <div className="left_profile_info">
                    <input
                      type="date"
                      value=""
                      className="fill_input"
                      placeholder="  DD-MM-YYYY"
                    />
                  </div>
                </div>
              </div>
              <div className="profile_info_row">
                <label>Release Date</label>
                <div className="profile_inner_info">
                  <div className="left_profile_info">
                    <input
                      type="date"
                      value=""
                      className="fill_input"
                      placeholder="  DD-MM-YYYY"
                    />
                  </div>
                  <div className="right_edit_icons">
                    <label className="radio-check">
                      <input type="checkbox" />
                      <span></span>
                      <p>Currently Working</p>
                    </label>
                  </div>
                </div>
              </div>
              <div className="profile_info_row">
                <label>Linkedin URL</label>
                <div className="profile_inner_info">
                  <div className="left_profile_info">
                    <input
                      type="text"
                      value=""
                      className="fill_input"
                      placeholder="Pest Link"
                    />
                  </div>
                </div>
              </div>
              <div className="profile_info_row">
                <label>Linkedin ID</label>
                <div className="profile_inner_info">
                  <div className="left_profile_info">
                    <input
                      type="text"
                      value=""
                      className="fill_input"
                      placeholder="Enter ID"
                    />
                  </div>
                </div>
              </div>
              <div className="profile_info_row">
                <label>Locations</label>
                <div className="profile_inner_info">
                  <div className="left_profile_info">
                    <input
                      type="text"
                      value=""
                      className="fill_input"
                      placeholder="Enter address"
                    />
                  </div>
                  <div className="right_edit_icons">
                    <Link to="#" className="gps_link">
                      <span className="material-icons-outlined">gps_fixed</span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="profile_info_row">
                <label>Company URL</label>
                <div className="profile_inner_info">
                  <div className="left_profile_info">
                    <input
                      type="text"
                      value=""
                      className="fill_input"
                      placeholder="Enter URL"
                    />
                  </div>
                </div>
              </div>
              <div className="profile_info_row">
                <label>Company Domain</label>
                <div className="profile_inner_info">
                  <div className="left_profile_info">
                    <input
                      type="text"
                      value=""
                      className="fill_input"
                      placeholder="Enter Domain"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="experience_bxItem">
            <div className="info_hdng">
              <h4>Experience 2</h4>
              <Link to="#" className="delete_bxbtn">
                <i className="material-icons-outlined">delete</i>
              </Link>
            </div>
            <div className="profile_info_pnl">
              <div className="profile_info_row">
                <label>Company</label>
                <div className="profile_inner_info">
                  <div className="left_profile_info">
                    <input
                      type="text"
                      value=""
                      className="fill_input"
                      placeholder="Enter company name"
                    />
                  </div>
                </div>
              </div>
              <div className="profile_info_row">
                <label>Position</label>
                <div className="profile_inner_info">
                  <div className="left_profile_info">
                    <div className="select_bx">
                      <select className="nosearch_select ">
                        <option>UX Designer</option>
                        <option>UI Developer</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="profile_info_row">
                <label>Person in Charge</label>
                <div className="profile_inner_info">
                  <div className="left_profile_info">
                    <input
                      type="text"
                      value=""
                      className="fill_input"
                      placeholder="Person in Charge"
                    />
                  </div>
                </div>
              </div>
              <div className="profile_info_row">
                <label>CTC (Annual)</label>
                <div className="profile_inner_info">
                  <div className="left_profile_info">
                    <input
                      type="number"
                      value=""
                      className="fill_input"
                      placeholder="Enter CTC"
                    />
                  </div>
                </div>
              </div>
              <div className="profile_info_row">
                <label>About</label>
                <div className="profile_inner_info">
                  <div className="left_profile_info">
                    <input
                      type="text"
                      value=""
                      className="fill_input"
                      placeholder="About company"
                    />
                  </div>
                </div>
              </div>
              <div className="profile_info_row">
                <label>Joining Date</label>
                <div className="profile_inner_info">
                  <div className="left_profile_info">
                    <input
                      type="date"
                      value=""
                      className="fill_input"
                      placeholder="  DD-MM-YYYY"
                    />
                  </div>
                </div>
              </div>
              <div className="profile_info_row">
                <label>Release Date</label>
                <div className="profile_inner_info">
                  <div className="left_profile_info">
                    <input
                      type="date"
                      value=""
                      className="fill_input"
                      placeholder="  DD-MM-YYYY"
                    />
                  </div>
                  <div className="right_edit_icons">
                    <label className="radio-check">
                      <input type="checkbox" />
                      <span></span>
                      <p>Currently Working</p>
                    </label>
                  </div>
                </div>
              </div>
              <div className="profile_info_row">
                <label>Linkedin URL</label>
                <div className="profile_inner_info">
                  <div className="left_profile_info">
                    <input
                      type="text"
                      value=""
                      className="fill_input"
                      placeholder="Pest Link"
                    />
                  </div>
                </div>
              </div>
              <div className="profile_info_row">
                <label>Linkedin ID</label>
                <div className="profile_inner_info">
                  <div className="left_profile_info">
                    <input
                      type="text"
                      value=""
                      className="fill_input"
                      placeholder="Enter ID"
                    />
                  </div>
                </div>
              </div>
              <div className="profile_info_row">
                <label>Locations</label>
                <div className="profile_inner_info">
                  <div className="left_profile_info">
                    <input
                      type="text"
                      value=""
                      className="fill_input"
                      placeholder="Enter address"
                    />
                  </div>
                  <div className="right_edit_icons">
                    <Link to="#" className="gps_link">
                      <span className="material-icons-outlined">gps_fixed</span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="profile_info_row">
                <label>Company URL</label>
                <div className="profile_inner_info">
                  <div className="left_profile_info">
                    <input
                      type="text"
                      value=""
                      className="fill_input"
                      placeholder="Enter URL"
                    />
                  </div>
                </div>
              </div>
              <div className="profile_info_row">
                <label>Company Domain</label>
                <div className="profile_inner_info">
                  <div className="left_profile_info">
                    <input
                      type="text"
                      value=""
                      className="fill_input"
                      placeholder="Enter Domain"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExperienceRightPanel;
