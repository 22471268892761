/* eslint-disable */
import { assetImages } from "constants";
import React, { useState } from "react";
import { Link } from "react-router-dom";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { postData } from "utils/Gateway";
import { ecosystemSlug } from "Config/Config";

const KitDigitalRequestModal = ({
  kitName,
  setKitName,
  serviceRate,
  setServiceRate,
}) => {
  const [name, setname] = useState("");
  const [surName, setsurName] = useState("");
  const [email, setemail] = useState("");
  const [message, setmessage] = useState("");
  const [isSending, setisSending] = useState(false);

  //function submit contact data
  const submitRequestHandler = async () => {
    if (name == "" || surName == "" || email == "") {
      return;
    } else {
      try {
        setisSending(true);

        let contactData = {
          kitdigital: kitName,
          name,
          surname: surName,
          email,
          customercomment: message,
          source: "Request Information",
        };

        let requestUrl =
          url.API_BASE_URL +
          url.API_SAVE_GUEST_LEAD +
          `?ecosystemslug=${ecosystemSlug}`;

        const response = await postData(requestUrl, contactData);

        // console.log(response);

        if (response.status) {
          setisSending(false);

          resetHandler();

          //hide kit_digital modal
          let kitDigitalModal = document.querySelector("#kit_digital");
          let modal = bootstrap.Modal.getInstance(kitDigitalModal);
          modal.hide();

          //show otpconfirm modal
          let myModal = new bootstrap.Modal(
            document.getElementById("success_modal")
          );
          myModal.show();
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  //reset function
  const resetHandler = () => {
    setKitName("");
    setServiceRate("");
    setname("");
    setsurName("");
    setemail("");
    setmessage("");
  };

  return (
    <div className="digital_modal">
      <div
        className="modal fade"
        id="kit_digital"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={resetHandler}
              >
                <i className="material-icons-outlined"> close </i>
              </button>
            </div>
            <div className="modal-body">
              <div
                className="digital_left"
                style={{ backgroundImage: `url(${assetImages.modalBg})` }}
              >
                <h4>{kitName}</h4>
                <h5>Payable Amount</h5>
                <h2>{serviceRate}€</h2>
              </div>
              <div className="digital_right">
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="input_fill">
                        <input
                          type="text"
                          value={name}
                          onChange={(e) => setname(e.target.value)}
                          className="form-control"
                          placeholder="First Name"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input_fill">
                        <input
                          type="text"
                          value={surName}
                          onChange={(e) => setsurName(e.target.value)}
                          className="form-control"
                          placeholder="Last Name"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="input_fill">
                        <input
                          type="email"
                          value={email}
                          onChange={(e) => setemail(e.target.value)}
                          className="form-control"
                          placeholder="Enter Email"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="input_fill description_fill">
                        <textarea
                          className="form-control"
                          placeholder="Enter Message"
                          value={message}
                          onChange={(e) => setmessage(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="difital_submit_btn">
                        {/* <button type="submit" className="btn">Submit Request</button> */}
                        <button
                          type="button"
                          onClick={submitRequestHandler}
                          className="btn"
                          disabled={isSending ? true : false}
                          style={{
                            cursor: isSending ? "not-allowed" : "pointer",
                          }}
                        >
                          Submit Request
                          {isSending && (
                            <div
                              className="mx-2 spinner-border spinner-border-sm"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KitDigitalRequestModal;
