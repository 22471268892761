import React from 'react';
import PasswordResetLeftPanel from '../PasswordResetLeftPanel/PasswordResetLeftPanel';
import PasswordResetRightPanel from '../PasswordResetRightPanel/PasswordResetRightPanel';

const PasswordResetBody = () => {
  return (
    <section className="feed_pnltotal">
      <div className="container-fluid">
        <div className="pnl_outer profile_pnlOuter">
          <PasswordResetLeftPanel moduleName="password" />
          <PasswordResetRightPanel />
        </div>
      </div>
    </section>
  );
};

export default PasswordResetBody;
