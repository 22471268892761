import React from "react";
import BlogPlaceHolderCard from "./BlogPlaceHolderCard";

const BlogAllListPlaceHolder = () => {
  const listGlowCount = 5;

  const cardGlowCount = 3;
  return (
    <div className="landing_outer">
      {Array(listGlowCount)
        .fill()
        .map((item, index) => {
          return (
            <div className="landing_row" key={index}>
              <div className="landing_hdng_row">
                <div className="page_hdng" style={{ width: "50%" }}>
                  <h2 style={{ width: "100%" }}>
                    <span
                      className="placeholder"
                      style={{ width: "40%", height: "20px" }}
                    ></span>
                  </h2>
                </div>
                <div className="details_arw">
                  <span
                    className="placeholder rounded-circle"
                    style={{ width: "60px", height: "60px" }}
                  ></span>
                </div>
              </div>
              <div className="blog_listing">
                <div className="row">
                  {Array.from(
                    { length: cardGlowCount },
                    (_, index) => index
                  ).map((item, index) => {
                    return (
                      <div className="col-lg-4 col-md-6" key={index}>
                        <BlogPlaceHolderCard />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default BlogAllListPlaceHolder;
