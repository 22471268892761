/* eslint-disable */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const PortfolioModal = ({ userPortfolioLinks, setuserPortfolioLinks }) => {
  const linkTemplate = {
    linktext: "",
  };

  const [linkBlock, setlinkBlock] = useState([]);

  const addNewLinkBlock = () => {
    setlinkBlock([...linkBlock, linkTemplate]);
  };

  const deleteLinkBlock = (index) => {
    const deleteLinkArr = [...linkBlock];
    deleteLinkArr.splice(index, 1);
    setlinkBlock(deleteLinkArr);
  };

  //function for change survey select
  const linkInputHandler = (index, field, value) => {
    const updatedFormValues = linkBlock.map((block, i) =>
      index == i ? Object.assign(block, { [field]: value }) : block
    );

    setlinkBlock(updatedFormValues);
  };

  const updatedPortfolioBlock = (updatedLinks) => {
    setlinkBlock(updatedLinks);
  };

  const saveInfoHandler = () => {
    if (linkBlock.length > 0) {
      setuserPortfolioLinks(linkBlock);
    } else {
      setuserPortfolioLinks([]);
    }

    resetHandler();

    let loginModal = document.querySelector("#portfolio_modal");
    let modal = bootstrap.Modal.getInstance(loginModal);
    modal.hide();
  };

  const resetHandler = () => {
    setlinkBlock([]);
  };

  useEffect(() => {
    if (userPortfolioLinks.length > 0) {
      updatedPortfolioBlock(userPortfolioLinks);
    }
  }, [userPortfolioLinks]);

  return (
    <div className="process_modal builder_modal">
      <div className="modal fade show" id="portfolio_modal">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <div className="signin_hdng text-left">
                <h3>Portfolio Links</h3>
                <h5>Your Range of Portfolio</h5>
              </div>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
                // onClick={resetHandler}
              >
                <i className="material-icons-outlined">close </i>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="form_innr">
                  <div className="form-group">
                    <label>Links</label>
                    {linkBlock.map((linkData, index) => {
                      return (
                        <div className="d-flex gap-1 mb-2" key={index}>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Links"
                            value={linkData.linktext}
                            onChange={(e) => {
                              linkInputHandler(
                                index,
                                "linktext",
                                e.target.value
                              );
                            }}
                          />
                          <div className="adnew_btn">
                            <Link
                              onClick={() => {
                                deleteLinkBlock(index);
                              }}
                              to="#"
                              className="d-inline-flex align-items-center justify-content-center gap-1"
                            >
                              <i className="material-icons-outlined">delete</i>
                            </Link>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="form-group">
                    <div className="adnew_btn">
                      <Link
                        to="#"
                        className="d-inline-flex align-items-center justify-content-center gap-1"
                        onClick={addNewLinkBlock}
                      >
                        <i className="material-icons-outlined">add </i>
                        <span>Add New</span>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="process_btns_outer d-flex align-items-center justify-content-between mt-3">
                  <button
                    onClick={resetHandler}
                    data-bs-dismiss="modal"
                    className="btn gray"
                  >
                    Cancel
                  </button>
                  <button className="btn" onClick={saveInfoHandler}>
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortfolioModal;
