/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { postData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import { assetImages } from "constants";

//import context
import { GlobalProvider } from "context/GlobalContext";

const AddToGroupModal = ({
  myContacts = [],
  afterModalClose = () => {},
  groupId = null,
}) => {
  const token = localStorage.getItem("token");

  const { setReloadConversationLeftPanel } = useContext(GlobalProvider);

  const [searchValue, setSearchValue] = useState("");

  const [selectedContacts, setSelectedContacts] = useState([]); // state to keep track of selected contacts
  const [isAdding, setIdAdding] = useState(false);

  const [validation, setvalidation] = useState({
    groupMemberWarning: false,
  });

  //function for set filter leads
  const filterSearchContacts = () => {
    return myContacts.filter((contact) => {
      const searchTerm = searchValue.toLowerCase().trim();
      const nameMatch = contact.contactname.toLowerCase().includes(searchTerm);

      return nameMatch;
    });
  };

  //function for select contact with toggle
  const contactSelectionHandler = (contact, index) => {
    setvalidation((prevState) => ({
      ...prevState,
      groupMemberWarning: false,
    }));

    setSelectedContacts((prevSelectedContacts) => {
      const isAlreadySelected = prevSelectedContacts.some(
        (selectedContact) =>
          selectedContact.toString() === contact.userid.toString()
      );

      if (isAlreadySelected) {
        // If already selected, remove it
        return prevSelectedContacts.filter(
          (selectedContact) =>
            selectedContact.toString() !== contact.userid.toString()
        );
      } else {
        // If not selected, add it
        return [...prevSelectedContacts, contact.userid];
      }
    });
  };

  //function for validation
  const validationHandler = () => {
    let isValid = true;

    if (selectedContacts.length === 0) {
      setvalidation((prevState) => ({
        ...prevState,
        groupMemberWarning: true,
      }));

      isValid = false;
    }

    return isValid;
  };

  //function for create group
  const addToGroupHandler = async () => {
    if (validationHandler()) {
      try {
        setIdAdding(true);

        let chatData = {
          group: groupId,
          contactids: selectedContacts,
        };

        console.log(chatData);

        let requestURL =
          url.API_BASE_URL + url.API_ADD_NEW_GROUP_MEMBER + `?token=${token}`;

        // console.log(requestURL);

        const response = await postData(requestURL, chatData);

        setIdAdding(false);

        console.log("group member modal response----->", response);

        if (response.status) {
          resetHandler();
          //hide modal
          let myModal = document.querySelector("#add_to_group_people_mdl");
          let modal = bootstrap.Modal.getInstance(myModal);
          modal.hide();
          afterModalClose();
          setReloadConversationLeftPanel(true);
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  const resetHandler = () => {
    setSelectedContacts([]);

    setvalidation({
      groupMemberWarning: false,
    });
  };

  return (
    <div className="chat_modalbx">
      <div
        className="modal fade"
        id="add_to_group_people_mdl"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header mdl_hdng">
              {/* <!-- <h5 className="modal-title">Add to Group</h5>
                  <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                    <span className="material-icons-round">close </span>
                  </button> --> */}
            </div>

            <div className="modal-body">
              <div className="group_srchfill">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search for people"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </div>
              <div className="group_innrScroll">
                {myContacts.map((contact, index) => {
                  const isSelected = selectedContacts.some(
                    (selectedContact) =>
                      selectedContact.toString() === contact.userid.toString()
                  );

                  return (
                    <div
                      key={index}
                      className={
                        filterSearchContacts().includes(contact)
                          ? "chat_usrRow cursor-pointer"
                          : "d-none"
                      }
                    >
                      <Link
                        to="#"
                        onClick={() => {
                          contactSelectionHandler(contact, index);
                        }}
                      >
                        <div className="usr_lftPnl">
                          <div className="chatting_user">
                            <img
                              src={
                                contact.contactimage === ""
                                  ? assetImages.defaultUser
                                  : url.SERVER_URL + contact.contactimage
                              }
                              alt=""
                            />
                          </div>
                          <div className="user_name">
                            <h3>{contact.contactname}</h3>
                          </div>
                        </div>
                        {isSelected ? (
                          <div className="members_check blue_bullet">
                            <span></span>
                          </div>
                        ) : null}
                      </Link>
                    </div>
                  );
                })}
              </div>

              {validation.groupMemberWarning && (
                <p className="error m-3">
                  <span className="material-icons-outlined me-1">info</span>
                  Please select one contact
                </p>
              )}
              {/* <div className="group_btnOuter">
                <ul>
                  <li className="w_50">
                    <Link to="#" className="ornage_btn" data-bs-dismiss="modal">
                      <i className="material-icons-round">check_circle</i>
                      <span>Done</span>
                    </Link>
                  </li>
                  <li className="w_50">
                    <Link to="#" className="" data-bs-dismiss="modal">
                      <i className="material-icons-round">close</i>
                      <span>Exit</span>
                    </Link>
                  </li>
                </ul>
              </div> */}

              <div className="row px-3 my-3">
                <div className="col d-flex align-items-center justify-content-center">
                  <button
                    className="btn btn-primary w-100 rounded"
                    onClick={addToGroupHandler}
                    disabled={isAdding ? true : false}
                    style={{
                      cursor: isAdding ? "not-allowed" : "pointer",
                    }}
                  >
                    {isAdding ? (
                      <div
                        className="mx-2 spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      <i className="material-icons-round">check_circle</i>
                    )}

                    <span>Add</span>
                  </button>
                </div>
                <div className="col">
                  <button
                    className="btn bg-white w-100 rounded text-gray"
                    data-bs-dismiss="modal"
                    onClick={resetHandler}
                  >
                    <i className="material-icons-round">close</i>
                    <span>Exit</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddToGroupModal;
