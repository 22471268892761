import { Link } from "react-router-dom";
import { assetImages } from "constants";

import * as url from "helper/UrlHelper";

const JobDetailsRightPanel = ({ similarJobList = [] }) => {
  return (
    <div className="pnl_rightpnl">
      <div className="white_shadow_bx">
        <div className="contact_header">
          <div className="post_hdng">
            <h4>Similar Jobs</h4>
          </div>
          <div className="contacts_srch_right"></div>
        </div>
        <div className="similar_postpnl">
          {similarJobList.map((job, index) => {
            return (
              <div className="similar_post_row" key={index}>
                <div className="top_company_row">
                  <div className="company_logotxt">
                    <Link to={`/jobs/details/${job._id}`}>
                      <span>
                        <img
                          className="rounded-circle"
                          src={
                            job.companylogopath === ""
                              ? assetImages.defaultUser
                              : url.SERVER_URL + job.companylogopath
                          }
                          alt=""
                          height={35}
                        />
                      </span>
                    </Link>
                    <Link to={`/jobs/details/${job._id}`}>
                      <span className="ms-2">
                        {job.companynamestring.slice(0, 35)}
                      </span>
                    </Link>
                  </div>
                </div>
                <div className="event_hdng">
                  <h3>
                    <Link
                      to={`/jobs/details/${job._id}`}
                      className="text-decoration-none text-black"
                    >
                      {job.name}
                    </Link>
                  </h3>
                  <h4>
                    €{job.minsalary} - €{job.maxsalary}
                  </h4>
                  <h5>
                    <i className="material-icons-outlined"> event_note </i>
                    <span>{job.createddate}</span>
                  </h5>
                </div>
                {/* <div className="event_tags">
                  <ul className="list_stye_none">
                    <li>
                      <i className="material-icons-outlined"> schedule</i>
                      <span>{job.jobtype}</span>
                    </li>
                  </ul>
                </div> */}
                <div className="event_tags">
                  <ul className="list_stye_none">
                    <li>
                      <i className="material-icons-outlined ornge_icon">star</i>
                      <span className="rating">
                        {job.ratingtotal} <em>({job.reviewtotal})</em>
                      </span>
                    </li>
                    <li>
                      <i className="material-icons-outlined">place</i>
                      <span>
                        {job.city}, {job.country}
                      </span>
                    </li>
                  </ul>
                </div>

                <div className="event_para">
                  <p>{job.summary.slice(0, 100)}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default JobDetailsRightPanel;
