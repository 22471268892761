import { assetImages } from "constants";
import { Link } from "react-router-dom";
import * as url from "helper/UrlHelper";

const SurveyRightPanel = ({
  surveyInviteDetailsLoading,
  surveyInviteeName,
  surveyInviterImage,
  surveyInviterName,
  surveyTitle,
  getSurveyQuestions,
}) => {
  return (
    <div className="right_survey bg-white px-5">
      {/* <!-- ========== Start mobile overlay Section ========== --> */}
      <div
        className="overlay"
        onClick={() => {
          document.body.classList.toggle("open-review");
        }}
      ></div>
      {/* <!-- ========== End mobile overlay Section ========== --> */}

      {/* <!-- ========== Start mobile button for display left Section ========== --> */}
      <div className="open_rvw_trigger bg-white p-3 d-flex justify-content-end">
        <Link
          to="#"
          className="d-inline-flex align-items-center gap-2 text-black fs-md"
          onClick={() => {
            document.body.classList.toggle("open-review");
          }}
        >
          <i className="material-icons-outlined fs-lg">filter_list</i>
          <span>Show Review</span>
        </Link>
      </div>
      {/* <!-- ========== End mobile button for display left Section ========== --> */}

      {/* <!-- ========== Start survey invitee and inviter Section ========== --> */}
      {surveyInviteDetailsLoading ? (
        <div className="right_rvw_inr d-flex align-items-center justify-content-center">
          <div className="medium_container">
            <div className="process_form_area">
              <div className="process_hdng text-center">
                <span className="d-block mb-3">
                  <span
                    className="placeholder"
                    style={{ width: "100px", height: "100px" }}
                  ></span>
                </span>
                <span
                  className="placeholder d-table mx-auto"
                  style={{ width: "150px", height: "30px" }}
                ></span>
              </div>
              <div className="survey_subheading text-center mb-4">
                <span
                  className="placeholder d-table mx-auto mb-3"
                  style={{ width: "350px", height: "22px" }}
                ></span>
                <div className="kitchen_user_row d-flex align-items-center gap-1 justify-content-center">
                  <span
                    className="placeholder rounded-90"
                    style={{ width: "150px", height: "44px" }}
                  ></span>
                </div>
              </div>
              <div className=" text-center">
                <span
                  className="placeholder rounded-90"
                  style={{ width: "170px", height: "60px" }}
                ></span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="right_rvw_inr d-flex align-items-center justify-content-center">
          <div className="medium_container">
            <div className="process_form_area">
              <div className="process_hdng text-center">
                <span className="d-block mb-3">
                  <img src={assetImages.helloWaveSvg} alt="" />
                </span>
                <h3>Hello {surveyInviteeName}</h3>
              </div>
              <div className="survey_subheading text-center mb-4">
                {/* <!-- ========== Start survey title Section ========== --> */}
                <h4 className="text-black fs-xl mb-3">
                  Start Survey on{" "}
                  <span className="fw-semibold">{surveyTitle}</span>
                </h4>

                {/* <!-- ========== End survey title Section ========== --> */}

                <div className="kitchen_user_row d-flex align-items-center gap-1 justify-content-center">
                  <h6 className="fs-sm text-black-gray">Invited by</h6>
                  {/* <!-- ========== Start inviter information Section ========== --> */}
                  <div className="survey_user d-flex align-items-center gap-2 bg-light rounded-90 px-3 py-2 justify-content-center">
                    <span className="d-block survey_usr_pic overflow-hidden rounded-circle">
                      <img
                        src={
                          surveyInviterImage === ""
                            ? assetImages.defaultUser
                            : url.SERVER_URL + surveyInviterImage
                        }
                        alt=""
                      />
                    </span>
                    <h5 className="fs-sm text-black">{surveyInviterName}</h5>
                  </div>
                  {/* <!-- ========== End inviter information Section ========== --> */}
                </div>
              </div>
              {/* <!-- ========== Start survey modal button Section ========== --> */}
              <div className=" text-center">
                <Link
                  to="#"
                  className="btn btn-primary"
                  onClick={getSurveyQuestions}
                >
                  Start Survey{" "}
                </Link>
              </div>
              {/* <!-- ========== End survey modal button Section ========== --> */}
            </div>
          </div>
        </div>
      )}
      {/* <!-- ========== End survey invitee and inviter Section ========== --> */}
    </div>
  );
};

export default SurveyRightPanel;
