/* eslint-disable */
import { useState, useEffect } from "react";

const PersonalInfoModal = ({
  setErrorMessage,
  setCvTitle,
  setuserName,
  setuserSurname,
  setuserEmail,
  setuserPosition,
  setuserPhone,
  setUserExperienceInYears,
  cvTitle,
  userName,
  userSurname,
  userEmail,
  userPosition,
  userPhone,
  userExperienceInYears
}) => {
  const [title, settitle] = useState("");
  const [name, setname] = useState("");
  const [surname, setsurname] = useState("");
  const [email, setemail] = useState("");
  const [position, setposition] = useState("");
  const [phone, setphone] = useState("");
  const [experienceInYears, setExperienceInYears] = useState("");

  const saveInfoHandler = () => {
    setCvTitle(title);

    setuserName(name);

    setuserSurname(surname);

    setuserEmail(email);

    setuserPosition(position);

    setuserPhone(phone);

    setUserExperienceInYears(experienceInYears);

    // resetHandler();

    let loginModal = document.querySelector("#personal_info_modal");
    let modal = bootstrap.Modal.getInstance(loginModal);
    modal.hide();
  };

  const resetHandler = () => {
    settitle("");
    setname("");
    setsurname("");
    setemail("");
    setposition("");
    setphone("");
    setExperienceInYears("");
  };

  useEffect(() => {
    if (cvTitle && cvTitle !== "") {
      settitle(cvTitle);
    } else {
      settitle(userName+" "+userSurname);
    }

    if (userName && userName !== "") {
      setname(userName);
    }

    if (userSurname && userSurname !== "") {
      setsurname(userSurname);
    }

    if (userEmail && userEmail !== "") {
      setemail(userEmail);
    }

    if (userPosition && userPosition !== "") {
      setposition(userPosition);
    }

    if (userPhone && userPhone !== "") {
      setphone(userPhone);
    }

    if (userExperienceInYears && userExperienceInYears !== "") {
      setExperienceInYears(userExperienceInYears);
    }
  }, [cvTitle, userName, userSurname, userEmail, userPosition, userPhone, userExperienceInYears]);

  return (
    <div className="process_modal builder_modal">
      <div className="modal fade show" id="personal_info_modal">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <div className="signin_hdng text-left">
                <h3>Personal Details</h3>
                <h5>Give your information</h5>
              </div>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
                // onClick={resetHandler}
              >
                <i className="material-icons-outlined">close </i>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="form_innr">
                  {/* cv title */}
                  <div className="form-group">
                    <label>
                      CV Title <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter CV title"
                      value={title}
                      onChange={(e) => {
                        settitle(e.target.value);
                        setErrorMessage("");
                      }}
                    />
                  </div>

                  {/* name and surname */}
                  <div className="form-group">
                    <div className="name_row d-flex gap-2">
                      <div className="w-50">
                        <label>
                          First Name <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="First Name"
                          value={name}
                          onChange={(e) => {
                            setname(e.target.value);
                          }}
                        />
                      </div>
                      <div className="w-50">
                        <label>Last Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Last Name"
                          value={surname}
                          onChange={(e) => {
                            setsurname(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label>
                      Email <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter email"
                      value={email}
                      onChange={(e) => {
                        setemail(e.target.value);
                      }}
                    />
                  </div>

                  <div className="form-group">
                    <label>Position</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Position"
                      value={position}
                      onChange={(e) => {
                        setposition(e.target.value);
                      }}
                    />
                  </div>

                  <div className="form-group">
                    <label>Experience in Years </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Total years of work experience"
                      value={experienceInYears}
                      onChange={(e) => {
                        setExperienceInYears(e.target.value);
                      }}
                    />
                  </div>

                  <div className="form-group">
                    <label>Phone</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Phone"
                      value={phone}
                      onChange={(e) => {
                        setphone(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="process_btns_outer d-flex align-items-center justify-content-between mt-3">
                  <button
                    // onClick={resetHandler}
                    data-bs-dismiss="modal"
                    className="btn gray"
                  >
                    Cancel
                  </button>
                  <button className="btn btn-primary" onClick={saveInfoHandler}>
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalInfoModal;
