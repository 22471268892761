import React from 'react';

const BlogDetailsPlaceHolder = () => {
  const paraCount = [1, 2]; //for paragraph placegolder
  return (
    <div className="landing_row blog_details_row">
      <div className="details_arw green_back mb-4">
        <span
          className="placeholder rounded-circle"
          style={{ width: '60px', height: '60px', minWidth: '60px' }}
        ></span>
      </div>
      <div className="landing_hdng_row">
        <div className="page_hdng" style={{ width: '100%' }}>
          <h2 style={{ width: '100%' }}>
            <span className="placeholder" style={{ width: '100%' }}></span>{' '}
            <br />
            <span className="placeholder" style={{ width: '60%' }}></span>
          </h2>
        </div>
      </div>
      <div className="news_date_info">
        <label>
          {' '}
          <span className="placeholder" style={{ width: '80px' }}></span>
        </label>
        <div className="bttm_step_cloude" style={{ width: '80%' }}>
          <span
            className="placeholder rounded-circle"
            style={{ height: '50px', width: '50px', minWidth: '50px' }}
          ></span>
          <div className="cloud_icon_text" style={{ width: '100%' }}>
            <h4 style={{ width: '100px' }}>
              <i className="placeholder" style={{ width: '100%' }}></i>
            </h4>
            <h6 style={{ width: '150px' }}>
              <i className="placeholder" style={{ width: '100%' }}></i>
            </h6>
          </div>
        </div>
      </div>
      <div className="blog_pic details_pic">
        <span
          className="placeholder"
          style={{
            width: '100%',
            height: '400px',
            borderRadius: '20px',
            marginBottom: '16px',
          }}
        ></span>
      </div>
      <div className="details_content_innr">
        {paraCount.map((count, index) => {
          return (
            <div className="content_item" key={index}>
              <h4 style={{ width: '60%' }}>
                {' '}
                <span className="placeholder" style={{ width: '30%' }}></span>
              </h4>
              <div className="details_para">
                <p>
                  <span
                    className="placeholder"
                    style={{ width: '100%' }}
                  ></span>
                  <span
                    className="placeholder"
                    style={{ width: '100%' }}
                  ></span>
                  <span
                    className="placeholder"
                    style={{ width: '100%' }}
                  ></span>
                  <span
                    className="placeholder"
                    style={{ width: '100%' }}
                  ></span>
                  <span className="placeholder" style={{ width: '60%' }}></span>
                  <span className="placeholder" style={{ width: '40%' }}></span>
                </p>
              </div>
            </div>
          );
        })}
      </div>
      <div className="details_back">
        <span
          className="placeholder rounded-circle"
          style={{ width: '60px', height: '60px', minWidth: '60px' }}
        ></span>
      </div>
    </div>
  );
};

export default BlogDetailsPlaceHolder;
