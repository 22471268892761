import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
//import { assetImages } from "constants";

const SuggestJob = ({params}) => {

  useEffect(()=>{
    console.log('params', params)
  }, [params])


  return (
    <div className="ai_productOuter">

        <Link to="#" className="ai_product_row d-flex gap-2">
            <div className="ai_procudt_pic pd_10">
                <img src="images/company_logo2.svg" alt="" />
            </div> 
            <div className="ai_procudt_text">
                <h3>Senior Full Stack Developer</h3>
                <p className="d-flex align-items-center gap-1 mb-1">  
                <span className="material-icons-outlined">place </span>Madrid, Spain <em>Full Time</em></p>
                <h4>$ 85,000 / Year</h4>
            </div>
        </Link>

        <Link to="#" className="ai_product_row d-flex gap-2">
            <div className="ai_procudt_pic pd_10">
                <img src="images/company_logo2.svg" alt="" />
            </div> 
            <div className="ai_procudt_text">
                <h3>React Developer</h3>
                <p className="d-flex align-items-center gap-1 mb-1">  
                <span className="material-icons-outlined">place </span>Madrid, Spain <em>Full Time</em></p>
                <h4>$ 60,000 / Year</h4>
            </div>
        </Link>

        

    
        <div><Link to="#" className="btn outline" >View more jobs?</Link></div>

    </div>

    
  );
};

export default SuggestJob;
