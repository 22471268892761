/* eslint-disable */
import React, { useEffect } from "react";
import Header from "../../components/common/Header";
import HomeBanner from "../../components/HomeComponents/HomeBanner/HomeBanner";
import Footer from "../../components/common/Footer";
import Brands from "../../components/common/Brands";
import HomeWorking from "../../components/HomeComponents/HomeWorking/HomeWorking";
import HomeWhyLobees from "../../components/HomeComponents/HomeWhyLobees/HomeWhyLobees";
import HomeModules from "../../components/HomeComponents/HomeModules/HomeModules";
import CustomerFeedback from "../../components/common/CustomerFeedback";
import ContactNow from "../../components/common/ContactNow";

const Home = () => {
  useEffect(() => {
    document.title = "Home"; // Update the title when Register component mounts

    //this is for showing login modal first render.
    let myModal = new bootstrap.Modal(document.getElementById("login_modal"));
    myModal.show();
  }, []);

  return (
    <>
      {/* ---- Header section ----- */}
      <Header />

      {/* ---- Banner section ---- */}
      <HomeBanner />

      {/* ---- Brands / companies section ---- */}
      <Brands />

      {/* ---- how it works section ---- */}
      <HomeWorking />

      {/* ---- why lobees section ---- */}
      <HomeWhyLobees />

      {/* ---- Modules section ---- */}
      <HomeModules />

      {/* ---- customer feedback section ---- */}
      <CustomerFeedback />

      {/* ---- connect now section ---- */}
      <ContactNow />

      {/* ---- footer section ---- */}
      <Footer />
    </>
  );
};

export default Home;
