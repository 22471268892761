import AuthContactPanel from 'components/common/AuthContactPanel';
import React from 'react';

const FeedLandingRightPanel = () => {
  return (
    <>
      <AuthContactPanel />
    </>
  );
};

export default FeedLandingRightPanel;
