import React from "react";
import { Link, useParams } from "react-router-dom";

const DetailsTab = ({
  requiredSkills,
  optionalSkills,
  summary,
  details,
  aboutPosition,
  aboutCompany,
  minSalary,
  maxSalary,
  isApplied,
}) => {
  const params = useParams();
  return (
    <div className="people_detailspnl">
      <div className="people_details_bx white_shadow_bx">
        <div className="results_innr">
          <div className="results_item">
            <div className="results_para">
              <h3>Summary</h3>
              <p>{summary}</p>
            </div>
          </div>
          <div className="results_item">
            <h3>Details</h3>
            <div className="results_para">
              <p dangerouslySetInnerHTML={{ __html: details }}></p>
            </div>
          </div>

          <div className="results_item">
            <h3>About Position</h3>
            <div className="results_para">
              <p dangerouslySetInnerHTML={{ __html: aboutPosition }}></p>
            </div>
          </div>

          <div className="results_item">
            <h3>About Company</h3>
            <div className="results_para">
              <p dangerouslySetInnerHTML={{ __html: aboutCompany }}></p>
            </div>
          </div>

          <div className="results_item">
            <h3>Salary and compensation</h3>
            <div className="results_para">
              <p>
                €{minSalary} — €{maxSalary}
              </p>
            </div>
          </div>
        </div>

        <div className="result_btnArea">
          <Link to={`/cvbuilder/${params.id}`} className="btn outline">
            Apply Now
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DetailsTab;
