import React, { useEffect } from 'react';
import Footer from '../../../components/common/Footer';
import BlogListByCategory from 'components/BlogsComponents/BlogListByCategory/BlogListByCategory';
import Header from 'components/common/Header';
import ContactNow from 'components/common/ContactNow';

const BlogListing = () => {
  useEffect(() => {
    document.title = 'Blogs'; // Update the title when Register component mounts
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <>
      {/* ---- Header section ----- */}
      <Header isBlog={true} />

      {/* ---- blogs body section ---- */}
      <BlogListByCategory />

      {/* ---- connect now section ---- */}
      <ContactNow />

      {/* ---- footer section ---- */}
      <Footer />
    </>
  );
};

export default BlogListing;
