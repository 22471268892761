/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";
import OverviewTab from "./CompanyDetailsRightPanelTabs/OverviewTab/OverviewTab";
import ProductTab from "./CompanyDetailsRightPanelTabs/ProductTab/ProductTab";
import TeamTab from "./CompanyDetailsRightPanelTabs/TeamTab/TeamTab";
import AwardsTab from "./CompanyDetailsRightPanelTabs/AwardsTab/AwardsTab";
import FAQTab from "./CompanyDetailsRightPanelTabs/FAQTab/FAQTab";
import ReviewsTab from "./CompanyDetailsRightPanelTabs/ReviewsTab/ReviewsTab";
import ESGReportTab from "./CompanyDetailsRightPanelTabs/ESGReportTab/ESGReportTab";

const CompanyDetailsRightPanel = ({
  companyDetails,
  companyServices,
  companySearchings,
  companyInterests,
  companyDocs,
  companyMemberList,
  ratingCount,
  reviewsList,
  ratingPercentArr,
}) => {
  return (
    <div className="pnl_mdlpnl">
      <div className="people_item">
        <div className="tab_links people_tablinks">
          <ul className="nav nav-tabs list_stye_none">
            <li className="nav-item">
              <Link
                className="nav-link active show"
                data-bs-toggle="tab"
                to="#overview"
              >
                Overview
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link " data-bs-toggle="tab" to="#team">
                team
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link className="nav-link " data-bs-toggle="tab" to="#product">
                Product
              </Link>
            </li> */}

            {/* <li className="nav-item">
              <Link className="nav-link " data-bs-toggle="tab" to="#awards">
                Awards
              </Link>
            </li> */}
            <li className="nav-item">
              <Link className="nav-link" data-bs-toggle="tab" to="#reviews">
                Reviews
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link className="nav-link " data-bs-toggle="tab" to="#faq">
                FAQs
              </Link>
            </li> */}
            {/* <li className="nav-item">
              <Link className="nav-link " data-bs-toggle="tab" to="#esg_report">
                ESG Report
              </Link>
            </li> */}
          </ul>
        </div>

        <div className="tab-content">
          <div className="tab-pane fade show active" id="overview">
            <OverviewTab
              companyDetails={companyDetails}
              companyServices={companyServices}
              companySearchings={companySearchings}
              companyInterests={companyInterests}
              companyDocs={companyDocs}
            />
          </div>

          <div className="tab-pane fade" id="product">
            <ProductTab />
          </div>

          <div className="tab-pane fade" id="team">
            <TeamTab companyMemberList={companyMemberList} />
          </div>

          <div className="tab-pane fade" id="awards">
            <AwardsTab />
          </div>

          <div className="tab-pane fade" id="faq">
            <FAQTab />
          </div>

          <div className="tab-pane fade" id="reviews">
            <ReviewsTab
              ratingCount={ratingCount}
              reviewsList={reviewsList}
              ratingPercentArr={ratingPercentArr}
            />
          </div>

          <div className="tab-pane fade" id="esg_report">
            <ESGReportTab />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyDetailsRightPanel;
