/*import url and gateway methods */
import { getData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

export const getAllCategories = async (ecosystemSlug, parentSlug) => {
  try {
    let requestUrl = url.API_BASE_URL + url.API_GET_CATEGORIES;

    if (ecosystemSlug && ecosystemSlug !== "") {
      requestUrl += `?ecosystemslug=${ecosystemSlug}`;
    }

    if (parentSlug && parentSlug !== "") {
      requestUrl += `&slug=${parentSlug}`;
    }

    const response = await getData(requestUrl);

    if (response.status) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    throw new error.message();
  }
};
