/* eslint-disable */
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

// Import CKEditor here (both required).
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

/*import url and gateway methods */
import { getData, postData, putData, uploadMultipleFile } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import { fileDownloadHandler } from "utils/DownloadFile";
import { assetImages } from "constants";

const TaskTab = ({
  userCourseRole = "",
  taskList = [],
  selectedLessonId = null,
}) => {
  /*---- CKEditor classic editor configuration start ----- */
  const editorConfig = {
    toolbar: ["bold", "italic", "link", "bulletedList", "numberedList"],
    ckfinder: {
      // Upload the images to the server using the CKFinder QuickUpload command.
      //   uploadUrl: url.URL_TEXT_EDITOR_IMAGE_UPLOAD_2,
    },
    placeholder: "Write your answer here",
  };
  /*---- CKEditor classic editor configuration end ----- */

  const params = useParams();
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const [taskClassName, setTaskClassName] = useState("task_panl lesson_pnl");
  const [selectedTaskId, setselectedTaskId] = useState(null);
  const [taskDetails, settaskDetails] = useState({});
  const [existingAssignmentTaskId, setExistingAssignmentTaskId] =
    useState(null);
  const [existingAssignmentTaskStatus, setexistingAssignmentTaskStatus] =
    useState("");
  const [existingAssignmentTaskGrade, setexistingAssignmentTaskGrade] =
    useState("");
  const [existingAssignmentTaskDate, setexistingAssignmentTaskDate] =
    useState("");
  const [existingAssignmentTaskFiles, setExistingAssignmentTaskFiles] =
    useState([]);

  const [isCreating, setisCreating] = useState(false);

  const [taskAnswer, settaskAnswer] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadedFileIds, setUploadedFileIds] = useState([]);
  const [isCompleted, setisCompleted] = useState(false);

  const [logList, setLogList] = useState([]);

  const [taskCompleteStatus, settaskCompleteStatus] = useState("0");
  const [isSaving, setisSaving] = useState(false);

  const closeTaskDetails = () => {
    setTaskClassName("task_panl lesson_pnl");
    setselectedTaskId(null);
    resetAnswerSection();
  };

  //get task details
  const getTaskDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_TASK_DETAILS +
        `/${selectedTaskId}?token=${token}`;

      // console.log(requestUrl);

      const response = await getData(requestUrl);

      console.log("task details", response);

      if (response.status) {
        settaskDetails(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //get created task assignment details
  const getTaskAssignmentDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_EXISTING_LESSON_TASK +
        `?token=${token}&filtercourse=${params.id}&lessonid=${selectedLessonId}&previoustask=${selectedTaskId}`;

      // console.log(requestUrl);

      const response = await getData(requestUrl);

      console.log("task assignment details", response);

      if (response.status) {
        setExistingAssignmentTaskId(response.data._id);
        setexistingAssignmentTaskStatus(response.data.taskstatus);
        setexistingAssignmentTaskGrade(response.data.score);
        setexistingAssignmentTaskDate(response.data.taskdate);
        setExistingAssignmentTaskFiles(response.data.uploadedfiles);
        settaskCompleteStatus(response.data.completedpercent);

        if (response.data.completedpercent === "3") {
          setisCompleted(true);
        }
      } else {
        setExistingAssignmentTaskId(null);
        setexistingAssignmentTaskStatus("");
        setexistingAssignmentTaskGrade("");
        setexistingAssignmentTaskDate("");
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //get task logs
  const getTaskLogs = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_COURSE_TASK_ANSWERS +
        `?token=${token}&filtertask=${existingAssignmentTaskId}&filterlesson=${selectedLessonId}&filtercourse=${params.id}`;

      // console.log("log list url----->", requestUrl);

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setLogList(response.data);
      }
      // else {
      //   setMessageType("error");
      //   setAlertMessage(response.message);
      //   setShowAlert(true);
      // }
    } catch (error) {
      // setMessageType("error");
      // setAlertMessage(error.message);
      // setShowAlert(true);
    }
  };

  //toggle task answer section
  const resetAnswerSection = () => {
    settaskAnswer("");
    setUploadedFileIds([]);
    setUploadedFiles([]);
    setisCompleted(false);
  };

  //function for file upload
  const uploadHandler = async (e) => {
    try {
      setIsUploading(true);
      let requestUrl =
        url.API_BASE_URL + url.API_MULTIPLE_FILE_UPLOAD + `?token=${token}`;

      console.log("req url", requestUrl);

      const response = await uploadMultipleFile(requestUrl, e.target.files);

      console.log(
        `response in file upload mail template------>`,
        response.data
      );

      if (response.status) {
        /*----- assign uploaded files with path for display start ------*/
        const uploadedFileValues = response.data.map(
          ({ name, path, _id, ...other }) => ({
            name,
            path: url.SERVER_URL + path,
            _id,
          })
        );

        //check old file exist or not if exist then new file will concat with old file
        if (uploadedFiles.length == 0) {
          setUploadedFiles(uploadedFileValues);
        } else {
          let fileArr = uploadedFiles.concat(uploadedFileValues);
          setUploadedFiles(fileArr);
        }
        /*----- assign uploaded files with path for display end ------*/

        setIsUploading(false);

        /*----- assign uploaded file ids start ------*/
        const fileIds = response.data.map(({ _id, ...other }) => ({ _id }));

        //check old file ids are exist or not.If exist then new id will concat with old id
        if (uploadedFileIds.length == 0) {
          setUploadedFileIds(fileIds);
        } else {
          let fileArr = uploadedFileIds.concat(fileIds);
          setUploadedFileIds(fileArr);
        }
        /*----- assign uploaded file ids end ------*/
        resetFile();
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  // function for clear file value
  const resetFile = () => {
    const file = document.getElementById("uploadFile");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  //function for delete file from upload stack
  const closeUploadedFileHandler = (index) => {
    const closedFile = [...uploadedFiles];
    const removedFileId = [...uploadedFileIds];

    closedFile.splice(index, 1);
    removedFileId.splice(index, 1);

    setUploadedFiles(closedFile);
    setUploadedFileIds(removedFileId);
  };

  //create Task Assignment
  const createTaskAssignmentHandler = async () => {
    try {
      setisCreating(true);

      let taskData = {
        uploads: [],
        previoustask: selectedTaskId,
        lessonid: selectedLessonId,
        course: params.id,
        assigneduser: userInfo._id,
      };

      let requestURL =
        url.API_BASE_URL + url.API_CREATE_LESSON_ASSIGNMENT + `?token=${token}`;

      const response = await postData(requestURL, taskData);

      setisCreating(false);

      console.log(response);

      if (response.status) {
        getTaskAssignmentDetails();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for submit answer of task
  const submitAnswerHandler = async () => {
    try {
      setisSaving(true);

      let taskData = {
        answer: taskAnswer,
        uploads: uploadedFileIds,
        previoustask: selectedTaskId,
        lessonid: selectedLessonId,
        course: params.id,
        assigneduser: userInfo._id,
      };

      if (isCompleted) {
        taskData.completedpercent = "3";
      }

      let requestURL =
        url.API_BASE_URL +
        url.API_SUBMIT_LESSON_TASK +
        `/${existingAssignmentTaskId}?token=${token}`;

      const response = await putData(requestURL, taskData);

      setisSaving(false);

      console.log(response);

      if (response.status) {
        settaskAnswer("");
        setUploadedFileIds([]);
        setUploadedFiles([]);
        getTaskDetails();
        getTaskAssignmentDetails();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (selectedTaskId) {
      getTaskDetails();
      getTaskAssignmentDetails();
    }
  }, [selectedTaskId]);

  useEffect(() => {
    if (selectedLessonId) {
      closeTaskDetails();
    }
  }, [selectedLessonId]);

  useEffect(() => {
    if (existingAssignmentTaskId && selectedLessonId) {
      getTaskLogs();
    }
  }, [existingAssignmentTaskId, selectedLessonId]);

  if (taskList.length > 0) {
    return (
      <div className={taskClassName}>
        <div className="task_outer">
          {taskList.map((task, index) => {
            return (
              <div className="task_row" key={index}>
                <div className="task_left col-lg-10">
                  <h3>{task?.title}</h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: task?.description,
                    }}
                  ></p>
                  <div className="jb_rating my-2">
                    <i className="material-icons-outlined">schedule</i>
                    <span>{task.duration} min</span>
                  </div>
                </div>
                <div className="task_right">
                  <div className="task_btn start_task_btn">
                    <Link
                      to="#"
                      className="btn outline"
                      onClick={() => {
                        setTaskClassName("task_panl lesson_pnl task_open");
                        setselectedTaskId(task._id);
                      }}
                    >
                      View Task
                    </Link>
                  </div>
                  {/* <div className="details_arw_btn">
                  <Link to="#">
                    <i className="material-icons-outlined">arrow_forward</i>
                  </Link>
                </div> */}
                </div>
              </div>
            );
          })}
          {/* <div className="task_row">
          <div className="task_left">
            <h3>What is Qualitative Analysis?</h3>
            <p>
              I love coding and problem solving. I have 3+ years of experience
              in the web development sector.
            </p>
            <div className="jb_rating">
              <i className="material-icons-outlined">schedule</i>
              <span>20min</span>
            </div>
          </div>
          <div className="task_right">
            <div className="task_btn success_btn">
              <Link to="#" className="btn outline">
                Completed
              </Link>
            </div>
            <div className="details_arw_btn">
              <Link to="#">
                <i className="material-icons-outlined">arrow_forward</i>
              </Link>
            </div>
          </div>
        </div>
        <div className="task_row">
          <div className="task_left">
            <h3>What is Qualitative Analysis?</h3>
            <p>
              I love coding and problem solving. I have 3+ years of experience
              in the web development sector.
            </p>
            <div className="jb_rating">
              <i className="material-icons-outlined">schedule</i>
              <span>20min</span>
            </div>
          </div>
          <div className="task_right">
            <div className="task_btn resume_btn">
              <Link to="#" className="btn outline gray_btn">
                Resume
              </Link>
            </div>
            <div className="details_arw_btn">
              <Link to="#">
                <i className="material-icons-outlined">arrow_forward</i>
              </Link>
            </div>
          </div>
        </div> */}
        </div>

        {/* <!-- ========== Start task details Section ========== --> */}

        <div className="task_detailspnl">
          <div className="task_back">
            <Link to="#" className="btn outline" onClick={closeTaskDetails}>
              <i className="material-icons-outlined">arrow_back</i>
              <span>Back</span>
            </Link>
          </div>

          {userCourseRole !== "" ? null : (
            <div className="task_status_outer">
              {existingAssignmentTaskId && (
                <div className="taskstatus_item">
                  <h3>Status</h3>
                  <h5 className="sucess-txt">{existingAssignmentTaskStatus}</h5>
                </div>
              )}

              {existingAssignmentTaskId && (
                <div className="taskstatus_item">
                  <h3>Paasing Grade</h3>
                  <h4>{existingAssignmentTaskGrade}% or higher</h4>
                </div>
              )}

              {existingAssignmentTaskId && (
                <div className="taskstatus_item">
                  <h3>Task Date</h3>
                  <h4>{existingAssignmentTaskDate}</h4>
                </div>
              )}

              {existingAssignmentTaskId ? null : (
                <div className="taskstatus_item">
                  <button
                    className="btn btn-primary"
                    onClick={createTaskAssignmentHandler}
                    disabled={isCreating ? true : false}
                    style={{
                      cursor: isCreating ? "not-allowed" : "pointer",
                    }}
                  >
                    Start Assignment
                    {isCreating && (
                      <div
                        className="mx-2 spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </button>
                </div>
              )}
            </div>
          )}

          {/* <!-- ========== Start title Section ========== --> */}
          <div className="task_detailsHdng">
            <h2>{taskDetails?.title}</h2>
            <div className="jb_rating">
              <i className="material-icons-outlined">schedule</i>
              <span>{taskDetails?.duration} min</span>
            </div>
          </div>
          {/* <!-- ========== End title Section ========== --> */}

          {/* <!-- ========== Start task detilas Section ========== --> */}
          <div className="task_detailspara mt-3">
            <p
              dangerouslySetInnerHTML={{
                __html: taskDetails?.description,
              }}
            ></p>
          </div>
          {/* <!-- ========== End task detilas Section ========== --> */}

          {/* details */}
          {existingAssignmentTaskId ? (
            taskCompleteStatus === "3" ? null : (
              <div className="post_description">
                <CKEditor
                  className="form-control"
                  editor={ClassicEditor}
                  config={editorConfig}
                  data={taskAnswer}
                  onReady={(editor) => {
                    editor.editing.view.change((writer) => {
                      writer.setStyle(
                        "height",
                        "150px",
                        editor.editing.view.document.getRoot()
                      );
                    });
                  }}
                  onChange={(event, editor) => {
                    let editorContent = editor.getData();
                    settaskAnswer(editorContent);
                  }}
                />
              </div>
            )
          ) : null}

          {/* <!-- ========== Start file Section ========== --> */}
          {existingAssignmentTaskId ? (
            taskCompleteStatus === "3" ? null : (
              <ul className="uploaded-files d-flex flex-wrap gap-3 p-0 m-0 mt-3">
                {uploadedFiles.map((file, index) => {
                  return (
                    <li
                      className="file d-flex gap-3 align-items-center px-3 py-2 border border-gray rounded-10"
                      key={index}
                    >
                      <Link
                        to="#"
                        className="delete_btn"
                        onClick={() => {
                          closeUploadedFileHandler(index);
                        }}
                      >
                        <span className="material-icons-outlined">delete</span>
                      </Link>
                      <p className="fw-medium">{file.name}</p>
                      <Link to="#" onClick={() => fileDownloadHandler(file)}>
                        <span className="d-block material-icons-outlined">
                          download
                        </span>
                      </Link>
                    </li>
                  );
                })}

                <li className="col-2 border border-gray rounded-10">
                  <label
                    htmlFor="uploadFile"
                    className="upload-container text-center h-100 flex-column p-3 position-relative overflow-hidden"
                  >
                    <input
                      type="file"
                      id="uploadFile"
                      className="d-none"
                      onChange={uploadHandler}
                      multiple
                    />
                    <span className="d-block material-icons-outlined mb-3">
                      upload
                    </span>
                    <h6 className="fw-bold mb-2">Attach Files</h6>
                    {/* <p>Max 20mb image/doc/mp4</p> */}
                  </label>
                </li>
              </ul>
            )
          ) : null}
          {/* <!-- ========== End file Section ========== --> */}

          {/* <!-- ========== Start submit and close button  Section ========== --> */}
          {existingAssignmentTaskId ? (
            taskCompleteStatus === "3" ? null : (
              <div className="tsk_btns align-items-center justify-content-between mb-5">
                <div className="trms_check lessons_check">
                  <label className="radio-check">
                    <input
                      type="checkbox"
                      checked={isCompleted}
                      onChange={(e) => {
                        setisCompleted(e.target.checked);
                      }}
                    />
                    <span></span>
                    <p>I have completed this task</p>
                  </label>
                </div>
                <button
                  className="btn btn-primary"
                  onClick={submitAnswerHandler}
                  disabled={isSaving ? true : false}
                  style={{
                    cursor: isSaving ? "not-allowed" : "pointer",
                  }}
                >
                  Submit Answer
                  {isSaving && (
                    <div
                      className="mx-2 spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
            )
          ) : null}
          {/* <!-- ========== End submit and close button  Section ========== --> */}

          {/* <!-- ========== Start task status Section ========== --> */}
          {existingAssignmentTaskId ? (
            <div
              className={`opportunities_table border rounded-10 ${
                taskCompleteStatus === "3" ? "mt-3" : ""
              }`}
            >
              <table>
                <thead>
                  <tr>
                    <th>Status</th>
                    <th>Begin</th>
                    <th>Submitted</th>
                    <th>Reviewed By</th>
                    <th>Audited By</th>
                  </tr>
                </thead>
                <tbody>
                  {logList.map((logData, index) => {
                    return (
                      <tr key={index}>
                        <td className="fw-semibold text-black">
                          {logData.taskstatus}
                        </td>
                        <td className="fw-semibold text-black">
                          {logData.startdate}
                        </td>
                        <td className="fw-semibold text-black">
                          {logData.enddate}
                        </td>
                        <td className="fw-semibold text-black">--</td>
                        <td className="fw-semibold text-black">
                          {logData.taskfollowername}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : null}
          {/* <!-- ========== End task status Section ========== --> */}

          {/* <!-- ========== Start task files Section ========== --> */}
          {existingAssignmentTaskId && (
            <ul className="uploaded-files d-flex flex-wrap gap-3 p-0 m-0 my-3">
              {existingAssignmentTaskFiles.map((file, index) => {
                return (
                  <li
                    className="file d-flex gap-3 align-items-center px-3 py-2 border border-gray rounded-3"
                    key={index}
                  >
                    <p className="fw-medium">{file.name}</p>
                    <Link to="#" onClick={() => fileDownloadHandler(file)}>
                      <span className="d-block material-icons-outlined">
                        download
                      </span>
                    </Link>
                  </li>
                );
              })}
            </ul>
          )}
          {/* <!-- ========== End task files Section ========== --> */}

          <div className="tsk_btns mt-5">
            <Link to="#" className="btn primary" onClick={closeTaskDetails}>
              Back to List
            </Link>
            {/* <Link to="#" className="btn gray_btn">
              Try Again
            </Link> */}
          </div>
        </div>

        {/* <!-- ========== End task details Section ========== --> */}
      </div>
    );
  } else {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <div className="pnl_mdlpnl">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                Sorry....! Assignments are not available for this lesson
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default TaskTab;
