/* eslint-disable */
import React, { useEffect } from "react";

import AuthHeader from "components/common/AuthHeader";
import EventLandingBody from "components/EventComponents/EventLandingComponents/EventLandingBody/EventLandingBody";

const EventLanding = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <AuthHeader moduleName={"event"} />

      <EventLandingBody />
    </>
  );
};

export default EventLanding;
