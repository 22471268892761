/* eslint-disable */
import { useState } from "react";
import CustomerFeedbackLeftPanel from "./Panels/LeftPanel/CustomerFeedbackLeftPanel";
import CustomerFeedbackRightPanel from "./Panels/RightPanel/CustomerFeedbackRightPanel";
import { assetImages } from "constants";

import SuccessModal from "./Modal/SuccessModal";

const CvBuilderMainPanel = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const [cvId, setcvId] = useState(null);
  const [reloadCvList, setreloadCvList] = useState(false);
  const [startFetchFromProfile, setStartFetchFromProfile] = useState(false);
  const [cvContent, setCvContent] = useState({});

  //function for after modal close execution
  const afterModalClose = () => {};

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_CV")
  ) {
    return (
      <section className="feed_pnltotal">
        <div className="container-fluid">
          <div className="pnl_outer">
            <div className="row col-12">
              <CustomerFeedbackLeftPanel
              // cvId={cvId}
              // setreloadCvList={setreloadCvList}
              // startFetchFromProfile={startFetchFromProfile}
              // setStartFetchFromProfile={setStartFetchFromProfile}
              // setCvContent={setCvContent}
              />
              <CustomerFeedbackRightPanel
              // setcvId={setcvId}
              // reloadCvList={reloadCvList}
              // setreloadCvList={setreloadCvList}
              // setStartFetchFromProfile={setStartFetchFromProfile}
              // cvContent={cvContent}
              />
            </div>
          </div>
        </div>

        <SuccessModal afterModalClose={afterModalClose} />
      </section>
    );
  } else {
    return (
      <section className="feed_pnltotal">
        <div className="container-fluid">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                Sorry....! You don't have privilege to see this content
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }
};

export default CvBuilderMainPanel;
