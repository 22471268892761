import React from 'react';
// import use translation ------------------------------
import { useTranslation } from 'react-i18next';

const KitDigitalProjects = () => {
  const { t } = useTranslation(); // translation marker
  return (
    <section className="help_system_area pd_130">
      <div className="help_dots help_dots1">
        <img src="images/help-dots1.png" alt="" />
      </div>
      <div className="help_dots help_dots2">
        <img src="images/help-dots2.png" alt="" />
      </div>
      <div className="container">
        <div className="page_hdng dark_grnheading text-center">
          <h5>{t('How do we help organizations to')}</h5>
          <h2>
            {t('Projects you can tackle')} <br /> {t('with theDigital Kit')}
          </h2>
        </div>

        {/* --- card section start --- */}
        <div className="help_outer kit_icon_outer">
          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <div className="help_item">
                <div className="circle_icon">
                  <span className="material-icons-outlined">
                    space_dashboard
                  </span>
                </div>
                <div className="help_text">
                  <h3>{t('Website and Internet Presence')}</h3>
                  <p>{t('Up to')} 2.000€</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="help_item">
                <div className="circle_icon">
                  <span className="material-icons-outlined">storefront</span>
                </div>
                <div className="help_text">
                  <h3>{t('E-Commerce')}</h3>
                  <p>{t('Up to')} 2.000€</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="help_item">
                <div className="circle_icon">
                  <span className="material-icons-outlined">inventory</span>
                </div>
                <div className="help_text">
                  <h3>{t('Business Intelligence and Analytics')}</h3>
                  <p>{t('Up to')} 4.000€</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="help_item">
                <div className="circle_icon">
                  <span className="material-icons-outlined">work_outline</span>
                </div>
                <div className="help_text">
                  <h3>{t('Virtual Office Services')}</h3>
                  <p>{t('Up to')} 12.000€</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="help_item">
                <div className="circle_icon">
                  <span className="material-icons-outlined">hub</span>
                </div>
                <div className="help_text">
                  <h3>{t('Secure Communications')}</h3>
                  <p>{t('Up to')} 6.000€</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="help_item">
                <div className="circle_icon">
                  <span className="material-icons-outlined">verified_user</span>
                </div>
                <div className="help_text">
                  <h3>{t('Cybersecurity')}</h3>
                  <p>{t('Up to')} 6.000€</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="help_item">
                <div className="circle_icon">
                  <span className="material-icons-outlined">support_agent</span>
                </div>
                <div className="help_text">
                  <h3>{t('Customer Management')}</h3>
                  <p>{t('Up to')} 4.000€</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="help_item">
                <div className="circle_icon">
                  <span className="material-icons-outlined">account_tree</span>
                </div>
                <div className="help_text">
                  <h3>{t('Process Management')}</h3>
                  <p>{t('Up to')} 6.000€</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="help_item">
                <div className="circle_icon">
                  <span className="material-icons-outlined">receipt_long</span>
                </div>
                <div className="help_text">
                  <h3>{t('Electronic Invoicing')}</h3>
                  <p>{t('Up to')} 1.000€</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* --- card section end --- */}
      </div>
    </section>
  );
};

export default KitDigitalProjects;
