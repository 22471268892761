import React from 'react';
import { Link } from 'react-router-dom';

const ProfileMenuPanel = ({ moduleName }) => {
  return (
    <div className="white_shadow_bx left_menulist">
      <ul className="list_stye_none">
        <li className={moduleName === 'staffInfo' && 'active'}>
          <Link to="/profile">
            <span className="feed_menu_left">
              <i className="material-icons-outlined">account_circle</i>
              <span>Staff Info </span>
            </span>
          </Link>
        </li>
        <li className={moduleName === 'experience' && 'active'}>
          <Link to="/experience">
            <span className="feed_menu_left">
              <i className="material-icons-outlined">event_note</i>
              <span>Experience </span>
            </span>
          </Link>
        </li>

        <li className={moduleName === 'improvementArea' && 'active'}>
          <Link to="/improvementarea">
            <span className="feed_menu_left">
              <i className="material-icons-outlined">lightbulb</i>
              <span>Improvement Area </span>
            </span>
          </Link>
        </li>
        <li className={moduleName === 'password' && 'active'}>
          <Link to="/password">
            <span className="feed_menu_left">
              <i className="material-icons-outlined">lock</i>
              <span>Password </span>
            </span>
          </Link>
        </li>
        <li className={moduleName === 'accountSettings' && 'active'}>
          <Link to="/accountsettings">
            <span className="feed_menu_left">
              <i className="material-icons-outlined">settings</i>
              <span>Account Settings </span>
            </span>
          </Link>
        </li>
        <li className={moduleName === 'privacy' && 'active'}>
          <Link to="/privacy">
            <span className="feed_menu_left">
              <i className="material-icons-outlined">verified_user</i>
              <span>Privacy </span>
            </span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default ProfileMenuPanel;
