/* eslint-disable */
import AppUserDetailsBody from "components/AppUserComponents/AppUserDetailsComponents/AppUserDetailsBody/AppUserDetailsBody";
import AuthHeader from "components/common/AuthHeader";
//import context
import { GlobalProvider } from "context/GlobalContext";
import React, { useEffect, useContext } from "react";

const AppUserDetails = () => {
  return (
    <>
      {/* ------- header section ---------- */}
      <header className="aftre_login_header">
        <AuthHeader />
      </header>

      {/* ------- body section ------------ */}
      <AppUserDetailsBody />
    </>
  );
};

export default AppUserDetails;
