import React from "react";
import { Link } from "react-router-dom";
import * as url from "helper/UrlHelper";

const CompanyDetailsLeftPanel = ({
  companyLogo,
  companyName,
  companyCity,
  companyState,
  companyCountry,
  companyTags,
  ratingCount,
  reviewsList,
}) => {
  return (
    <div className="pnl_leftpnl">
      <div className="white_shadow_bx company_leftusr">
        <div className="expertholder_outer">
          <div className="company_pic">
            <img src={url.SERVER_URL + companyLogo} alt="" />
          </div>
          <div className="experts_holdertext">
            <h3>{companyName}</h3>
            <div className="jb_rating star_color">
              <i className="material-icons-outlined ornge_icon"> star </i>
              <span className="rating">
                {ratingCount} <em>({reviewsList.length})</em>
              </span>
            </div>
            <div className="details_tags expert_details">
              <div className="address">
                <i className="material-icons-outlined">place</i>
                <span>
                  {companyCity}, {companyState}, {companyCountry}
                </span>
              </div>
            </div>

            {/* <div className="expert_social">
              <ul className="list_stye_none">
                <li>
                  <Link to="#">
                    <img src="/images/fb.svg" alt="" />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <img src="/images/gmail.svg" alt="" />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <img src="/images/linkedin.svg" alt="" />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <img src="/images/pinterest.svg" alt="" />
                  </Link>
                </li>
              </ul>
            </div> */}
            <div className="feedback_jointbtnOuter company_btns">
              <div className="details_apply">
                <Link
                  to="#"
                  data-bs-target="#company_connect"
                  data-bs-toggle="modal"
                  className="btn outline"
                >
                  Connect
                </Link>
              </div>
              {/* <div className="job_sharebtn">
                <Link to="#">
                  <span className="material-icons">share</span>
                </Link>
              </div> */}
              <div className="job_sharebtn">
                <Link
                  to="#"
                  data-bs-target="#tags_modal"
                  data-bs-toggle="modal"
                >
                  <span className="material-icons">more</span>
                </Link>
              </div>
            </div>
            <div className="feedback_jointbtnOuter">
              <div className="tags_links mt-4 mb-2">
                <ul className="list_stye_none d-flex flex-wrap gap-2 ">
                  {companyTags.map((tag, index) => {
                    return (
                      <li key={index}>
                        <Link
                          to="#"
                          className="d-flex align-items-center gap-2"
                        >
                          <span>{tag.title}</span>
                        </Link>
                      </li>
                    );
                  })}

                  {/* <li>
                    <Link to="#" className="d-flex align-items-center gap-2">
                      <span>List</span>
                      <i className="material-icons">close</i>
                    </Link>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyDetailsLeftPanel;
