import React from "react";
import { Link } from "react-router-dom";

import * as url from "helper/UrlHelper";

import MostImportantPanel from "../../MostImportantPanel/MostImportantPanel";
import { assetImages } from "constants";

const TeamTab = ({ courseDetails = {} }) => {
  return (
    <div className="inner_pnl_outer">
      <div className="inner_leftpnl">
        <div className="post_item_outer">
          {courseDetails?.memberlist ? (
            <div className="post_item">
              {courseDetails?.memberlist?.map((member, index) => {
                return (
                  <div className="white_shadow_bx people_bx" key={index}>
                    <div className="people_row">
                      <div className="left_people">
                        <div className="people_profile">
                          <img
                            src={
                              member?.image && member?.image !== ""
                                ? url.SERVER_URL + member?.image
                                : assetImages.defaultUser
                            }
                            alt=""
                          />
                        </div>
                        <div className="people_profile_info">
                          <h3>{member?.name}</h3>
                          <h5>{member?.role}</h5>
                          <div className="event_tags">
                            <ul className="list_stye_none mb-0">
                              <li>
                                <i className="material-icons-outlined ornge_icon">
                                  star
                                </i>
                                <span className="rating">
                                  {member?.totalratings}{" "}
                                  <em>({member?.totalreviews})</em>
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="right_people_btn">
                        <Link
                          to={`/peoples/details/${member?.userid}`}
                          className="btn outline"
                        >
                          View Details
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
      </div>
      {/* -------- most important panel ------------- */}
      <MostImportantPanel />
    </div>
  );
};

export default TeamTab;
