import { assetImages } from "constants";
import React from "react";
import { Link } from "react-router-dom";

const ServiceCard = ({ service, setKitName, setServiceRate }) => {
  return (
    <div className="digital_srvc_row d-flex">
      <div className="kit_srvc_leftpnl">
        <div className="price_text_area">
          <h6>From </h6>
          <h2>{service.price} €</h2>
        </div>
        <div className="kit_srvc_text">
          <h3>{service.kitname}</h3>
          <p>{service.kitdetails}</p>
          <Link
            to="#"
            data-bs-target="#kit_digital"
            data-bs-toggle="modal"
            className="btn"
            onClick={() => {
              setKitName(service.kitname + " : " + service.price);
              setServiceRate(service.price);
            }}
          >
            Request Information
          </Link>
        </div>
        <div className="kit_logo">
          <img src={assetImages.digitalKitLogo} alt="" />
        </div>
      </div>
      <div className="kit_srvc_rightpnl">
        <ul className="list_stye_none">
          {service.features.map((feature, index) => {
            return (
              <li key={index}>
                <i className="material-icons-outlined">task_alt</i>
                <span>{feature}</span>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default ServiceCard;
