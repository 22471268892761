/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";
// import use translation ------------------------------
import { useTranslation } from "react-i18next";
import { assetImages } from "constants";

import * as url from "helper/UrlHelper";

const ActivityDetailsRightPanel = ({ similarList }) => {
  const { t } = useTranslation(); // translation marker
  return (
    <div className="pnl_rightpnl">
      <div className="white_shadow_bx">
        <div className="contact_header">
          <div className="post_hdng">
            <h4>{t("Similar Post")}</h4>
          </div>
          <div className="contacts_srch_right"></div>
        </div>
        <div className="similar_postpnl">
          {similarList.map((forum, index) => {
            return (
              <div className="similar_post_row" key={index}>
                <div className="user_post_top">
                  <div className="post_left">
                    <div className="pofile_pic">
                      <img
                        src={
                          forum.moderatorimage == ""
                            ? assetImages.defaultUser
                            : url.SERVER_URL + forum.moderatorimage
                        }
                        alt=""
                      />
                    </div>
                    <div className="pofile_info">
                      <h3>{forum.moderatorname}</h3>
                      <ul className="list_stye_none">
                        <li>
                          <i className="material-icons">public</i>
                          <span>Public</span>
                        </li>
                        <li>
                          <span>{forum.timestring}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="user_post_paragraph">
                  <p>{forum.title}</p>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: forum.description.slice(0, 120),
                    }}
                  ></p>
                  <span>
                    <Link to={`/activities/details/${forum._id}`}>
                      {t("See More")}
                    </Link>
                  </span>
                </div>
                <div className="user_post_icons">
                  <ul className="list_stye_none">
                    <li>
                      <Link to="#">
                        <i className="material-icons ornge_icon">favorite</i>
                        <span>{forum.likes.length}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="material-icons">chat</i>
                        <span>{forum.comments.length}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="material-icons">stars</i>
                        <span>{t("Winner")}</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ActivityDetailsRightPanel;
