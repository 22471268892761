/* eslint-disable */
import axios from "axios";

//get method
const getData = (url = "") => {
  return request("get", url);
};

//post method
const postData = (url = "", inputData = {}) => {
  return request("post", url, inputData);
};

//put/update method
const putData = (url = "", inputData = {}) => {
  return request("put", url, inputData);
};

//delete method
const deleteData = (url = "") => {
  return request("delete", url);
};

//common method
const request = async (method, url = "", params = {}) => {
  try {
    const { data } = await axios[method](url, params);
    return data;
  } catch (error) {
    return error.message;
  }
};

//upload single file method
const uploadSingleFile = async (url, formValues = {}) => {
  try {
    const formData = new FormData();

    const uniqueName = `${formValues.name.split('.')[0]}_${Math.random().toString(36).substring(2, 15)}.${formValues.name.split('.').pop()}`;
    formData.append("upload", formValues, uniqueName);

    const { data } = await axios.post(url, formData);

    return data;
  } catch (error) {
    return error.message;
  }
};

//upload multiple file method
const uploadMultipleFile = async (url, formValues = []) => {
  try {
    const formData = new FormData();

    let objArray = Object.keys(formValues);

    objArray.forEach((element) => {
      const file = formValues[element];
      const uniqueName = `${file.name.split('.')[0]}_${Math.random().toString(36).substring(2, 15)}.${file.name.split('.').pop()}`;
      formData.append("uploads", file, uniqueName);
    });

    const { data } = await axios.post(url, formData);

    return data;
  } catch (error) {
    return error.message;
  }
};

export {
  getData,
  postData,
  putData,
  deleteData,
  uploadSingleFile,
  uploadMultipleFile,
};
