import React from 'react';
import AuthHeader from 'components/common/AuthHeader';
import AccountSettingsBody from 'components/AccountSettingsComponents/AccountSettingsBody/AccountSettingsBody';

const AccountSettings = () => {
  return (
    <>
      {/* -------- header ------------ */}
      <AuthHeader />

      {/* ---------- body --------- */}
      <AccountSettingsBody />
    </>
  );
};

export default AccountSettings;
