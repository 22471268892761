/* eslint-disable */
import AuthHeader from "components/common/AuthHeader";
import React, { useContext, useEffect } from "react";
//import context
import { GlobalProvider } from "context/GlobalContext";
import NotificatioBody from "components/NotificationsComponents/NotificationBody/NotificationBody";

const Notifications = () => {
  return (
    <>
      <header className="aftre_login_header">
        <AuthHeader />
      </header>

      {/* ------ body section --------- */}
      <NotificatioBody />
    </>
  );
};

export default Notifications;
