/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";
import clipboardCopy from "clipboard-copy";
import { assetImages } from "constants";

const ShareModal = ({ copyMessage }) => {
  return (
    <div className="post_modal share_modal">
      <div className="modal fade" id="share_mdl" data-bs-backdrop="static">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">Share this item</h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="material-icons-outlined">close</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="share_social_row">
                <ul className="list_stye_none d-flex gap-2">
                  <li>
                    <Link to="#" className="d-block">
                      <span className="d-block text-center mb-2">
                        <img src={assetImages.embedIcon} alt="" />
                      </span>
                      <span className="d-block text-center share_text">
                        Embade
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="d-block">
                      <span className="d-block text-center mb-2">
                        <img src={assetImages.whatsAppIcon} alt="" />
                      </span>
                      <span className="d-block text-center share_text">
                        WhatsApp
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="d-block">
                      <span className="d-block text-center mb-2">
                        <img src={assetImages.skypIcon} alt="" />
                      </span>
                      <span className="d-block text-center share_text">
                        Skype
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="d-block">
                      <span className="d-block text-center mb-2">
                        <img src={assetImages.facebookIcon} alt="" />
                      </span>
                      <span className="d-block text-center share_text">
                        Facebook
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="d-block">
                      <span className="d-block text-center mb-2">
                        <img src={assetImages.twitterIcon} alt="" />
                      </span>
                      <span className="d-block text-center share_text">
                        Twitter
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="d-block">
                      <span className="d-block text-center mb-2">
                        <img src={assetImages.instagramIcon} alt="" />
                      </span>
                      <span className="d-block text-center share_text">
                        Instagram
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="share_copy_link">
                <div className="form-group input_fill">
                  <label>Copy Link</label>
                  <div className="copy_fill">
                    <input
                      type="text"
                      value={copyMessage}
                      className="form-control"
                      placeholder=""
                      readOnly
                    />
                    <Link
                      to="#"
                      data-bs-dismiss="modal"
                      className="link_copy"
                      onClick={() => {
                        clipboardCopy(copyMessage);
                      }}
                    >
                      <span className="material-icons-outlined">
                        content_copy
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareModal;
