/* eslint-disable */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import use translation ------------------------------
import { useTranslation } from "react-i18next";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData } from "utils/Gateway";

const AuthMenuPanel = ({ moduleName }) => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const { t } = useTranslation(); // translation marker

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    (moduleAccess && moduleAccess.length > 0)
  ) {
    return (
      <div className="white_shadow_bx left_menulist">
        <ul className="list_stye_none">
          {/* forum */}
          {userInfo.role.slug === "ADMIN" ||
          userInfo.role.slug === "SUPER_ADMIN" ||
          (moduleAccess && moduleAccess.includes("MOD_ACTIVITY")) ? (
            <li className={moduleName == "activity" ? "active" : ""}>
              <Link to="/activities">
                <span className="feed_menu_left">
                  <i className="material-icons-outlined">rss_feed</i>
                  <span>{t("Activities")}</span>
                </span>
              </Link>
            </li>
          ) : null}

          {/* app user */}
          {userInfo.role.slug === "ADMIN" ||
          userInfo.role.slug === "SUPER_ADMIN" ||
          (moduleAccess && moduleAccess.includes("MOD_USER")) ? (
            <li className={moduleName == "appuser" ? "active" : ""}>
              <Link to="/peoples">
                <span className="feed_menu_left">
                  <i className="material-icons-outlined">group</i>
                  <span>{t("People")}</span>
                </span>
              </Link>
            </li>
          ) : null}

          {/* company */}
          {userInfo.role.slug === "ADMIN" ||
          userInfo.role.slug === "SUPER_ADMIN" ||
          (moduleAccess && moduleAccess.includes("MOD_COMPANY")) ? (
            <li className={moduleName == "company" ? "active" : ""}>
              <Link to="/company">
                <span className="feed_menu_left">
                  <i className="material-icons-outlined">meeting_room</i>
                  <span>{t("Company")}</span>
                </span>
              </Link>
            </li>
          ) : null}

          {/* event */}
          {userInfo.role.slug === "ADMIN" ||
          userInfo.role.slug === "SUPER_ADMIN" ||
          (moduleAccess && moduleAccess.includes("MOD_EVENT")) ? (
            <li className={moduleName == "event" ? "active" : ""}>
              <Link to="/events">
                <span className="feed_menu_left">
                  <i className="material-icons-outlined">calendar_month</i>
                  <span>{t("Events")}</span>
                </span>
                {/* <span className="feed_count">9+</span> */}
              </Link>
            </li>
          ) : null}

          {/* group */}
          {userInfo.role.slug === "ADMIN" ||
          userInfo.role.slug === "SUPER_ADMIN" ||
          (moduleAccess && moduleAccess.includes("MOD_GROUP")) ? (
            <li className={moduleName == "group" ? "active" : ""}>
              <Link to="/groups">
                <span className="feed_menu_left">
                  <i className="material-icons-outlined">diversity_2</i>
                  <span>{t("Groups")}</span>
                </span>
              </Link>
            </li>
          ) : null}

          {/* course */}
          {userInfo.role.slug === "ADMIN" ||
          userInfo.role.slug === "SUPER_ADMIN" ||
          (moduleAccess && moduleAccess.includes("MOD_COURSE")) ? (
            <li className={moduleName == "course" ? "active" : ""}>
              <Link to="/courses">
                <span className="feed_menu_left">
                  <i className="material-icons-outlined">
                    collections_bookmark
                  </i>
                  <span>{t("Courses")}</span>
                </span>
              </Link>
            </li>
          ) : null}

          {/* challenges */}
          {userInfo.role.slug === "ADMIN" ||
          userInfo.role.slug === "SUPER_ADMIN" ||
          (moduleAccess && moduleAccess.includes("MOD_CHALLENGE")) ? (
            <li className={moduleName == "jobs" ? "active" : ""}>
              <Link to="/jobs">
                <span className="feed_menu_left">
                  <i className="material-icons-outlined">work_outline</i>
                  <span>{t("Jobs")}</span>
                </span>
              </Link>
            </li>
          ) : null}
        </ul>
      </div>
    );
  } else return null;
};

export default AuthMenuPanel;
