/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";

// import resources ------------------------------
import { useTranslation } from "react-i18next";
import { assetImages } from "constants";

//import * as url from "helper/UrlHelper";
//import { getData, postData } from "utils/Gateway";

const Failure = () => {
  return (
    <section className="pt-70">
      <div className="container-fluid">
        <div className="py-4 px-3 cart_sec">
          <div className="row">
            <div className="col-lg-12">
              <div className="cart_leftpnl p-4 rounded-10 overflow-hidden">
                <div className="alert_msg_top bg-extra-light p-4 d-flex align-items-center justify-content-between gap-2 mb-4">
                  <div className="left_icon_msg d-flex align-items-center gap-2">
                    <div className="icon d-flex align-items-center justify-content-center">
                      <img src={assetImages.oopsIcon} alt="success" />
                    </div>
                    <div className="icon_info_text">
                      <h3 className="text-black fw-semibold mb-1 fs-xl">
                        Oops! ...
                      </h3>
                      <p className="text-gray fs-sm">
                        Something error in you payment.
                      </p>
                    </div>
                  </div>
                  <div className="right_success_btn">
                    <Link to="/cart" className="btn">
                      Like to Retry?
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Failure;
