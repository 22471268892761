import { assetImages } from "constants";
import * as url from "helper/UrlHelper";

const WinnerTab = ({ winnerList = [] }) => {
  return (
    <div className="post_item_outer">
      {winnerList.length === 0 ? (
        <div className="pnl_mdlpnl w-100">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-md text-gray fw-semibold">
                Sorry....! Winners not availbale at this moment
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="post_item">
          {winnerList.map((jobResponse, index) => {
            return (
              <div className="white_shadow_bx people_bx" key={index}>
                <div className="people_row">
                  <div className="left_people">
                    <div className="people_profile">
                      <img
                        src={
                          jobResponse.candidateimage === ""
                            ? assetImages.defaultUser
                            : url.SERVER_URL + jobResponse.candidateimage
                        }
                        alt={jobResponse.cvcandidatename}
                      />
                    </div>
                    <div className="people_profile_info">
                      <h3>{jobResponse.cvcandidatename}</h3>
                      <h5>{jobResponse.cvcandidateemail}</h5>
                      <h6 className="fw-semibold text-gray">
                        {jobResponse.candidateposition}
                      </h6>
                      {/* <div className="event_tags">
                      <ul className="list_stye_none mb-0">
                        <li>
                          <i className="material-icons-outlined ornge_icon">
                            star
                          </i>
                          <span className="rating">
                            4.5 <em>(32)</em>
                          </span>
                        </li>
                      </ul>
                    </div> */}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default WinnerTab;
