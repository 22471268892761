import { assetImages } from "constants";
import React from "react";
// import use translation ------------------------------
import { useTranslation } from "react-i18next";

const SustainabilityWorkProcess = () => {
  const { t } = useTranslation(); // translation marker
  return (
    <section className="process_work_sec we_doSec pd_130">
      <div className="container">
        <div className="page_hdng dark_grnheading text-center">
          <h5>{t("Work Process")}</h5>
          <h2>{t("How it work")}</h2>
        </div>
        <div className="offer_col_outer">
          <div className="ofr_row">
            <div className="row">
              <div className="col-lg-6">
                <div className="ofr_pic">
                  <img src={assetImages.processWork1} alt="" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="ofr_textpnl">
                  <div className="number_count">
                    <img src={assetImages.img01} alt="" />
                  </div>
                  <div className="ofr_icon blue_circle">
                    <span className="material-symbols-outlined">
                      touchpad_mouse
                    </span>
                  </div>
                  <div className="ofr_hdng">
                    <h3>{t("Sign Up")}</h3>
                  </div>
                  <div className="ofr_para">
                    <p>{t("Fill out our form and join the community.")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ofr_row">
            <div className="row">
              <div className="col-lg-6">
                <div className="ofr_pic">
                  <img src={assetImages.processWork2} alt="" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="ofr_textpnl">
                  <div className="number_count">
                    <img src={assetImages.img02} alt="" />
                  </div>
                  <div className="ofr_icon blue_circle">
                    <span className="material-symbols-outlined">newsmode</span>
                  </div>
                  <div className="ofr_hdng">
                    <h3>
                      {t("We Publish Your")} <br /> {t("Success Story")}
                    </h3>
                  </div>
                  <div className="ofr_para">
                    <p>
                      {t("Highlight your ESG achievements and commitments.")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ofr_row">
            <div className="row">
              <div className="col-lg-6">
                <div className="ofr_pic">
                  <img src={assetImages.processWork3} alt="" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="ofr_textpnl">
                  <div className="number_count">
                    <img src={assetImages.img03} alt="" />
                  </div>
                  <div className="ofr_icon blue_circle">
                    <span className="material-symbols-outlined">
                      monitoring
                    </span>
                  </div>
                  <div className="ofr_hdng">
                    <h3>
                      {t("Connect and")} <br /> {t("Grow")}
                    </h3>
                  </div>
                  <div className="ofr_para">
                    <p>
                      {t(
                        "Attend events, connect with other companies, and strengthen your network."
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SustainabilityWorkProcess;
