/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
// import use translation ------------------------------
import { useTranslation } from "react-i18next";
/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData } from "utils/Gateway";
import { assetImages } from "constants";
import { fileDownloadHandler } from "utils/DownloadFile";

const AboutTab = ({
  eventDetails,
  eventSummary,
  eventCategories,
  eventInterests,
  eventSearchings,
  eventDocs,
}) => {
  const { t } = useTranslation(); // translation marker
  return (
    <div className="people_detailspnl">
      <div className="people_details_bx white_shadow_bx">
        <div className="details_hdng">
          <h4>{t("Summary")}</h4>
        </div>
        <div className="results_para">
          <p>{eventSummary}</p>
        </div>
      </div>
      <div className="people_details_bx white_shadow_bx">
        <div className="details_hdng">
          <h4>{t("Details")}</h4>
        </div>
        <div className="results_para">
          <p dangerouslySetInnerHTML={{ __html: eventDetails }}></p>
        </div>
      </div>
      <div className="people_details_bx white_shadow_bx">
        <div className="accordian_area">
          <div className="accordion accordion-flush" id="faq-accordian">
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                >
                  <span>{t("Category")}</span>
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                className="accordion-collapse collapse"
                data-bs-parent="#faq-accordian"
              >
                <div className="accordion-body">
                  <div className="experts_skill">
                    <ul className="list_stye_none">
                      {eventCategories.map((category, index) => {
                        return (
                          <li key={index}>
                            <Link to="#">{category.label}</Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTwo"
                  aria-expanded="false"
                >
                  <span>{t("Interest")}</span>
                </button>
              </h2>
              <div
                id="flush-collapseTwo"
                className="accordion-collapse collapse"
                data-bs-parent="#faq-accordian"
              >
                <div className="accordion-body">
                  <div className="experts_skill">
                    <ul className="list_stye_none">
                      {eventInterests.map((item, index) => {
                        return (
                          <li key={index}>
                            <Link to="#">{item.label}</Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseThree"
                  aria-expanded="false"
                >
                  <span>{t("Search")}</span>
                </button>
              </h2>
              <div
                id="flush-collapseThree"
                className="accordion-collapse collapse"
                data-bs-parent="#faq-accordian"
              >
                <div className="accordion-body">
                  <div className="experts_skill">
                    <ul className="list_stye_none">
                      {eventSearchings.map((item, index) => {
                        return (
                          <li key={index}>
                            <Link to="#">{item.label}</Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="people_details_bx white_shadow_bx">
        <div className="details_hdng">
          <h4>Sponsors</h4>
        </div>
        <div className="sponsor_cmpny">
          <ul className="list_stye_none">
            <li>
              <Link to="#">
                <i>
                  <img src="/images/sponsor1.svg" alt="" />
                </i>
                <span>Microsoft</span>
              </Link>
            </li>
            <li>
              <Link to="#">
                <i>
                  <img src="/images/sponsor2.svg" alt="" />
                </i>
                <span>Dropbox</span>
              </Link>
            </li>
            <li>
              <Link to="#">
                <i>
                  <img src="/images/sponsor3.svg" alt="" />
                </i>
                <span>Google</span>
              </Link>
            </li>
          </ul>
        </div>
      </div> */}
      <div className="people_details_bx white_shadow_bx">
        <div className="details_hdng">
          <h4>{t("Docs")}</h4>
        </div>
        {/* <div className="upload_area">
          {eventDocs.map((doc, index) => {
            return (
              <div className="upload_item" key={index}>
                <div className="uploaded_innrBx">
                  <div className="upload_icon">
                    <img
                      src={
                        doc.path.split(".").pop() == "pdf"
                          ? assetImages.PdfImg
                          : assetImages.DocImg
                      }
                      alt=""
                    />
                  </div>
                  <div className="upload_txt">
                    <h4>{doc.name}</h4>
                    
                  </div>
                </div>
              </div>
            );
          })}
        </div> */}

        <ul className="uploaded-files d-flex flex-wrap gap-3 p-0 m-0 mt-3">
          {eventDocs.map((file, index) => {
            return (
              <li
                className="file d-flex gap-3 align-items-center px-3 py-2 border border-gray rounded-3"
                key={index}
              >
                <p className="fw-medium">{file.name}</p>
                <Link to="#" onClick={() => fileDownloadHandler(file)}>
                  <span className="d-block material-icons-outlined">
                    download
                  </span>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default AboutTab;
