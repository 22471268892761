/* eslint-disable */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ApplyJobModal from "../Modal/ApplyJobModal";
// import use translation ------------------------------
import { useTranslation } from "react-i18next";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData, putData } from "utils/Gateway";

const JobLandingRightPanel = ({ selectedJobId }) => {
  const { t } = useTranslation(); // translation marker

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [isLoading, setisLoading] = useState(false);

  const [title, setTitle] = useState("");
  const [code, setcode] = useState("");
  const [summary, setsummary] = useState("");
  const [details, setdetails] = useState("");
  const [aboutCompany, setaboutCompany] = useState("");
  const [aboutPosition, setaboutPosition] = useState("");
  const [requiredSkills, setrequiredSkills] = useState([]);
  const [optionalSkills, setoptionalSkills] = useState([]);
  const [remoteJobValue, setremoteJobValue] = useState(null);
  const [jobTypeValue, setjobTypeValue] = useState(null);
  const [experienceAsValue, setexperienceAsValue] = useState(null);
  const [experienceYear, setexperienceYear] = useState("");
  const [experienceMonth, setexperienceMonth] = useState("");
  const [currencyValue, setcurrencyValue] = useState(null);
  const [paymentDurationValue, setpaymentDurationValue] = useState(null);
  const [minSalary, setminSalary] = useState("");
  const [maxSalary, setmaxSalary] = useState("");
  const [companyValue, setcompanyValue] = useState(null);
  const [companyName, setcompanyName] = useState("");
  const [companyAdress, setcompanyAdress] = useState("");
  const [stateName, setstateName] = useState("");
  const [cityName, setcityName] = useState("");
  const [country, setcountry] = useState("");
  const [zipcode, setzipcode] = useState("");
  const [uploadedDocFiles, setUploadedDocFiles] = useState([]);
  const [uploadedDocFileIds, setUploadedDocFileIds] = useState([]);
  const [isApplied, setIsApplied] = useState(false);
  const [applicationCount, setapplicationCount] = useState(0);

  //function for get project details
  const getJobDetails = async () => {
    setisLoading(true);
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_CHALLENGE_DETAILS +
        `/${selectedJobId}?token=${token}`;

      const response = await getData(requestUrl);
      setisLoading(false);
      console.log(response);
      if (response.status && response.data) {
        const data = response.data;
        setTitle(data.name);
        setcode(data.jobcode);
        setsummary(data.summary);
        setdetails(data.description);
        setaboutCompany(data.aboutcompany);
        setaboutPosition(data.aboutposition);
        setrequiredSkills(data.requiredskillsdata);
        setoptionalSkills(data.optionalskillsdata);
        setexperienceYear(data.experienceyear);
        setexperienceMonth(data.experienceMonth);

        setminSalary(data.minsalary);
        setmaxSalary(data.maxsalary);

        setcompanyName(data.companystring);
        setcompanyAdress(data.location);
        setzipcode(data.zipcode);
        setcityName(data.city);
        setstateName(data.state);
        setcountry(data.country);

        //assign uploaded files
        setUploadedDocFiles(response?.data?.uploadedfiles);
        setUploadedDocFileIds(response?.data?.uploadedfileids);
        setIsApplied(response.data.isappiled);
        setapplicationCount(response.data.totalapplicationcount);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (selectedJobId) {
      getJobDetails();
    }
  }, [selectedJobId]);

  return (
    <div className="results_rightPnl white_shadow_bx">
      {isLoading ? (
        <>
          <div className="progres_tagsouter">
            <div className="tags_lftpnl">
              <div className="event_tags">
                <ul className="list_stye_none">
                  <span
                    className="placeholder"
                    style={{ width: "60px" }}
                  ></span>
                  <span
                    className="placeholder"
                    style={{ width: "60px" }}
                  ></span>
                  <span
                    className="placeholder"
                    style={{ width: "60px" }}
                  ></span>
                </ul>
              </div>
            </div>
            <div className="tags_prgrsspnl">
              <h5>
                <span className="placeholder" style={{ width: "60px" }}></span>{" "}
                <span className="placeholder" style={{ width: "40px" }}></span>
              </h5>
              <div className="progess_outer">
                <span className="progress_bar " style={{ width: "40%" }}></span>
              </div>
            </div>
          </div>
          <div className="job_hdng">
            <h3>
              <span className="placeholder" style={{ width: "80%" }}></span>
            </h3>
            <h5>
              <span
                className="placeholder"
                style={{ width: "20%", color: "#AAB3BD" }}
              ></span>
            </h5>
          </div>

          <div className="results_innr">
            <div className="results_item">
              <div className="results_para">
                <h3>
                  <span className="placeholder" style={{ width: "20%" }}></span>
                </h3>
                <p>
                  <span
                    className="placeholder"
                    style={{ width: "100%" }}
                  ></span>
                  <span
                    className="placeholder"
                    style={{ width: "100%" }}
                  ></span>
                  <span
                    className="placeholder"
                    style={{ width: "100%" }}
                  ></span>
                  <span className="placeholder" style={{ width: "60%" }}></span>
                </p>
              </div>
            </div>
            <div className="results_item">
              <div className="results_para">
                <h3>
                  <span className="placeholder" style={{ width: "20%" }}></span>
                </h3>
                <p>
                  <span
                    className="placeholder"
                    style={{ width: "100%" }}
                  ></span>
                  <span
                    className="placeholder"
                    style={{ width: "100%" }}
                  ></span>
                  <span
                    className="placeholder"
                    style={{ width: "100%" }}
                  ></span>
                  <span className="placeholder" style={{ width: "60%" }}></span>
                </p>
              </div>
            </div>
            <div className="results_item">
              <div className="results_para">
                <h3>
                  <span className="placeholder" style={{ width: "20%" }}></span>
                </h3>
                <p>
                  <span
                    className="placeholder"
                    style={{ width: "100%" }}
                  ></span>
                  <span
                    className="placeholder"
                    style={{ width: "100%" }}
                  ></span>
                  <span
                    className="placeholder"
                    style={{ width: "100%" }}
                  ></span>
                  <span className="placeholder" style={{ width: "60%" }}></span>
                </p>
              </div>
            </div>

            <div className="results_item">
              <h3>
                <span className="placeholder" style={{ width: "20%" }}></span>
              </h3>
              <div className="results_para">
                <p>
                  <span
                    className="placeholder"
                    style={{ width: "60px" }}
                  ></span>{" "}
                  —
                  <span
                    className="placeholder ms-1"
                    style={{ width: "60px" }}
                  ></span>
                </p>
              </div>
            </div>
          </div>

          <div className="result_btnArea">
            <span
              className="placeholder btn"
              style={{
                height: "50px",
                borderRadius: "30px",
              }}
            ></span>

            <span
              className="placeholder"
              style={{
                width: "110px",
                height: "50px",
                borderRadius: "30px",
              }}
            ></span>
          </div>
        </>
      ) : (
        <>
          <div className="progres_tagsouter">
            <div className="tags_lftpnl">
              <div className="event_tags">
                <ul className="list_stye_none">
                  {requiredSkills.map((skill, index) => {
                    return <li key={index}>{skill.label}</li>;
                  })}
                  {optionalSkills.map((skill, index) => {
                    return <li key={index}>{skill.label}</li>;
                  })}
                </ul>
              </div>
            </div>
            <div className="tags_prgrsspnl">
              <h5>
                {applicationCount} applied
                {/* <span>(40%)</span> */}
              </h5>
              {/* <div className="progess_outer">
                <span className="progress_bar" style={{ width: "40%" }}></span>
              </div> */}
            </div>
          </div>
          <div className="job_hdng">
            <h3>
              <Link
                className="text-decoration-none text-black"
                to={`/jobs/details/${selectedJobId}`}
              >
                {title}
              </Link>
            </h3>
            <h5>{code}</h5>
          </div>

          <div className="results_innr">
            <div className="results_item">
              <div className="results_para">
                <h3>Summary</h3>

                <p>{summary}</p>
              </div>
            </div>
            <div className="results_item">
              <h3>Details</h3>
              <div className="results_para">
                <p dangerouslySetInnerHTML={{ __html: details }}></p>
              </div>
            </div>

            <div className="results_item">
              <h3>About Position</h3>
              <div className="results_para">
                <p dangerouslySetInnerHTML={{ __html: aboutPosition }}></p>
              </div>
            </div>

            <div className="results_item">
              <h3>About Company</h3>
              <div className="results_para">
                <p dangerouslySetInnerHTML={{ __html: aboutCompany }}></p>
              </div>
            </div>

            <div className="results_item">
              <h3>Salary and compensation</h3>
              <div className="results_para">
                <p>
                  €{minSalary} — €{maxSalary}
                </p>
              </div>
            </div>
          </div>

          <div className="result_btnArea">
            {/* <Link
              to={isApplied ? "#" : `/cvbuilder/${selectedJobId}`}
              className="btn"
            >
              {isApplied ? "Applied" : "Apply Now"}
            </Link> */}
            <Link to={`/cvbuilder/${selectedJobId}`} className="btn">
              Apply Now
            </Link>
            <Link to={`/jobs/details/${selectedJobId}`} className="btn outline">
              More Details
            </Link>
          </div>
        </>
      )}
    </div>
  );
};

export default JobLandingRightPanel;
