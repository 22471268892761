/* eslint-disable */

import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
// import use translation ------------------------------
import { useTranslation } from "react-i18next";
import LoginModal from "./Modals/LoginModal";
import RegisterModal from "./Modals/RegisterModal";
import VideoModal from "./Modals/VideoModal";
import ForgotPasswordModal from "./Modals/ForgotPasswordModal";
import ResetPassowordModal from "./Modals/ResetPassowordModal";
import CongratsModal from "./Modals/CongratsModal";
import OtpConfirmLoginModal from "./Modals/OtpConfirmLoginModal";
import OtpValidateModal from "./Modals/OtpValidateModal";
import LoginSuccessModal from "./Modals/LoginSuccessModal";

//import languages ------------------------------------------------
import languageOptions from "../../Data/Language.json";

const Footer = () => {
  const { i18n, t } = useTranslation(); // translation marker
  const [languageValue, setLanguageValue] = useState(null);

  //function for select language
  const languageSelectionHandler = (val) => {
    setLanguageValue(val);
    i18n.changeLanguage(val.value);
  };

  useEffect(() => {
    for (let language of languageOptions) {
      if (i18n.language.toString() === language.value.toString()) {
        languageSelectionHandler(language);
      }
    }
  }, []);

  return (
    <footer className="footer_outer">
      <div className="help_dots ftr_dots1">
        <img src="/images/ftr-dots1.png" alt="" />
      </div>
      <div className="help_dots ftr_dots2">
        <img src="/images/ftr-dots2.png" alt="" />
      </div>
      <div className="container">
        <div className="footer_top">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="ftr_logopnl">
                <div className="footer_logo">
                  <Link to="/">
                    <img src="/images/logo.svg" alt="" />
                  </Link>
                </div>
                <div className="ftr_social">
                  <ul className="list_stye_none">
                    <li>
                      <Link to="#">
                        <img src="/images/twitter.svg" alt="" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <img src="/images/instagram.svg" alt="" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <img src="/images/facebook.svg" alt="" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="ftr_item">
                <h3>Links</h3>
                <div className="ftr_links">
                  <ul className="list_stye_none d-flex flex-wrap">
                    <li>
                      <Link to="#">{t("KPIs")}</Link>
                    </li>
                    <li>
                      <Link to="#">{t("Cookies policy")}</Link>
                    </li>
                    <li>
                      <Link to="#">{t("Team")}</Link>
                    </li>
                    <li>
                      <Link to="#">{t("Unsubscribe")}</Link>
                    </li>
                    <li>
                      <Link to="#">{t("Legal Notice")}</Link>
                    </li>
                    <li>
                      <Link to="#">{t("Purchase and returns policy")}</Link>
                    </li>
                    <li>
                      <Link to="#">{t("Privacy Policy")}</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer_bttm">
          <div className="row">
            <div className="col-lg-6">
              <div className="copyright">
                <p>
                  © Copyright 2023.{" "}
                  {t("Lobees Professional Services All rights reserved.")}
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="ftr_socialpnl">
                <div className="flg_selctBtn">
                  <Select
                    placeholder={t("Select Language")}
                    menuPlacement="top"
                    options={languageOptions}
                    value={languageValue}
                    onChange={(val) => {
                      languageSelectionHandler(val);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ==== all modals render here ==== */}
      {/* login modal */}
      <LoginModal />

      {/* login success modal */}
      <LoginSuccessModal />

      {/* otplogin modal for login with otp */}
      <OtpConfirmLoginModal />

      {/* sign up modal */}
      <RegisterModal />

      {/* forgot password modal */}
      <ForgotPasswordModal />

      {/* otpvalidation modal for confirm email with otp input */}
      <OtpValidateModal />

      {/* reset password modal */}
      <ResetPassowordModal />

      {/* congrats modal */}
      <CongratsModal />

      {/* video modal in home */}
      <VideoModal />
    </footer>
  );
};

export default Footer;
