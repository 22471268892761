import React from 'react';
// import use translation ------------------------------
import { useTranslation } from 'react-i18next';

const InterestAddedSuccessModal = () => {
  const { t } = useTranslation(); // translation marker
  return (
    <div className="post_modal success_greetings_mdl">
      <div className="modal fade" id="interest_success_mdl">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="gretings_icon">
                <span className="material-icons-outlined"> verified </span>
              </div>
              <div className="gretings_hdng">
                <h3>{t('Interest Added Successfully')}</h3>
                <h6>{t('Successfully updated')}</h6>
              </div>
              <div className="post_btn post_bnts_outer">
                <button className="btn" data-bs-dismiss="modal">
                  {t('Done')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InterestAddedSuccessModal;
