/* eslint-disable */
import { assetImages } from "constants";
import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

import * as url from "helper/UrlHelper";

const BlogCard = ({ blog }) => {
  return (
    <div className="blogs_item">
      <div className="blog_pic">
        {blog.coverimage == "" ? (
          <span
            className="placeholder"
            style={{
              width: "100%",
              height: "300px",
              borderRadius: "20px",
              marginBottom: "16px",
            }}
          ></span>
        ) : (
          <Link to={`/blogdetails/${blog._id}`}>
            <img src={url.SERVER_URL + blog.coverimage} alt="blogImage" />
          </Link>
        )}
      </div>
      <div className="blog_content">
        <h5>{blog.categorystring}</h5>
        <Link to={`/blogdetails/${blog._id}`}>{blog.name}</Link>
        <p>{blog.summary}....</p>
        <div className="bttm_step_cloude">
          <div className="cloud_icon">
            <span className="">
              <img
                className="rounded-circle"
                src={
                  blog.authorimage == ""
                    ? assetImages.defaultUser
                    : url.SERVER_URL + blog.authorimage
                }
                alt="author"
              />
            </span>
          </div>
          <div className="cloud_icon_text">
            <h4>{blog.authorname}</h4>
            <h6>{blog.createddate}</h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
