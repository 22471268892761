/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData } from "utils/Gateway";

//import images
import { assetImages } from "constants";
import { ecosystemSlug } from "Config/Config";

import BlogAllListPlaceHolder from "../BlogPlaceHolder/BlogAllListPlaceHolder";
import BlogCard from "../common/BlogCard";

const BlogListAllCategory = () => {
  const listGlowCount = 5;

  const [isLoading, setIsLoading] = useState(false);
  const [blogList, setBlogList] = useState([]);

  //function for get all blogs
  const getallBlogs = async () => {
    try {
      setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_BLOG_WITH_CATEGORY +
        `?ecosystemslug=${ecosystemSlug}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setIsLoading(false);
        setBlogList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getallBlogs();
  }, []);

  return (
    <section
      className={`blog_landing_total ${isLoading ? "placeholder-glow" : ""}`}
    >
      <div className="container">
        {isLoading ? (
          <BlogAllListPlaceHolder />
        ) : (
          <div className="landing_outer">
            {blogList.map((blogData, index) => {
              return (
                <div
                  className="landing_row"
                  key={index}
                  id={blogData.categoryname}
                >
                  {blogData.bloglist.length > 0 ? (
                    <div className="landing_hdng_row">
                      <div className="page_hdng">
                        <h2>{blogData.categoryname.toUpperCase()}</h2>
                      </div>
                      <div className="details_arw">
                        <Link to={`/blog/${blogData.slug}`}>
                          <span className="material-icons-outlined">
                            arrow_forward
                          </span>
                        </Link>
                      </div>
                    </div>
                  ) : null}

                  {blogData.bloglist.length > 0 ? (
                    <div className="blog_listing">
                      <div className="row">
                        {blogData.bloglist.map((blog, index2) => {
                          return (
                            <div className="col-lg-4 col-md-6" key={index2}>
                              <BlogCard blog={blog} />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : null}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </section>
  );
};

export default BlogListAllCategory;
