import React from "react";

const CourseListPlaceHolder = () => {
  return (
    <div className="people_row course_row groups_item">
      <div className="course_left">
        <span
          className="placeholder"
          style={{ width: "128px", height: "128px" }}
        ></span>
      </div>
      <div className="course_right">
        <div className="top_company_row">
          <div className="company_logotxt">
            <span className="placeholder" style={{ width: "100px" }}></span>
          </div>
          <div className="rating_tags d-flex align-items-center">
            <span className="placeholder" style={{ width: "80px" }}></span>
            <span className="placeholder" style={{ width: "30px" }}></span>
          </div>
        </div>
        <div className="event_hdng">
          <h3>
            <span className="placeholder" style={{ width: "70%" }}></span>
          </h3>
        </div>
        <div className="event_tags">
          <ul className="list_stye_none">
            <span className="placeholder" style={{ width: "70px" }}></span>
            <span className="placeholder" style={{ width: "70px" }}></span>
            <span className="placeholder" style={{ width: "70px" }}></span>
          </ul>
        </div>
        <div className="details_btn">
          <span
            className="placeholder"
            style={{
              width: "110px",
              height: "40px",
              borderRadius: "30px",
            }}
          ></span>
        </div>
      </div>
    </div>
  );
};

export default CourseListPlaceHolder;
