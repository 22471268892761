import React from 'react';
import { Link } from 'react-router-dom';
import ForgetPasswordModal from '../Modals/ForgetPasswordModal';
import VerifyEmailModal from '../Modals/VerifyEmailModal';

const PasswordResetRightPanel = () => {
  return (
    <div className="pnl_mdlpnl">
      <div className="profile_information white_shadow_bx">
        <div className="info_hdng info_mainhdng">
          <h2>Password Settings</h2>
        </div>
        <div className="experience_bxOuter">
          <div className="experience_bxItem">
            <div className="profile_info_pnl">
              <div className="profile_info_row">
                <label>Current Password</label>
                <div className="profile_inner_info">
                  <div className="left_profile_info">
                    <input
                      type="password"
                      value="12345"
                      className="fill_input"
                      placeholder="Enter Password"
                    />
                  </div>
                </div>
              </div>
              <div className="profile_info_row">
                <label>New Password</label>
                <div className="profile_inner_info">
                  <div className="left_profile_info">
                    <input
                      type="password"
                      value=""
                      className="fill_input"
                      placeholder="Enter New Password"
                    />
                  </div>
                </div>
              </div>
              <div className="profile_info_row">
                <label>New Password</label>
                <div className="profile_inner_info">
                  <div className="left_profile_info">
                    <input
                      type="password"
                      value=""
                      className="fill_input"
                      placeholder="Re-enter Current Password"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="password_btnOuter">
              <div className="password_btn">
                <Link to="#" className="btn outline">
                  Update Password
                </Link>
              </div>
              <div className="password_btn">
                <Link
                  to="#"
                  className="btn gray_btn"
                  data-bs-toggle="modal"
                  data-bs-target="#forgot_mdl"
                >
                  <i className="material-icons-outlined">info</i>
                  Forgot Password
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* --------- ForgetPasswordModal ---------- */}
      <ForgetPasswordModal />

      {/* ------------- VerifyEmailModal -------------- */}
      <VerifyEmailModal />
    </div>
  );
};

export default PasswordResetRightPanel;
