/* eslint-disable */
import AuthHeader from "components/common/AuthHeader";
import React, { useContext, useEffect } from "react";

import JobLandingBody from "components/JobsComponents/JobLandingComponents/JobLandingBody/JobLandingBody";

const JobLanding = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <AuthHeader />

      {/* --------- body section ------------ */}
      <JobLandingBody />
    </>
  );
};

export default JobLanding;
