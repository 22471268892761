import React from 'react';
import { Link } from 'react-router-dom';
// import use translation ------------------------------
import { useTranslation } from 'react-i18next';

const KitDigitalWorkProcess = () => {
  const { t } = useTranslation(); // translation marker
  return (
    <section className="how_works_area pd_130">
      <div className="container">
        {/* --- desc section start --- */}
        <div className="howwrks_outer talent_outer">
          <div className="row">
            <div className="col-lg-6">
              <div className="tab_leftpnl">
                <div className="page_hdng">
                  <h5>{t('Let`s Grow')}</h5>
                  <h2>{t('How does the Digital Kit work')}?</h2>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="tab_rightpnl">
                <div className="kit_para">
                  <p>
                    {t(
                      'Offering economic aid, framed in the Digital Spain 2025 agenda, through different calls that are already underway and whose objective is to contribute to the modernization of the Spanish productive fabric in the coming years.'
                    )}
                  </p>
                  <p>
                    {t(
                      'Any SME with a maximum of 50 employees can benefit from the Digital Kit. The amount of aid depends on the categories and segments of the beneficiary companies.'
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* --- desc section end --- */}

        {/* --- price section start --- */}
        <div className="digital_price_outer">
          <div className="row">
            <div className="col-lg-4">
              <div className="digital_pricebx">
                <div className="price_icon">
                  <span className="material-icons-outlined">train</span>
                </div>
                <div className="price_text_area">
                  <h6>{t('Up to')}</h6>
                  <h2>12.000€</h2>
                  <p>{t('SMEs with between 1O and 50 employees')}</p>
                  <Link to="#">{t('Segment')} 1</Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="digital_pricebx">
                <div className="price_icon">
                  <span className="material-icons-outlined">
                    airport_shuttle
                  </span>
                </div>
                <div className="price_text_area">
                  <h6>{t('Up to')}</h6>
                  <h2>6.000€</h2>
                  <p>{t('Between 3 and 10 employees')}</p>
                  <Link to="#">{t('Segment')} 2</Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="digital_pricebx">
                <div className="price_icon">
                  <span className="material-icons-outlined">pedal_bike</span>
                </div>
                <div className="price_text_area">
                  <h6>{t('Up to')}</h6>
                  <h2>2.000€</h2>
                  <p>{t('Less than 3 employees')}</p>
                  <Link to="#">{t('Segment')} 3</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* --- price section end --- */}
      </div>
    </section>
  );
};

export default KitDigitalWorkProcess;
