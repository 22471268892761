/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import InviteMessageModal from "../Modal/InviteMessageModal";

/*import url and gateway methods */
import { getData, putData, deleteData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

//import get current date in string
import { getCurrentDateInString } from "utils/getcurrentdatestring";
import RecievedTab from "../Tabs/RecievedTab";
import SentTab from "../Tabs/SentTab";

const FriendRequestListBody = () => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [showRecievedTab, setshowRecievedTab] = useState(false);
  const [showSentTab, setshowSentTab] = useState(false);

  const [modalMessage, setModalMessage] = useState();
  const [modalSubMessage, setModalSubMessage] = useState("");
  const [recievedInviteList, setRecievedInviteList] = useState([]);
  const [sentRequestList, setSentRequestList] = useState([]);

  const toggleTabHandler = (tabName) => {
    setshowRecievedTab(false);
    setshowSentTab(false);

    if (tabName == "recieved") {
      setshowRecievedTab(true);
    }

    if (tabName == "sent") {
      setshowSentTab(true);
    }
  };

  //function for get all invite
  const getAllInvites = async () => {
    try {
      let requestURL =
        url.API_BASE_URL + url.API_GET_ALL_RECIEVED_INVITE + `?token=${token}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        setRecievedInviteList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //get all sent requests
  const getAllSentRequests = async () => {
    try {
      let requestURL =
        url.API_BASE_URL + url.API_GET_ALL_SEND_INVITE + `?token=${token}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        setSentRequestList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for accept invite
  const acceptHandler = async (contactId) => {
    try {
      let inviteData = {
        memberstatus: "1",
        connecteddate: getCurrentDateInString(),
      };

      let requestURL =
        url.API_BASE_URL +
        url.API_ACCEPT_INVITE +
        `/${contactId}?token=${token}`;

      const response = await putData(requestURL, inviteData);

      if (response.status) {
        setModalMessage("Invite accpet Successfully");
        setModalSubMessage("Invite request has been successfully added");
        //show register modal
        let myModal = new bootstrap.Modal(
          document.getElementById("invite_success_mdl")
        );
        myModal.show();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for reject invite
  const rejectInviteHandler = async (personId) => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_REJECT_CONTACT_REQUEST +
        `?token=${token}&personid=${personId}`;

      const response = await deleteData(requestURL);

      console.log(response);

      if (response.status) {
        setModalMessage("Invite rejected Successfully");
        setModalSubMessage("Invite request has been successfully rejected");
        //show register modal
        let myModal = new bootstrap.Modal(
          document.getElementById("invite_success_mdl")
        );
        myModal.show();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const afterModalClose = () => {
    setModalMessage("");
    setModalSubMessage("");
    getAllInvites();
  };

  useEffect(() => {
    getAllInvites();
    getAllSentRequests();
    setshowRecievedTab(true);
  }, []);

  return (
    <section className="feed_pnltotal">
      <div className="container-fluid">
        <div className="notication_bx white_shadow_bx">
          <div className="contact_header">
            <div className="post_hdng mb-4">
              <h4>Manage Requests</h4>
            </div>
            {/* <div className="contacts_srch_right">
              <ul className="list_stye_none">
                <li>
                  <Link to="#">
                    <i className="material-icons-outlined">search</i>
                  </Link>
                </li>
              </ul>
            </div> */}
            <div className="tab_links people_tablinks">
              <ul className="nav nav-tabs list_stye_none">
                <li className="nav-item">
                  <Link
                    className={
                      showRecievedTab ? "nav-link active show" : "nav-link"
                    }
                    // data-bs-toggle="tab"
                    to="#"
                    onClick={() => {
                      toggleTabHandler("recieved");
                    }}
                  >
                    Recieved
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    className={
                      showSentTab ? "nav-link active show" : "nav-link"
                    }
                    // data-bs-toggle="tab"
                    to="#"
                    onClick={() => {
                      toggleTabHandler("sent");
                    }}
                  >
                    Sent
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          {showRecievedTab && (
            <RecievedTab
              inviteList={recievedInviteList}
              acceptHandler={acceptHandler}
              rejectInviteHandler={rejectInviteHandler}
            />
          )}

          {showSentTab && <SentTab inviteList={sentRequestList} />}

          {/* <div className="notification_inner"> */}
          {/* <div className="post_item"> */}
          {/* {inviteList.map((invite, index) => {
                return (
                  <div className="white_shadow_bx people_bx" key={index}>
                    <div className="people_row">
                      <div className="left_people">
                        <div className="people_profile">
                          <img src={invite.senderimage} alt="" />
                        </div>
                        <div className="people_profile_info">
                          <h3>{invite.sendername}</h3>
                          <h5>{invite.senderposition}</h5>
                          <div className="event_tags">
                            <ul className="list_stye_none mb-0">
                              <li>
                                <i className="material-icons-outlined ornge_icon">
                                  star
                                </i>
                                <span className="rating">
                                  {invite.senderratings}{" "}
                                  <em>({invite.senderreviews})</em>
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="right_people_btn">
                        <button
                          className="btn gray_btn me-1"
                          onClick={() => {
                            rejectInviteHandler(invite.owner._id);
                          }}
                        >
                          Reject
                        </button>
                        <button
                          className="btn outline"
                          onClick={() => {
                            acceptHandler(invite._id);
                          }}
                        >
                          Accept
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })} */}
          {/* <div className="white_shadow_bx people_bx">
              <div className="people_row">
                <div className="left_people">
                  <div className="people_profile">
                    <img src="images/people2.png" alt="" />
                  </div>
                  <div className="people_profile_info">
                    <h3>Clare MacLearn</h3>
                    <h5>Sales Manager at Lobees Ecosystem</h5>
                    <div className="event_tags">
                      <ul className="list_stye_none mb-0">
                        <li>
                          <i className="material-icons-outlined ornge_icon">
                            star
                          </i>
                          <span className="rating">
                            4.5 <em>(32)</em>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="right_people_btn">
                  <Link to="#" className="btn gray_btn">
                    Reject
                  </Link>
                  <Link to="#" className="btn outline">
                    Accept
                  </Link>
                </div>
              </div>
            </div> */}
          {/* <div className="white_shadow_bx people_bx">
              <div className="people_row">
                <div className="left_people">
                  <div className="people_profile">
                    <img src="images/people3.png" alt="" />
                  </div>
                  <div className="people_profile_info">
                    <h3>Nasino Yangchang</h3>
                    <h5>Sales Manager at Lobees Ecosystem</h5>
                    <div className="event_tags">
                      <ul className="list_stye_none mb-0">
                        <li>
                          <i className="material-icons-outlined ornge_icon">
                            star
                          </i>
                          <span className="rating">
                            4.5 <em>(32)</em>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="right_people_btn">
                  <Link to="#" className="btn gray_btn rejected_btn">
                    <i className="material-icons"> cancel </i>
                    <span>Rejected</span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="white_shadow_bx people_bx">
              <div className="people_row">
                <div className="left_people">
                  <div className="people_profile">
                    <img src="images/people1.png" alt="" />
                  </div>
                  <div className="people_profile_info">
                    <h3>Pierre Gagnaire</h3>
                    <h5>Sales Manager at Lobees Ecosystem</h5>
                    <div className="event_tags">
                      <ul className="list_stye_none mb-0">
                        <li>
                          <i className="material-icons-outlined ornge_icon">
                            star
                          </i>
                          <span className="rating">
                            4.5 <em>(32)</em>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="right_people_btn">
                  <Link to="#" className="btn gray_btn accepted_btn">
                    <i className="material-icons"> check_circle </i>
                    <span>Accepted</span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="white_shadow_bx people_bx">
              <div className="people_row">
                <div className="left_people">
                  <div className="people_profile">
                    <img src="images/people1.png" alt="" />
                  </div>
                  <div className="people_profile_info">
                    <h3>Harry Nonalarm</h3>
                    <h5>Sales Manager at Lobees Ecosystem</h5>
                    <div className="event_tags">
                      <ul className="list_stye_none mb-0">
                        <li>
                          <i className="material-icons-outlined ornge_icon">
                            star
                          </i>
                          <span className="rating">
                            4.5 <em>(32)</em>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="right_people_btn">
                  <Link to="#" className="btn gray_btn accepted_btn">
                    <i className="material-icons"> check_circle </i>
                    <span>Accepted</span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="white_shadow_bx people_bx">
              <div className="people_row">
                <div className="left_people">
                  <div className="people_profile">
                    <img src="images/people2.png" alt="" />
                  </div>
                  <div className="people_profile_info">
                    <h3>Clare MacLearn</h3>
                    <h5>Sales Manager at Lobees Ecosystem</h5>
                    <div className="event_tags">
                      <ul className="list_stye_none mb-0">
                        <li>
                          <i className="material-icons-outlined ornge_icon">
                            star
                          </i>
                          <span className="rating">
                            4.5 <em>(32)</em>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="right_people_btn">
                  <Link to="#" className="btn gray_btn rejected_btn">
                    <i className="material-icons"> cancel </i>
                    <span>Rejected</span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="white_shadow_bx people_bx">
              <div className="people_row">
                <div className="left_people">
                  <div className="people_profile">
                    <img src="images/people3.png" alt="" />
                  </div>
                  <div className="people_profile_info">
                    <h3>Nasino Yangchang</h3>
                    <h5>Sales Manager at Lobees Ecosystem</h5>
                    <div className="event_tags">
                      <ul className="list_stye_none mb-0">
                        <li>
                          <i className="material-icons-outlined ornge_icon">
                            star
                          </i>
                          <span className="rating">
                            4.5 <em>(32)</em>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="right_people_btn">
                  <Link to="#" className="btn gray_btn accepted_btn">
                    <i className="material-icons"> check_circle </i>
                    <span>Accepted</span>
                  </Link>
                </div>
              </div>
            </div> */}
          {/* </div> */}
          {/* </div> */}
        </div>
      </div>
      <InviteMessageModal
        afterModalClose={afterModalClose}
        modalMessage={modalMessage}
        modalSubMessage={modalSubMessage}
      />
    </section>
  );
};

export default FriendRequestListBody;
