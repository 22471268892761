/* eslint-disable */
import { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";

import * as url from "helper/UrlHelper";
import { getData, putData, uploadSingleFile } from "utils/Gateway";

import JobHeaderSection from "../LeftPanel/Sections/JobHeaderSection";

import { assetImages } from "constants";

import { getUpdatedConvertedDate } from "helper/Common/CommonHelper";
import { getDateFormatInSpanish } from "helper/Common/CommonHelper";
import { getJobResponseStatus } from "helper/ChallengeHelper/ChallengeHelper";

const CvBuilderRightPanel = ({
  isAgency,
  setcvId,
  reloadCvList,
  setreloadCvList,
  setStartFetchFromProfile,
  jobDetailsData = {},
  getChallengeDetails = () => {},
}) => {
  const params = useParams();

  return (
    <div className="col-lg-6">
      <div className="builder_lrightpnl">
        {/* <!-- ========== CV builder action buttons ========== --> */}
        <div
          className={`download_btn d-flex gap-2 mb-3 ${
            params && params.id ? "" : "d-none"
          }`}
        >
          {/* <button className="btn">
              <i className="material-icons me-2">psychology</i>
              Ask AI help for job below
            </button> */}

          <button className="btn outline">
            <i className="material-icons">info</i>
            Job Details and Responses
          </button>

          {/* <button className="btn ">
              <i className="material-icons me-2">person_pin</i>
              Ask to Expert
            </button> */}
        </div>

        {/* <!-- ========== CV builder action buttons ends ========== --> */}

        {/* <!-- ========== Start job header component ========== --> */}
        <JobHeaderSection
          isAgency={isAgency}
          companyName={jobDetailsData.companynamestring}
          companyLogoPath={jobDetailsData.companylogopath}
          title={jobDetailsData.name}
          summary={jobDetailsData.summary}
          jobTypeValue={jobDetailsData.jobtype}
          maxSalary={jobDetailsData.maxsalary}
          minSalary={jobDetailsData.minsalary}
          isApplied={jobDetailsData.isappiled}
          applyCount={jobDetailsData.applicationcount}
        />
        {/* <!-- ========== End job header component ========== --> */}

        {/* <!-- ========== Start previous created cv list Section ========== --> */}
        <div className="white_shadow_bx bg-light previous_cvbx py-4 px-3">
          <h3 className="mb-3 d-flex justify-content-between">
            Submitted Responses
            <Link to="#" className="ms-auto" onClick={getChallengeDetails}>
              <i className="material-icons-outlined">refresh</i>
            </Link>
          </h3>

          <div className="cv_pnl d-flex flex-column gap-3">
            {jobDetailsData.challengeresponses
              ? jobDetailsData.challengeresponses.map((jobResponse, index) => {
                  return (
                    <div
                      className="cv_row p-3 d-flex align-items-center justify-content-between"
                      key={index}
                    >
                      <div className="cv_left_text">
                        <h4 className="mb-2">
                          {jobResponse.responsecode} {" : "}
                          {jobResponse.candidatecvid?.resumetitle ?? ""}
                        </h4>
                        <h5 className="mb-2">
                          {getUpdatedConvertedDate(jobResponse.updatedAt)}
                        </h5>

                        {jobResponse.approvalstatus === "1" ? (
                          jobResponse.hiringprocessid ? (
                            <h5 className="mb-2">
                              Process :{" "}
                              <span className="fw-semibold text-gray">
                                {jobResponse.hiringprocessid.title}
                              </span>
                            </h5>
                          ) : null
                        ) : null}

                        <h5>
                          Approval Status :{" "}
                          {jobResponse.approvalstatus === "1" ? (
                            jobResponse.hiringprocessid &&
                            jobResponse.processresponserecord ? (
                              <span
                                className={`fw-semibold ${
                                  jobResponse.processaccepted
                                    ? "text-success"
                                    : "text-danger"
                                }`}
                              >
                                {jobResponse.processaccepted
                                  ? "Qualified"
                                  : "Sorry ! the request is rejected. Please try again later"}
                              </span>
                            ) : null
                          ) : (
                            <span className="text-danger fw-semibold">
                              {getJobResponseStatus(jobResponse.approvalstatus)}
                            </span>
                          )}
                        </h5>
                      </div>
                      {/* <div className="cv_more_btn dropdown user_right">
                        <Link
                          to="#"
                          className="d-inline-block"
                          data-bs-toggle="dropdown"
                        >
                          <i className="material-icons-outlined">more_vert </i>
                        </Link>
                        <ul className="dropdown-menu list_stye_none">
                          <li>
                            <Link
                              to="#"
                              className="d-flex align-items-center gap-1"
                              onClick={() => {
                                setcvId(jobResponse.candidatecvid?._id ?? null);
                              }}
                            >
                              <i className="material-icons-outlined">
                                visibility
                              </i>
                              <span>View CV</span>
                            </Link>
                          </li>

                          <li>
                            <Link
                              to="#"
                              className="d-flex align-items-center gap-1"
                            >
                              <i className="material-icons-outlined">
                                assignment
                              </i>
                              <span>Send to Customer</span>
                            </Link>
                          </li>
                        </ul>
                      </div> */}
                    </div>
                  );
                })
              : null}
          </div>
        </div>
        {/* <!-- ========== End previous created cv list Section ========== --> */}
      </div>
    </div>
  );
};

export default CvBuilderRightPanel;
