/* eslint-disable */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import * as url from "helper/UrlHelper";
import { getData } from "utils/Gateway";

import ConversationRightContactChatPanel from "./ConversationRightContactChatPanel";
import { assetImages } from "constants";
import ConversationProfileDetailsPopup from "components/ConversationComponents/Popups/ConversationProfileDetailsPopup";

//import ConversationRightContactMailPanel from './ConversationRightContactMailPanel';

import ChatHistoryComponent from "components/ConversationComponents/Modals/ChatHistoryModal";

const ConversationRightContactPanel = ({
  selectedContactUserId,
  conversationName,
  selectedChatLogo,
  selectedChatEmail,
  selectedChatRoomId,
  selectedChatPhone,
  selectedChatAddress,
  selectedChatCompany,
  chatcomponentName,
  setchatcomponentName,
  chatComponentTitle,
  setchatComponentTitle,
  socket,
}) => {
  const mobileCloseChatHandler = () => {
    document.body.classList.toggle("chatmsg_open");
  };

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  //search message requirements
  const [displaySearchPanel, setdisplaySearchPanel] = useState(false);
  const [searchMessage, setsearchMessage] = useState("");
  const [refreshMessages, setrefreshMessages] = useState(false);

  const [getSearchMessage, setgetSearchMessage] = useState(false);

  const [isLoading, setisLoading] = useState(false);
  const [name, setName] = useState("");
  const [surName, setSurName] = useState("");
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState("");
  const [position, setPosition] = useState("");

  const [showHistory, setShowHistory] = useState(false);

  //function for get user details
  const getUserDetails = async () => {
    try {
      setisLoading(true);
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_USER_DETAILS +
        `/${selectedContactUserId}` +
        `?token=${token}`;

      console.log("requestUrl", requestUrl);

      const response = await getData(requestUrl);

      setisLoading(false);

      console.log(response);

      if (response.status && response.data) {
        const data = response.data;

        setName(data.name || "");
        setSurName(data.surname || "");
        setEmail(data.email || "");
        setTelephone(data.phone || "");
        setPosition(data.position || "");
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for reset all chats
  const refreshChatHandler = () => {
    setrefreshMessages(true);
    setdisplaySearchPanel(false);
    setsearchMessage("");
    if (selectedContactUserId) {
      getUserDetails();
    }
  };

  //function for search messages
  const searchMessageHandler = () => {
    setgetSearchMessage(true);
    setdisplaySearchPanel(false);
  };

  const searchMessageByEnter = (e) => {
    if (e.key === "Enter") {
      searchMessageHandler();
    }
  };

  useEffect(() => {
    if (selectedChatRoomId) {
      refreshChatHandler();
    }
  }, [selectedChatRoomId]);

  useEffect(() => {
    if (selectedContactUserId) {
      getUserDetails();
    }
  }, [selectedContactUserId]);

  return (
    <>
      <div className="chat_topPnl">
        <div className="chatusr_info">
          <div className="list_back">
            <Link to="#" onClick={mobileCloseChatHandler}>
              <i className="material-icons-round">arrow_back</i>
            </Link>
          </div>
          {isLoading ? (
            <div className="grp_userHead placeholder-glow">
              <div className="user_placeHoledr">
                <div className="default_usr">
                  <img src={assetImages.defaultUser} alt="" />
                </div>
                <div className="default_usr_name">
                  <span className="placeholder placeholder-sm col-5 rounded-3"></span>
                  <span className="placeholder placeholder-sm col-2 rounded-3"></span>
                </div>
              </div>
            </div>
          ) : (
            <Link
              to="#"
              data-bs-toggle="offcanvas"
              data-bs-target="#profile_pnl"
              className="chat_usrOuter profile_linkBtn"
            >
              <div className="chat_usrPic">
                <img
                  src={
                    isLoading
                      ? assetImages.defaultUser
                      : selectedChatLogo == ""
                      ? assetImages.defaultUser
                      : url.SERVER_URL + selectedChatLogo
                  }
                  alt=""
                />
              </div>

              <div className="chat_usrtext">
                <h3>
                  {name} {surName}
                </h3>
                <h5>{position}</h5>
              </div>
            </Link>
          )}

          <div className="info_icons me-2">
            <ul className="list_stye_none">
              <li>
                <Link to="#" onClick={refreshChatHandler}>
                  <i className="material-icons-round">refresh</i>
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="srch_Btn_icon"
                  onClick={() => {
                    setdisplaySearchPanel(!displaySearchPanel);
                    setsearchMessage("");
                  }}
                >
                  <i className="material-icons-round">search</i>
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#chathistorymodal"
                  onClick={() => setShowHistory(true)}
                >
                  <i className="material-icons-round">history</i>
                </Link>
              </li>

              {/* <li>
                <Link to="#">
                  <i className="material-icons-round">person_add</i>
                </Link>
              </li> */}
            </ul>
          </div>
        </div>

        <div className="chat_tablinks">
          <ul className="list_stye_none">
            <li className="active">
              <Link to="#">
                <i className="material-icons-round">question_answer</i>
                <span>chat</span>
              </Link>
            </li>
            {/* <li>
              <Link
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#emaildetails_mdl"
              >
                <i className="material-icons-round">email</i>
                <span>mail</span>
              </Link>
            </li> */}
          </ul>
        </div>

        <div
          className="serch_pnl"
          style={{
            display: displaySearchPanel ? "block" : "none",
          }}
        >
          <div className="srch_innrFill">
            <input
              type="text"
              className="form-control"
              placeholder="Search for conversation"
              value={searchMessage}
              onChange={(e) => {
                setsearchMessage(e.target.value);
              }}
              onKeyDown={searchMessageByEnter}
            />
            <button
              className="srch_submitBtn"
              type="button"
              onClick={searchMessageHandler}
            >
              <i className="material-icons-round"> send</i>
            </button>
          </div>
        </div>
      </div>

      <ConversationRightContactChatPanel
        conversationName={conversationName}
        selectedContactUserId={selectedContactUserId}
        selectedChatEmail={selectedChatEmail}
        selectedChatLogo={selectedChatLogo}
        selectedChatRoomId={selectedChatRoomId}
        chatcomponentName={chatcomponentName}
        setchatcomponentName={setchatcomponentName}
        chatComponentTitle={chatComponentTitle}
        setchatComponentTitle={setchatComponentTitle}
        socket={socket}
        searchMessage={searchMessage}
        setsearchMessage={setsearchMessage}
        getSearchMessage={getSearchMessage}
        setgetSearchMessage={setgetSearchMessage}
        refreshMessages={refreshMessages}
        setrefreshMessages={setrefreshMessages}
      />

      {/* <ConversationRightContactMailPanel /> */}

      <ConversationProfileDetailsPopup
        conversationName={conversationName}
        selectedChatLogo={selectedChatLogo}
        selectedChatEmail={selectedChatEmail}
        selectedChatPhone={selectedChatPhone}
        selectedChatAddress={selectedChatAddress}
        selectedChatCompany={selectedChatCompany}
      />

      <ChatHistoryComponent
        showHistory={showHistory}
        isGroupChat={false}
        setShowHistory={setShowHistory}
        selectedChatRoomId={selectedContactUserId} // here we send contact id instead of room id
        searchMessage={searchMessage}

        />  

    </>
  );
};

export default ConversationRightContactPanel;
